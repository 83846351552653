import { TurnItemKart } from "./TurnItemKart";
import { Group } from "../../Graphics/Group";
import { Logic, _s, settings } from "../../Logic/Logic";
import { ITrack, IAnimInterval } from "../../Logic/LogicDefinitions";
import { AnimHelper } from "../common/Anim";
import { GameLength } from "../../common/Definitions";

export class TrackPresentationKart extends Group {
  private turns: TurnItemKart[] = [];
  private gameLength: GameLength;
  private withBonus = true;
  private anims: IAnimInterval[] = [];

  public constructor(gameLength: GameLength) {
    super();

    this.gameLength = gameLength;

    this.showDebug(settings.debug);
  }

  public fill(track: ITrack, withBonus: boolean) {
    while (this.turns.length < track.items.length) {
      const turn = new TurnItemKart();
      this.turns.push(turn);
      this.add(turn);
    }

    for (let i = 0; i < track.items.length; i++) {
      this.turns[i].fill(track.items[i]);
      this.turns[i].visible = true;
    }
    for (let i = track.items.length; i < this.turns.length; i++) {
      this.turns[i].visible = false;
    }

    this.anims = this.createAnims(this.gameLength, withBonus);

    this.onLayout();
  }

  public onLayout() {
    this.updateTurns(this.gameLength, this.withBonus);
  }

  private createAnims(gameLength: GameLength, withBonus: boolean): IAnimInterval[] {
    this.withBonus = withBonus;
    switch (gameLength) {
      case 120:
        return [];
      case 180:
        return [withBonus ? { startTime: 40.0, duration: 20.0 } : { startTime: 50.0, duration: 20.0 }];
      case 240:
        return [{ startTime: 50.0, duration: 20.0 }];
      case 300:
        return [withBonus ? { startTime: 50.3, duration: 20.0 } : { startTime: 61.0, duration: 20.0 }];
    }

    this.updateTurns(this.gameLength, this.withBonus);
    return [];
  }

  private createTurnAnims(gameLength: GameLength): IAnimInterval[] {
    switch (gameLength) {
      case 300:
        return [
          { startTime: 0.5, duration: 17.2 }, // finish line
          { startTime: 0.5, duration: 17.2 }, // start line
          { startTime: 2.2, duration: 1.1 }, // turn 01
          { startTime: 2.8, duration: 1.1 }, // turn 02
          { startTime: 4.4, duration: 1.1 }, // turn 03
          { startTime: 5.7, duration: 1.1 }, // turn 04
          { startTime: 8.5, duration: 1.2 }, // turn 05
          { startTime: 9.3, duration: 2.6 }, // Highspeed Section 1
          { startTime: 12.3, duration: 1.1 }, // turn 06
          { startTime: 13.1, duration: 1.1 }, // H
          { startTime: 14.7, duration: 1.1 }, // Turn 07
          { startTime: 15.5, duration: 1.1 }
        ];
      default:
        return [
          { startTime: 1, duration: 17.6 }, // finish line
          { startTime: 1, duration: 17.6 }, // start line
          { startTime: 3, duration: 1.2 }, // turn 01
          { startTime: 3.65, duration: 1.2 }, // turn 02
          { startTime: 5.3, duration: 1.2 }, // turn 03
          { startTime: 6.9, duration: 1.2 }, // turn 04
          { startTime: 9.7, duration: 1.2 }, // turn 05
          { startTime: 10.3, duration: 2.3 }, // Highspeed Section 1
          { startTime: 13.1, duration: 1.2 }, // turn 06
          { startTime: 14.1, duration: 1.0 }, // H
          { startTime: 15.6, duration: 1.2 }, // Turn 07
          { startTime: 16.2, duration: 1.3 }
        ];
    }
  }

  private updateTurns(gameLength: GameLength, withBonus: boolean) {
    if (!this.turns || this.turns.length === 0) {
      return;
    }

    // war 0.98
    //     const f = (gameLength === 300) ?
    // //      (Logic.languageId === "it"? 0.9 : 0.8):
    //        1: 1;
    const f = 1;
    const anims = this.createTurnAnims(gameLength);

    let index = 0;
    {
      // Finish Line
      const turn = this.turns[index++];
      turn.x = _s(195);
      turn.y = _s(374);
      turn.fadeInTime = anims[0].startTime * f;
      turn.fadeOutTime = anims[0].duration * f;
    }
    {
      // Start Line
      const turn = this.turns[index++];
      turn.x = _s(535);
      turn.y = _s(562);
      turn.fadeInTime = anims[1].startTime * f;
      turn.fadeOutTime = anims[1].duration * f;
    }
    {
      // Turn 01
      const turn = this.turns[index++];
      turn.x = _s(138);
      turn.y = _s(583);
      turn.fadeInTime = anims[2].startTime * f;
      turn.fadeOutTime = anims[2].duration * f;
    }
    {
      // Turn 02
      const turn = this.turns[index++];
      turn.x = _s(245);
      turn.y = _s(445);
      turn.fadeInTime = anims[3].startTime * f;
      turn.fadeOutTime = anims[3].duration * f;
    }
    {
      // Turn 03
      const turn = this.turns[index++];
      turn.x = _s(258);
      turn.y = _s(210);
      turn.fadeInTime = anims[4].startTime * f;
      turn.fadeOutTime = anims[4].duration * f;
    }
    {
      // Turn 04
      const turn = this.turns[index++];
      turn.x = _s(765);
      turn.y = _s(72);
      turn.fadeInTime = anims[5].startTime * f;
      turn.fadeOutTime = anims[5].duration * f;
    }
    {
      // Turn 05
      const turn = this.turns[index++];
      turn.x = _s(587);
      turn.y = _s(429);
      turn.fadeInTime = anims[6].startTime * f;
      turn.fadeOutTime = anims[6].duration * f;
    }
    {
      // Highspeed Section 1
      const turn = this.turns[index++];
      turn.x = _s(678);
      turn.y = _s(334);
      turn.fadeInTime = anims[7].startTime * f;
      turn.fadeOutTime = anims[7].duration * f;
    }
    {
      // Turn 06
      const turn = this.turns[index++];
      turn.x = _s(1063);
      turn.y = _s(177);
      turn.fadeInTime = anims[8].startTime * f;
      turn.fadeOutTime = anims[8].duration * f;
    }
    {
      // H
      const turn = this.turns[index++];
      turn.x = _s(977);
      turn.y = _s(354);
      turn.fadeInTime = anims[9].startTime * f;
      turn.fadeOutTime = anims[9].duration * f;
    }
    {
      // Turn 07
      const turn = this.turns[index++];
      turn.x = _s(790);
      turn.y = _s(631);
      turn.fadeInTime = anims[10].startTime * f;
      turn.fadeOutTime = anims[10].duration * f;
    }
    {
      // HS2
      const turn = this.turns[index++];
      turn.x = _s(623);
      turn.y = _s(664);
      turn.fadeInTime = anims[11].startTime * f;
      turn.fadeOutTime = anims[11].duration * f;
    }
  }

  public update(dt: number) {
    super.update(dt);

    const t = Logic.getVideoTime();
    const anim = Logic.getAnim(t, this.anims, this);
    if (!anim) {
      this.visible = false;
      return;
    } else {
      this.visible = true;
    }

    const fadeInTimeFactor = this.gameLength === 300 ? 2 : 1;

    const baseFactor = t - anim.startTime;
    this.setDebugFade(AnimHelper.limit(baseFactor, anim.duration));
    for (const turn of this.turns) {
      const f = baseFactor - turn.fadeInTime;
      if (f >= turn.fadeOutTime) turn.alpha = 1.0 - (f - turn.fadeOutTime) * 4;
      else turn.alpha = AnimHelper.clamp(f * 2 * fadeInTimeFactor);
    }
  }
}
