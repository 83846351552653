import * as WebSocket from "ws";
import { RtcSocket } from "../common/RtcSocket";
import { Consumer, Producer } from "mediasoup-client/lib/types";
import { Transport } from "mediasoup-client/lib/types";

export interface IWebSocketConnections {
  id: string;
  socket: RtcSocket<WebSocket>;
  roomId: string;
}

export interface IConsumerHelper {
  type: "consumer";
  state: "initialized" | "closed";
  audio?: Consumer;
  video?: Consumer;
  transport?: Transport;
}

export interface IProducerHelper {
  type: "producer";
  state: "initialized" | "closed";
  audio?: Producer;
  video?: Producer;
  transport?: Transport;
}

export class ConnectionHelper {
  public static isProducer(connection: IWebSocketConnections): boolean {
    return connection.id === connection.roomId;
  }
  public static getConnectionsForRoom(connections: IWebSocketConnections[], roomId: string): IWebSocketConnections[] {
    return connections.filter((d) => d.roomId === roomId);
  }

  public static getConsumers(connections: IWebSocketConnections[], roomId: string): IWebSocketConnections[] {
    return connections.filter((c) => c.roomId === roomId && c.id !== roomId);
  }
}

export class ProducerHelper {
  public static setTransport(ph: IProducerHelper | IConsumerHelper, transport: Transport): void {
    console.log("Client setTransport: " + ph.state + " " + ph.type);
    if (ph.state === "closed") transport.close();
    else ph.transport = transport;
  }

  public static setStream(ph: IProducerHelper | IConsumerHelper, stream: Producer | Consumer, kind: "audio" | "video"): void {
    console.log("Client setStream: " + ph.state + " " + ph.type);
    if (ph.state === "closed") {
      stream.close();
    } else {
      ph[kind] = stream;
    }
  }

  public static close(ph: IProducerHelper | IConsumerHelper): void {
    if (ph) {
      console.log("Client close producerHelper: " + ph.state + " " + ph.type);
      if (ph.audio) ph.audio.close();
      if (ph.video) ph.video.close();
      if (ph.transport) ph.transport.close();
      ph.state = "closed";
    }
  }
}
