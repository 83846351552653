import * as PIXI from "pixi.js";
import { Group } from "../../../../Graphics/Group";
import { Logic, _s, _t, settings } from "../../../../Logic/Logic";
import { IDriver, IDog63RoundHistoryAccoppiataEntry } from "../../../../Logic/LogicDefinitions";
import { Dog63Helper } from "../../Dog63Helper";
import { LayoutHelper } from "../../../../VideoScreen/Util/LayoutHelper";
import { DrawHelper } from "../../../../VideoScreen/common/DrawHelper";

export class Dog63Bar extends Group {
  private left: PIXI.Sprite;
  private right: PIXI.Sprite;
  private line: PIXI.Sprite;
  private bar: PIXI.Sprite;

  private val: number = 0;

  public constructor() {
    super();

    this.showDebug(settings.debug, undefined, "bar");

    this.left = new PIXI.Sprite(PIXI.Texture.WHITE);
    this.left.anchor.set(0, 0.5);
    //this.left.tint = 0xAA0000;
    this.add(this.left);

    this.right = new PIXI.Sprite(PIXI.Texture.WHITE);
    this.right.anchor.set(1, 0.5);
    //this.right.tint = 0xAA0000;
    this.add(this.right);

    this.line = new PIXI.Sprite(PIXI.Texture.WHITE);
    this.line.anchor.set(0, 0.5);
    //this.line.tint = 0xAA0000;
    this.add(this.line);

    this.bar = new PIXI.Sprite(PIXI.Texture.WHITE);
    this.bar.anchor.set(0, 0.5);
    //this.bar.tint = 0xAA0000;
    //this.bar.alpha = 0.5;
    this.add(this.bar);
  }

  public fill(value: number): void {
    // const driverFirst = drivers[accopiata.firstDriverIndex];
    // const driverSecond = drivers[accopiata.secondDriverIndex];
    // this.barFirst.texture = DrawHelper.getCachedPattern(6, 32, 0, driverFirst.color, driverFirst.color2, driverFirst.driverPattern);
    // this.barSecond.texture = DrawHelper.getCachedPattern(6, 32, 0, driverSecond.color, driverSecond.color2, driverSecond.driverPattern);
    this.val = value;
    this.onLayout();
  }

  public onLayout(): void {
    const lineHeight = 20;
    const lineWidth = 56;
    const smallLineWidth = 2;

    this.left.width = _s(smallLineWidth);
    this.left.height = _s(lineHeight);

    this.right.x = _s(lineWidth);
    this.right.width = _s(smallLineWidth);
    this.right.height = _s(lineHeight);

    this.line.height = _s(smallLineWidth);
    this.line.width = _s(lineWidth);

    this.bar.height = _s(12);
    this.bar.width = _s(this.val * lineWidth);

    //const driverY = -1;
    // this.driverFirst.x = _s(7);
    // this.driverFirst.y = _s(driverY);
    // this.driverSecond.x = _s(19);
    // this.driverSecond.y = _s(driverY);
    // this.quote.x = _s(13);
    // this.quote.y = _s(14);

    // this.barFirst.rotation = Math.PI*90.0/180.0;
    // LayoutHelper.setScaledRectangleSprite(this.barFirst, 6, 13, 4, 14);
    // this.barSecond.rotation = Math.PI*90.0/180.0;
    // LayoutHelper.setScaledRectangleSprite(this.barSecond, 6+14, 13, 4, 14);
  }

  public updateAnim(baseFactor: number, duration: number): void { }
}
