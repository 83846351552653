import { IHorseDog6C4Model } from "./../Logic/LogicDefinitions";
import { Logic, settings } from "../Logic/Logic";
import { ServerSocketLogic } from "../ServerWebSocket/ServerSocketLogic";
import { IGameInfo, IHorseC4Bonus, IInitData, ILogicImplementation, IModel, IRouletteRoundHistory, VideoState, VideoUrlInfo } from "../Logic/LogicDefinitions";
import { VideoScreenRouletteC4 } from "./../VideoScreen/rouletteC4/VideoScreenRouletteC4";

import headerImageUrl from "../assets/kickbox/WGP_Header.png";
import inFightImageUrl from "../assets/kickbox/WGP_Infight_Display.png";
import inFightImageBigUrl from "../assets/kickbox/WGP_Overlay_edBG_Big.png";
import fightResultHexUrl from "../assets/kickbox/roundresult_hexagon.png";
import { Logger } from "../Logic/Logger";
import { ServerSocketLogicBase, SockServGameRoundMessage, SockServLogMessage } from "../ServerWebSocket/base/ServerSocketLogicBase";
import { VideoScreenDog63 } from "../VideoScreen/dog63/VideoScreenDog63";
import { VideoScreenKickBox } from "../VideoScreen/kickbox/VideoScreenKickBox";
import resultBackgroundImageUrl from "../assets/kickbox/Round_Result_Stilframe_Start.png";
import wipeBackgroundImageUrl from "../assets/kickbox/WipeBackground.png";
import {
  GameLength,
  GameType,
  IBetCodeDecimals,
  IGameRoundResultData,
  IInitSettings,
  ISockServResponseMessageGameRound,
  ISockServResponseMessageInit,
  ISockServResponseMessageTranslation,
  PerformanceSetting,
  SkinType,
  SkinTypeDefinition
} from "../common/Definitions";
import { Settings } from "../common/Settings";
import { Util } from "../common/Util";
import { IVideoUrls, LogicBase } from "../Logic/LogicBase";
import { ErrorHandler, Errors } from "./ErrorHandler";
import { GameTimer, GamesModel, IResultExtern, ModelBox, ModelDog, ModelDog63, ModelHorse, ModelKart, ModelSulky, ModelRtt } from "./GamesModel";
import { Languages } from "./Localisation";
import { ErrorObj } from "./base/ErrorHandlerBase";
import { ModelBase } from "./base/GamesModelBase";
import { ModelHorseC4 } from "./ModelHorseC4";
import { VideoScreenHorseC4 } from "../VideoScreen/horseDog6C4/VideoScreenHorseDog6C4";
import { FadeDurations } from "../common/FadeDurations";

export const INIT_INTERVAL = 1;
export const INIT_NUMB_FUTURE = 1;
export const INIT_NUMB_PAST = 8;
export const RACE_BREAK_REQU_INTERVAL = 60; // in Seconds, every nth second at race break a request for new games is made
// TODO TEST
// export const RACE_BREAK_REQU_INTERVAL = 1; // in Seconds, every nth second at race break a request for new games is made

export let GAME_VIDEO_START_MS = 169000; // in milliseconds, its the time when countoun shows 0 ( INTRO_VIDEO_LENGTH - fade Time - 1 Second 0 shown)
export let INTRO_VIDEO_LENGTH = 172; // in seconds, its the time when Viualisation VideoState changes from Intro to Race
export let GAME_LOOP_LENGTH: GameLength = 240; // in seconds
export let START_NEXT_TO_SEC_DELAY = 900; // in seconds
export let START_NEXT_VIDEO_START_OFFSET = 2; // in seconds
export const VIDEO_START_DELAY_TIME = 0.1; // in seconds
export const MAX_START_OFFSET = 200; // in milliseconds
export const MAX_INTRO_OFFSET = 400; // in milliseconds
export const MAX_RACE_OFFSET = 800; // in milliseconds
export const SETUP_OFFSET_FOR_PLAY = 2; // in seconds

const performance: PerformanceSetting = "high";

//TODO TEST
// let testCount = 0;

export class LogicImplementation extends LogicBase implements ILogicImplementation {
  private initStettingsData!: IInitSettings;
  private gamesModel = new GamesModel();
  private setNextModel!: ModelBase;
  private nextVideoStarted = false;
  private roundRequestSecond = 0;
  private intiVideoStartTime = 0; // seconds since game round start
  private intiVideoStartTimestamp = 0; // sys time when should have first started
  private gameTimer: GameTimer = new GameTimer(this, 500, 10000, GAME_LOOP_LENGTH, GAME_VIDEO_START_MS, INIT_INTERVAL);
  public lastStartRaceId: string = "";
  private startNextTimer!: NodeJS.Timeout;
  private canPlayOnSetUp: boolean = true;
  private setUpCanplayReceived: boolean = false;
  private setUpVideoStarted: boolean = false;
  private raceVideoStarted: boolean = false;
  private fadeToRaceReceived = false;
  private canPlayIntroReceived = false;
  private initResult: ISockServResponseMessageInit | null = null;
  private deviceId: string = "";

  private countVidUpdate = 0;
  private raceVideoDelay = 2400; // delay of race video to servertime video Loop in milliseconds
  private countRaceVidDoneUpd: number = 0;
  private extraLoadTime: number = 0; // in milliseconds
  private continiousSync: boolean = false; // if true synchronization with server will be checked continiuslly,
  // otherwise only at start of a video Loop
  private maxRaceSyncUpdates: number = 5; // to be not complete unfluent update only several times, than leaf delayed
  private maxRoundNumber: number = 10000000000;
  private beforeBreakNumber: number = 10000000000;

  // initialize logic => prepare first round ...
  public async onInit(): Promise<void> {
    Logger.debug("On Init");
    this.deviceId = settings.deviceId;

    try {
      // socket sever Url is read from an request by device ID
      ServerSocketLogicBase.socketServerUrl = await ServerSocketLogic.getSocketUrlRequest(this.deviceId);
    } catch (e: any) {
      const error = new ErrorObj(Errors.SOCKET_SERVER_URL_REQUEST.code, e.toString());
      this.errorHandler(error, true);
      return;
    }

    const settingSocketServerUrl = ServerSocketLogic.getServerUrlByDomain();
    if (settingSocketServerUrl !== "") {
      // it can be overwritten by settings (for example for development on local host)
      ServerSocketLogicBase.socketServerUrl = settingSocketServerUrl;
    }
    ServerSocketLogic.instance.setSocketServerUrl(ServerSocketLogicBase.socketServerUrl);

    await this.init(true);
  }

  public async init(firstSetUp: boolean): Promise<void> {
    await this.initServerAction(firstSetUp);
    if (this.initResult) await this.initialSetUp(this.initResult);
  }

  public async tryRestart() {
    //connect to server and read static settings for device
    await this.initServerAction(false);

    if (!this.isAllreadySetUp()) {
      //touch on browser version can not be done jet --> reload window
      this.reloadWidow();
    }

    //dynamic datas are set up each restart
    this.initAction(false);
  }

  private async cacheMediaFiles(initResult: ISockServResponseMessageInit) {
    // await LocalCache.delete(); // delete old cache since urls are different on each start....

    const introInfo = this.getIntroUrls(initResult);
    console.log("IntroInfo: " + JSON.stringify(introInfo));
    const files: string[] = [];
    files.push(introInfo.image);
    files.push(introInfo.video);
    if (introInfo.sound && this.isIntroSoundEnabled()) files.push(introInfo.sound);

    // Todo: "delete unused files..."
    // const filesWithoutParams = files.map((f) => LocalCache.removeParams(f));
    // await LocalCache.enumCache((file, folder) => {
    //   const fullPath = folder + "/" + file.name;
    //  if (!filesWithoutParams.includes(fullPath))
    //    console.log("Delete: ...");
    //  console.log("FullPath: " + fullPath);
    // });

    await Logic.cacheFiles(files);
  }

  public async initialSetUp(initResult: ISockServResponseMessageInit) {
    if (initResult.setting.betoffers[0].eventtype !== "wgp") {
      await this.cacheMediaFiles(initResult);
    }

    // ----------------- Game settings Parameters ------------------------------

    let gameType: GameType = "kart5";
    let eventType: string = "kart5";
    // TODO vom init Result lesen
    let skinVersion: SkinType = 10;

    // TODO TEST
    // if(this.initResult)
    // this.initResult.setting.betoffers[0].nbrEvents = 233;
    // this.initResult.setting.betoffers[0].roundInterval = 241;

    GAME_LOOP_LENGTH = initResult.setting.betoffers[0].roundInterval;
    eventType = initResult.setting.betoffers[0].eventtype;
    skinVersion = initResult.setting.skinVersion as SkinType;

    FadeDurations.setFadeDurations(gameType, skinVersion);

    if (initResult.setting.betoffers[0].eventtype === "dog") {
      gameType = "dog6";
      START_NEXT_VIDEO_START_OFFSET = 2;
      START_NEXT_TO_SEC_DELAY = 710;
      this.raceVideoDelay = 2400;
      this.gamesModel.historyLength = GamesModel.HISTROY_LENGTH;

      if (skinVersion === SkinTypeDefinition.CLASSIC) {
        this.gamesModel.historyLength = GamesModel.HISTROY_LENGTH_C4;
        switch (GAME_LOOP_LENGTH) {
          case 240: {
            GAME_VIDEO_START_MS = 193000;
            INTRO_VIDEO_LENGTH = 195;

            START_NEXT_VIDEO_START_OFFSET = 1;
            START_NEXT_TO_SEC_DELAY = 600;
            break;
          }
          default: {
            const error = Errors.INVALID_LENGTH;
            error.message = error.message + ":" + GAME_LOOP_LENGTH;
            ErrorHandler.instance.normalErrorHandler(Errors.INVALID_LENGTH, true);
            return;
          }
        }
      } else {
        switch (GAME_LOOP_LENGTH) {
          case 120: {
            GAME_VIDEO_START_MS = 57200;
            INTRO_VIDEO_LENGTH = 60.2;
            break;
          }
          case 180: {
            GAME_VIDEO_START_MS = 117200;
            INTRO_VIDEO_LENGTH = 120.2;
            break;
          }
          case 240: {
            GAME_VIDEO_START_MS = 177200;
            INTRO_VIDEO_LENGTH = 180.2;
            break;
          }
          case 300: {
            GAME_VIDEO_START_MS = 192200;
            INTRO_VIDEO_LENGTH = 195.2;
            START_NEXT_VIDEO_START_OFFSET = 2;
            START_NEXT_TO_SEC_DELAY = 610;
            break;
          }
          default: {
            const error = Errors.INVALID_LENGTH;
            error.message = error.message + ":" + GAME_LOOP_LENGTH;
            ErrorHandler.instance.normalErrorHandler(Errors.INVALID_LENGTH, true);
            return;
          }
        }
      }
    } else if (initResult.setting.betoffers[0].eventtype === "dog8") {
      gameType = initResult.setting.betoffers[0].eventtype;
      START_NEXT_VIDEO_START_OFFSET = 2;
      START_NEXT_TO_SEC_DELAY = 710;
      this.raceVideoDelay = 2100;
      this.gamesModel.historyLength = GamesModel.HISTROY_LENGTH;

      switch (GAME_LOOP_LENGTH) {
        case 120: {
          GAME_VIDEO_START_MS = 57200;
          INTRO_VIDEO_LENGTH = 60.2;
          break;
        }
        case 180: {
          GAME_VIDEO_START_MS = 117200;
          INTRO_VIDEO_LENGTH = 120.2;
          break;
        }
        case 240: {
          GAME_VIDEO_START_MS = 177200;
          INTRO_VIDEO_LENGTH = 180.2;
          break;
        }
        case 300: {
          GAME_VIDEO_START_MS = 192371;
          INTRO_VIDEO_LENGTH = 195.371;
          START_NEXT_VIDEO_START_OFFSET = 2;
          START_NEXT_TO_SEC_DELAY = 610;
          break;
        }
        default: {
          const error = Errors.INVALID_LENGTH;
          error.message = error.message + ":" + GAME_LOOP_LENGTH;
          ErrorHandler.instance.normalErrorHandler(Errors.INVALID_LENGTH, true);
          return;
        }
      }
    } else if (initResult.setting.betoffers[0].eventtype === "kart") {
      gameType = "kart5";
      START_NEXT_VIDEO_START_OFFSET = 2;
      START_NEXT_TO_SEC_DELAY = 760;
      this.raceVideoDelay = 2100;
      this.gamesModel.historyLength = GamesModel.HISTROY_LENGTH;

      switch (GAME_LOOP_LENGTH) {
        case 120: {
          GAME_VIDEO_START_MS = 49100;
          INTRO_VIDEO_LENGTH = 52.1;
          break;
        }
        case 180: {
          GAME_VIDEO_START_MS = 109100;
          INTRO_VIDEO_LENGTH = 112.1;
          break;
        }
        case 240: {
          GAME_VIDEO_START_MS = 169100;
          INTRO_VIDEO_LENGTH = 172.1;
          break;
        }
        case 300: {
          GAME_VIDEO_START_MS = 184040;
          INTRO_VIDEO_LENGTH = 187.04;
          break;
        }
        default: {
          const error = Errors.INVALID_LENGTH;
          error.message = error.message + ":" + GAME_LOOP_LENGTH;
          ErrorHandler.instance.normalErrorHandler(Errors.INVALID_LENGTH, true);
          return;
        }
      }
    } else if (initResult.setting.betoffers[0].eventtype === "wgp") {
      gameType = "box";
      START_NEXT_VIDEO_START_OFFSET = 2;
      START_NEXT_TO_SEC_DELAY = 760;
      this.raceVideoDelay = 2100;
      this.gamesModel.historyLength = GamesModel.HISTROY_LENGTH_BOX;

      switch (GAME_LOOP_LENGTH) {
        case 384: {
          GAME_VIDEO_START_MS = 246466.667;
          INTRO_VIDEO_LENGTH = 249.661333;

          break;
        }
        default: {
          const error = Errors.INVALID_LENGTH;
          error.message = error.message + ":" + GAME_LOOP_LENGTH;
          ErrorHandler.instance.normalErrorHandler(Errors.INVALID_LENGTH, true);
          return;
        }
      }
    } else if (initResult.setting.betoffers[0].eventtype === "dog63") {
      gameType = "dog63";
      START_NEXT_VIDEO_START_OFFSET = 2;
      START_NEXT_TO_SEC_DELAY = 710;
      this.raceVideoDelay = 2400;
      this.gamesModel.historyLength = GamesModel.HISTROY_LENGTH;

      switch (GAME_LOOP_LENGTH) {
        case 120: {
          GAME_VIDEO_START_MS = 57200;
          INTRO_VIDEO_LENGTH = 60.2;
          break;
        }
        case 180: {
          GAME_VIDEO_START_MS = 117200;
          INTRO_VIDEO_LENGTH = 120.2;
          break;
        }
        case 240: {
          GAME_VIDEO_START_MS = 177200;
          INTRO_VIDEO_LENGTH = 180.2;
          break;
        }
        case 300: {
          GAME_VIDEO_START_MS = 237200;
          INTRO_VIDEO_LENGTH = 237.2;
          START_NEXT_VIDEO_START_OFFSET = 2;
          START_NEXT_TO_SEC_DELAY = 610;
          break;
        }
        default: {
          const error = Errors.INVALID_LENGTH;
          error.message = error.message + ":" + GAME_LOOP_LENGTH;
          ErrorHandler.instance.normalErrorHandler(Errors.INVALID_LENGTH, true);
          return;
        }
      }
    } else if (initResult.setting.betoffers[0].eventtype === "horse") {
      gameType = "horse";
      START_NEXT_VIDEO_START_OFFSET = 2;
      START_NEXT_TO_SEC_DELAY = 710;
      this.raceVideoDelay = 2400;
      this.gamesModel.historyLength = GamesModel.HISTROY_LENGTH;

      if (skinVersion === SkinTypeDefinition.CLASSIC) {
        this.gamesModel.historyLength = GamesModel.HISTROY_LENGTH_C4;
        switch (GAME_LOOP_LENGTH) {
          case 240: {
            GAME_VIDEO_START_MS = 195000;
            INTRO_VIDEO_LENGTH = 197;

            START_NEXT_VIDEO_START_OFFSET = 1;
            START_NEXT_TO_SEC_DELAY = 600;
            break;
          }
          default: {
            const error = Errors.INVALID_LENGTH;
            error.message = error.message + ":" + GAME_LOOP_LENGTH;
            ErrorHandler.instance.normalErrorHandler(Errors.INVALID_LENGTH, true);
            return;
          }
        }
      } else {
        switch (GAME_LOOP_LENGTH) {
          case 120: {
            GAME_VIDEO_START_MS = 57200;
            INTRO_VIDEO_LENGTH = 60.2;
            break;
          }
          case 180: {
            GAME_VIDEO_START_MS = 117200;
            INTRO_VIDEO_LENGTH = 120.2;
            break;
          }
          case 240: {
            GAME_VIDEO_START_MS = 177200;
            INTRO_VIDEO_LENGTH = 180.2;
            break;
          }
          case 300: {
            GAME_VIDEO_START_MS = 192200;
            INTRO_VIDEO_LENGTH = 195.2;
            START_NEXT_VIDEO_START_OFFSET = 2;
            START_NEXT_TO_SEC_DELAY = 610;
            break;
          }
          case 320: {
            GAME_VIDEO_START_MS = 177000;
            INTRO_VIDEO_LENGTH = 180;
            START_NEXT_VIDEO_START_OFFSET = 2;
            START_NEXT_TO_SEC_DELAY = 610;

            break;
          }
          default:
            {
              const error = Errors.INVALID_LENGTH;
              error.message = error.message + ":" + GAME_LOOP_LENGTH;
              ErrorHandler.instance.normalErrorHandler(Errors.INVALID_LENGTH, true);
              return;
            }
            break;
        }
      }
    } else if (initResult.setting.betoffers[0].eventtype === "sulky") {
      gameType = "sulky";
      START_NEXT_VIDEO_START_OFFSET = 2;
      START_NEXT_TO_SEC_DELAY = 710;
      this.raceVideoDelay = 2400;

      switch (GAME_LOOP_LENGTH) {
        case 384: {
          GAME_VIDEO_START_MS = 177000;
          INTRO_VIDEO_LENGTH = 180;
          START_NEXT_VIDEO_START_OFFSET = 2;
          START_NEXT_TO_SEC_DELAY = 610;
          break;
        }
        default: {
          const error = Errors.INVALID_LENGTH;
          error.message = error.message + ":" + GAME_LOOP_LENGTH;
          ErrorHandler.instance.normalErrorHandler(Errors.INVALID_LENGTH, true);
          return;
        }
      }
    } else if (initResult.setting.betoffers[0].eventtype === "rtt") {
      gameType = "roulette";
      START_NEXT_VIDEO_START_OFFSET = 2;
      START_NEXT_TO_SEC_DELAY = 710;
      this.raceVideoDelay = 2400;
      this.gamesModel.historyLength = GamesModel.HISTROY_LENGTH;

      if (skinVersion === SkinTypeDefinition.CLASSIC) {
        this.gamesModel.historyLength = GamesModel.HISTROY_LENGTH_C4;
        switch (GAME_LOOP_LENGTH) {
          case 240: {
            GAME_VIDEO_START_MS = 202000;
            INTRO_VIDEO_LENGTH = 204;

            START_NEXT_VIDEO_START_OFFSET = 1;
            START_NEXT_TO_SEC_DELAY = 600;
            this.raceVideoDelay = 0;

            break;
          }
          default: {
            const error = Errors.INVALID_LENGTH;
            error.message = error.message + ":" + GAME_LOOP_LENGTH;
            ErrorHandler.instance.normalErrorHandler(Errors.INVALID_LENGTH, true);
            return;
          }
        }
      }
    }

    Logger.debug("Inital time const, START_NEXT_VIDEO_START_OFFSET:" + START_NEXT_VIDEO_START_OFFSET + " START_NEXT_TO_SEC_DELAY:" + START_NEXT_TO_SEC_DELAY);

    // -------------- Sync Prameters ---------------------------------------------

    this.continiousSync = Util.getUrlParameterBool(window.location.href, "contsync", initResult.param.contsync);
    this.extraLoadTime = Util.getUrlParameterInt(window.location.href, "extraload", initResult.param.extraload);
    this.maxRaceSyncUpdates = Util.getUrlParameterInt(window.location.href, "maxraceupd", initResult.param.maxraceupd);

    Logger.debug("Sync Parameters, continiousSync:" + this.continiousSync + " extraLoadTime:" + this.extraLoadTime + " maxRaceSyncUpdates:" + this.maxRaceSyncUpdates);

    // ---------------------------------------------------------------------------

    // set prepare for new game loop sync time bcause of extra time parameter
    if (this.extraLoadTime > START_NEXT_TO_SEC_DELAY) {
      const rest = this.extraLoadTime % 1000;
      const seconds = (this.extraLoadTime - rest) / 1000;

      START_NEXT_VIDEO_START_OFFSET += seconds + 1;

      START_NEXT_TO_SEC_DELAY = 1000 - (this.extraLoadTime - START_NEXT_TO_SEC_DELAY);
    } else {
      START_NEXT_TO_SEC_DELAY -= this.extraLoadTime;
    }

    Logger.debug("correc time const, START_NEXT_VIDEO_START_OFFSET:" + START_NEXT_VIDEO_START_OFFSET + " START_NEXT_TO_SEC_DELAY:" + START_NEXT_TO_SEC_DELAY);

    this.gameTimer = new GameTimer(this, 500, 10000, GAME_LOOP_LENGTH, GAME_VIDEO_START_MS, INIT_INTERVAL);

    const gameInfo: IGameInfo = {
      // TODO TEST
      // videoLanguage: "it", // it for italian mode

      videoLanguage: initResult.setting.videoLanguage, // it for italian mode
      gameType,
      eventType,
      gameSkin: skinVersion,
      gameLength: GAME_LOOP_LENGTH,
      performance,
      music: initResult.music,
      speakerTimesArray: [],
      haveDbPot: initResult.haveDbPot,
      oddsAlwaysOn: skinVersion === SkinTypeDefinition.MODERN_ODDS_ALWAYS_ON
    };

    if (gameType === "box") {
      const headerTexture = await Logic.loadTexture(headerImageUrl);
      const inFightTexture = await Logic.loadTexture(inFightImageUrl);
      const inFightTextureBig = await Logic.loadTexture(inFightImageBigUrl);
      const fightResultHexTexture = await Logic.loadTexture(fightResultHexUrl);
      const wipeBackgroundTexture = await Logic.loadTexture(wipeBackgroundImageUrl);
      const resultBackgroundTexture = await Logic.loadTexture(resultBackgroundImageUrl);
      gameInfo.additionalTextures = {
        headerImage: headerTexture,
        inFightImage: inFightTexture,
        inFightImageBig: inFightTextureBig,
        fightResultHexImage: fightResultHexTexture,
        wipeBackgroundTexture,
        resultBackgroundTexture
      };
    }
    if (initResult.setting.videooverlayLogo !== "none") {
      if (gameInfo.gameSkin === SkinTypeDefinition.CLASSIC) {
        const logoTexture = await Logic.loadTexture(
          "https://rdweb.racingdogs.eu/dsa4/?rt=3&cmd=logoRequest&type=videooverlay&name=" + initResult.setting.videooverlayLogo + "&game_type=" + gameInfo.eventType,
          { crossOrigin: "anonymous", mipmap: true }
        );
        const logoBackground = await Logic.loadTexture(
          "https://rdweb.racingdogs.eu/dsa4/?rt=3&cmd=logoRequest&type=videooverlay&name=" + initResult.setting.videooverlayLogo + "&variant=background&game_type=" + gameInfo.eventType,
          { crossOrigin: "anonymous", mipmap: true }
        );
        const logoText = await Logic.loadTexture(
          "https://rdweb.racingdogs.eu/dsa4/?rt=3&cmd=logoRequest&type=videooverlay&name=" + initResult.setting.videooverlayLogo + "&variant=text&game_type=" + gameInfo.eventType,
          { crossOrigin: "anonymous", mipmap: true }
        );

        gameInfo.companyLogo = { image: logoTexture, imageBackground: logoBackground, imageText: logoText };
      } else {
        const logoTexture = await Logic.loadTexture("https://rdweb.racingdogs.eu/dsa4/?rt=3&cmd=logoRequest&type=videooverlayTv2&name=" + initResult.setting.videooverlayLogo, { mipmap: true });
        gameInfo.companyLogo = { image: logoTexture };
      }
    }

    Logger.debug("Game Type:" + gameInfo.gameType);
    Logger.debug("Music settings:" + JSON.stringify(gameInfo.music));

    // set language and transletion which have been read from server
    Languages.instance.setTranslations(initResult.translations);
    Languages.instance.updateLanguage(initResult.setting.languageId, false);

    this.initGame(gameInfo);

    Languages.instance.setLangFields();

    // load intro texture => should be loaded before fade to intro ...
    if (gameInfo.gameType !== "box") {
      const introUrls = this.getIntroUrls(initResult);
      this.fillIntroTexture(await this.loadTexture(introUrls.image, true));
      // when intro video was loaded (note async await...) => start video at a certain position

      if (introUrls.sound && this.isIntroSoundEnabled()) Logic.loadIntroMusic(introUrls.sound);
    } else {
      // kickbox intro first picture read when round info is set
      const introUrls = this.getIntroUrls(initResult);
      if (introUrls.sound && this.isIntroSoundEnabled()) Logic.loadIntroMusic(introUrls.sound);
    }
    // await this.sendMessage({ type: "test", data: { x: 2, data: "Message from WebView to React" } });
    // return { gameType: "kart5" };
  }

  public isAllreadySetUp(): boolean {
    if (this.gameInfo !== undefined && this.gameInfo !== null) {
      return true;
    }

    return false;
  }

  // called when player touches the VideoOverlay -> start the video with the current server time
  public async onStarted() {
    Logger.debug("On Started");
    await this.initAction(true);
  }

  private async errorHandler(e: any, firstSetUp: boolean) {
    try {
      // set Language token if not allready set
      if (Languages.instance.getTranslations() === null) {
        const translationResult: ISockServResponseMessageTranslation = await ServerSocketLogic.instance.sendTranslationRequest();
        Languages.instance.setTranslations(translationResult.translations);
      }
    } catch (error) {
      // ignore error
    }

    if (e.code === Errors.TIME_REQUEST_TO_LONG.code || !firstSetUp) {
      Logger.error("Error:" + JSON.stringify(e, Object.getOwnPropertyNames(e)));

      setTimeout(() => {
        // restart automatic after some time
        this.tryRestart();
      }, Settings.onlineRetryTime);
    } else if (e.errorId === parseInt(Errors.DEV_NOT_ACTIVE.code, 10) || e.errorId === parseInt(Errors.DEV_REGISTERED.code, 10)) {
      const errElem = document.getElementById("startInfo");
      if (errElem) {
        let message = "";

        if (Languages.instance.getTranslations() !== null) {
          message = Languages.instance.getText("errTxt_" + e.errorId);
        } else {
          message = Errors.DEV_NOT_ACTIVE.message;

          if (e.errorId === parseInt(Errors.DEV_REGISTERED.code, 10)) {
            message = Errors.DEV_REGISTERED.message;
          }
        }

        message = message.replace("___DEVID___", "<span style='display:inline-block;margin:10px 0px;'>" + this.deviceId + "</span>");

        const msgElem = document.getElementById("startInfoMsg");
        if (msgElem) {
          msgElem.innerHTML = message;
        }

        errElem.style.display = "block";
      }
    } else {
      Logger.error("Set Up Error:" + JSON.stringify(e, Object.getOwnPropertyNames(e)));
      const errObj = new ErrorObj();
      errObj.message = JSON.stringify(e, Object.getOwnPropertyNames(e));
      errObj.code = Errors.SETUP.code;

      ErrorHandler.instance.normalErrorHandler(errObj, true, () => {
        // reload window on confirm
        this.reloadWidow();
      });

      setTimeout(() => {
        // reload window automatic after some time
        this.reloadWidow();
      }, Settings.onlineRetryTime);
    }
  }

  /**
   * connects to server and reads static settings for device
   * @param firstSetUp
   */
  public async initServerAction(firstSetUp: boolean) {
    this.initResult = null;

    try {
      if (!ServerSocketLogic.instance.serverSocketClient.isWebsocketOpen()) {
        await ServerSocketLogic.instance.connectWebSocketClient();
      }

      ErrorHandler.instance.hideErrorDialog();

      this.initResult = await ServerSocketLogic.instance.sendInitRequest(this.deviceId);

      if (this.deviceId.length === 17 && this.deviceId.indexOf(":") > 1) {
        // device ID is a mac address
        Logic.isMacAddressDevice = true;
        // --> set timezone from geolocation
        await Util.setTimezoneOffsetFromGeolocation();

        const logMessage = new SockServLogMessage(
          Errors.LOG_INFO.code,
          "Geolocation lat:" + Util.geoResult.lat + " lon:" + Util.geoResult.lon + " Timezone:" + Util.timezoneId + "  offset to UTC:" + Util.timeZoneOffset
        );
        ServerSocketLogic.instance.sendLogRequest(logMessage).catch((errorData) => {
          Logger.error("Send log Error:" + JSON.stringify(errorData));
        });
      }

      // TODO TEST
      // throw new Error("e");
    } catch (e) {
      this.errorHandler(e, firstSetUp);
    }
  }

  /**
   * Reads current round infos from Server
   * calls initSettings
   * and handles race breaks
   * @param firstSetUp
   */
  public async initAction(firstSetUp: boolean) {
    this.canPlayOnSetUp = true;
    this.setUpCanplayReceived = false;
    this.setUpVideoStarted = false;

    if (this.initResult !== null) {
      try {
        // TODO TEST
        // await Util.sleep(10000);

        // read actual init games
        const sendData = new SockServGameRoundMessage(null, INIT_NUMB_PAST * -1, INIT_NUMB_FUTURE);
        const result: ISockServResponseMessageGameRound = await ServerSocketLogic.instance.sendGameRequest(sendData);

        // TODO TEST no past games
        // for(let n = 0 ; n < 7 ; n++){
        //   result.gamepool.shift();
        // }

        // TODO TEST
        // for(const game of result.gamepool){
        //
        //   if(game.id === "541_105_20210608" + "0262"){
        //     game.finish = null;
        //   }
        // }

        Logger.info("After init send Game round");
        const initData = { initResult: this.initResult, gamepool: result.gamepool, rttStatistics: result.rttStatistics };

        this.maxRoundNumber = initData.initResult.setting.betoffers[0].firstNumber + initData.initResult.setting.betoffers[0].nbrEvents - 1;

        // TODO TEST
        // await Util.sleep(10000);

        await this.initSettings(initData);

        if (this.gamesModel.showRaceBreakAtStart) {
          Logger.info("Game Paused at start:");
          this.canPlayOnSetUp = false;
          this.gamesModel.showGamePauseView(false);
        } else {
          Logger.info("Set Start video Time:" + this.intiVideoStartTime);
          Logic.startPlayingWithTime(this.intiVideoStartTime);
        }

        // TODO TEST
        // if(testCount === 1)
        //   throw new Error("e");

        // testCount++;
      } catch (e) {
        this.errorHandler(e, firstSetUp);
      }
    } else {
      ErrorHandler.instance.normalErrorHandler(Errors.INIT_RES_NOT, true);
    }
  }

  // destroy resources ...
  public onExit(): void { }

  public onCanPlay(preparedVideoTime: number) {
    Logger.info("onCanPlay: " + preparedVideoTime);
    Logger.debug("Exact Video Second:" + this.gameTimer.getExactVideoSec());

    if (this.gamesModel.showRaceBreakAfterStartVideo) {
      this.gamesModel.showRaceBreakAfterStartVideo = false;
      this.getGamesModel().showGamePauseView(false);
    }

    if (this.canPlayOnSetUp) {
      this.canPlayOnSetUp = false;
      this.setUpCanplayReceived = true; // remember set up can play received

      if (Logic.getState() === VideoState.Intro) {
        this.canPlayIntroReceived = true;
      }
      Logger.info("Start first Playing of video preparedVideoTime:" + preparedVideoTime + " getVideoSec:" + this.gameTimer.getVideoSec());
      if ((this.gameTimer.getVideoSec() - preparedVideoTime) * 1000 > MAX_START_OFFSET) {
        // can play came to late

        // Update video time and play emediatly

        const setStartTime = this.gameTimer.getExactVideoSec();
        Logger.info("Start first Playing of video force, Set Start video Time:" + setStartTime);
        Logic.setVideoTime(setStartTime + VIDEO_START_DELAY_TIME + this.extraLoadTime / 1000);
        this.setUpVideoStarted = true; // remember that allready started
        Logic.play();
        Logger.debug("Start Play 1");
        if (Logic.getState() === VideoState.Race) {
          this.raceVideoStarted = true;
        }
      } else if (this.gamesModel.raceBreak) {
        this.setUpVideoStarted = true;
        Logger.info("In Race Break, handle as started");
      }
    } else {
      if (Logic.getState() === VideoState.Intro) {
        // Intro Video Can play

        let timeChanged: boolean = false;
        if (!this.canPlayIntroReceived) {
          // just hadle first can play event

          Logger.info("Can Play Intro Video, video Second:" + this.gameTimer.getVideoSec());
          this.canPlayIntroReceived = true;
          // Can play came to late
          const exactVideoSecond = this.gameTimer.getExactVideoSec();

          // for safety if intro video starts to late
          if ((exactVideoSecond - preparedVideoTime) * 1000 > MAX_INTRO_OFFSET && this.gameTimer.getVideoSec() < GAME_LOOP_LENGTH - 30) {
            Logger.info("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC needed to correct intro video time:" + exactVideoSecond);
            if (exactVideoSecond > 10) {
              // to not change state with time update
              Logger.info("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC much to late!!!");
            } else {
              // Correct Video Time
              Logic.setStateTime(exactVideoSecond + VIDEO_START_DELAY_TIME + this.extraLoadTime / 1000);
              timeChanged = true;
            }
          }
        } else {
          Logger.debug("Can Play not first time!");
        }

        Logic.play();
        Logger.debug("Start Play 2");

        if (timeChanged) {
          // When time was changed force fade to be finished
          Logger.info("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC Stop Fading");
          Logic.videoScreen.stopFading();
        }
      } else {
        this.raceVideoStarted = true;
        Logic.play(); // Race Video spimply play
        Logger.debug("Start Play 3");
      }
    }
  }

  public checkSetUpPlay(videoSec: number) {
    // when canplay was received within Time
    // start playing of video at this full second
    if (this.setUpCanplayReceived && !this.setUpVideoStarted && videoSec >= this.intiVideoStartTime) {
      this.setUpVideoStarted = true;

      if (Logic.getState() === VideoState.Intro) {
        //needs to be set bacause now there is no more pause when canplay on start up, why removed???
        Logic.setStateTime(this.intiVideoStartTime + VIDEO_START_DELAY_TIME + this.extraLoadTime / 1000);
      }

      Logic.play();
      Logger.debug("Start Play 4");
      Logger.info("Start first Playing of video");
    }
  }

  // it is time to fade to another video state (e.g. end of video, ...)
  public onTimeForFade(targetState: VideoState) {
    if (targetState === VideoState.Race) {
      // Fade To RACE
      Logger.info("Time for fade to Race ");
      this.fadeToRaceReceived = true;

      const nextModel = this.getRaceDataModelByVideoSecondsState();
      nextModel.fadeToRaceReiceived = true;

      if (nextModel.result !== null) {
        // result for to be started race allready set

        Logger.info("Next Race Model result set, fade emediatly:" + nextModel.roundInfo.fullGameId);
        const f = async () => {
          if (nextModel.result !== null) {
            let raceFirstImageUrl = "";

            if (nextModel.gameType === "box") {
              const nextModelbox = nextModel as ModelBox;
              if (nextModelbox.fightVideos) {
                if (nextModelbox.fightVideos.round1.length > 0) {
                  // to fit with standard logic from other game
                  // result first image must be set with first image of first fight
                  raceFirstImageUrl = nextModelbox.fightVideos.round1[0].jpg;
                } else {
                  Logger.error("Should at least have one clip!!");
                }
              } else {
                Logger.error("Should have fighter Videos!!");
              }
            } else {
              raceFirstImageUrl = this.getResultRaceUrl(nextModel.result).image;
            }

            this.fillRaceTexture(await this.loadTexture(raceFirstImageUrl, false));

            Logic.fadeTo(targetState);
          }
        };
        this.fadeToRaceReceived = false;
        f();
      } else {
        Logger.debug("Current Model result not set on fade time:" + nextModel.roundInfo.fullGameId);
      }
    } else if (targetState === VideoState.Paused) {
      Logic.fadeTo(targetState);
    }
  }

  // is called when result is received from Server
  public checkStartRaceVideo(resModel: IModel) {
    console.log('checkStartRaceVideo', resModel);

    if (this.fadeToRaceReceived) {
      // result fot from server after intro video finished
      this.fadeToRaceReceived = false;

      const nextModel = this.getRaceDataModelByVideoSecondsState();

      if (nextModel.roundInfo.gameId !== resModel.roundInfo.gameId) {
        const error = Errors.RESULT_WRONG_GAME;
        error.message = Errors.RESULT_WRONG_GAME.message + ", id Next:" + nextModel.roundInfo.gameId + ", id Result:" + resModel.roundInfo.gameId;
        ErrorHandler.instance.normalErrorHandler(error, false);
      }

      if (nextModel.result !== null) {
        Logger.info("Current Model result set, fade to race after result received:" + resModel.roundInfo.gameId);
        const f = async () => {
          if (nextModel.result !== null) {
            let raceFirstImageUrl = "";

            if (nextModel.gameType === "box") {
              const nextModelbox = nextModel as ModelBox;
              if (nextModelbox.fightVideos) {
                if (nextModelbox.fightVideos.round1.length > 0) {
                  // to fit with standard logic from other game
                  // result first image must be set with first image of first fight
                  raceFirstImageUrl = nextModelbox.fightVideos.round1[0].jpg;
                } else {
                  Logger.error("Should at least have one clip!!");
                }
              } else {
                Logger.error("Should have fighter Videos!!");
              }
            } else {
              raceFirstImageUrl = this.getResultRaceUrl(nextModel.result).image;
            }

            this.fillRaceTexture(await this.loadTexture(raceFirstImageUrl, false));

            Logic.fadeTo(VideoState.Race);
          }
        };
        f();
      }
    }
    return false;
  }

  public checkResultDalayToBig() {
    const curModel = this.gamesModel.getCurrentGameData();

    if (curModel.fadeToRaceReiceived && curModel.result === null) {
      curModel.gotNoResult = true;
      const nextRaceModel = this.gamesModel.getNextGameData();

      if (nextRaceModel !== null && typeof nextRaceModel !== "undefined") {
        Logic.showPauseOverlay("immediately", {
          pauseEndTimeText: Logic.implementation.getText("canceled"),
          bottomText: Logic.implementation.getText("roundCanceled"),
          nextRaceTime: new Date(nextRaceModel.roundInfo.videoStartUnix),
          nextRound: nextRaceModel.roundInfo
        });
      } else {
        // no next game round
        // show race breake screen with standart races start time
        let startString = "";
        
        const initResult = (Logic.implementation as LogicImplementation).getInitResult();

        if (initResult !== null) {
          startString = Util.formatRaceBreakTime(initResult.setting.betoffers[0].starttime);
        }

        Logic.showPauseOverlay("immediately", {
          pauseEndTimeText: startString,
          bottomText: Logic.implementation.getText("raceBreakTxt")
        });
      }

      const logMessage = new SockServLogMessage(Errors.RESULT_NOT_RECEIVED.code, Errors.RESULT_NOT_RECEIVED.message + curModel.roundInfo.fullGameId);
      ServerSocketLogic.instance.sendLogRequest(logMessage).catch((errorData) => {
        Logger.error("Send log Error:" + JSON.stringify(errorData));
      });
    }
  }

  // it is time to start playing
  public onTimeForPlay(targetState: VideoState) {
    Logger.info("onTimeForPlay: " + targetState);

    // if (targetState === VideoState.Intro) {
    //   this.canPlayIntroReceived = false;
    // }

    Logic.startPlayingWithState(targetState, targetState === VideoState.Intro ? 0.0 : 0.0);
  }

  public onFillStateInfo(state: VideoState): VideoUrlInfo[] {
    Logger.debug("onFillStateInfo: " + state);

    if (
      this.gamesModel.raceBreak && // dont react during race break
      !(state === VideoState.Race && this.gamesModel.checkIfLastBeforeABreak(this.getRaceDataIndexByVideoSecondsState(), null))
    ) {
      // only if last race is started
      Logger.debug("Is in race braek: " + state);
      return [];
    }

    if (state === VideoState.Intro) {
      Logger.info("onStartIntro");
      const initCurModel = this.onStartIntroAction(false, false);
      if (initCurModel && initCurModel.gameType === "box") {
        // const logMessage = new SockServLogMessage(Errors.LOG_INFO.code,"Intro Path:" + ((initCurModel as ModelBox).getIntroKickBoxUrls((initCurModel as ModelBox).wgpInfo).video));
        // ServerSocketLogic.instance.sendLogRequest(logMessage).catch( (error) => {
        //   Logger.error("Send log Error:" + JSON.stringify(error));
        // });

        return [
          {
            url: (initCurModel as ModelBox).getIntroKickBoxUrls((initCurModel as ModelBox).wgpInfo).video,
            length: Logic.getIntroLength()
          }
        ];
      } else {
        //return [this.getIntroUrls(this.initResult!).video];
        return [
          {
            url: this.getIntroUrls(this.initResult!).video,
            length: Logic.getIntroLength()
          }
        ];
      }

      //return this.getIntroUrls(this.initResult!).video;
    } else {
      Logger.info("onStartRace");

      const curModel = this.getRaceDataModelByVideoSecondsState();
      this.lastStartRaceId = curModel.roundInfo.fullGameId;
      if (!this.getGamesModel().raceBreak && typeof this.setNextModel !== "undefined" && this.setNextModel !== null) {
        if (this.setNextModel.roundInfo.fullGameId !== curModel.roundInfo.fullGameId) {
          Logger.error("Preview of race and started are different!!! preview:" + this.setNextModel.roundInfo.fullGameId + " start:" + curModel.roundInfo.fullGameId);

          const logMessage = new SockServLogMessage(
            Errors.RACES_DIFF.code,
            "Preview of race and started are different!!! preview:" + this.setNextModel.roundInfo.fullGameId + " start:" + curModel.roundInfo.fullGameId
          );
          ServerSocketLogic.instance.sendLogRequest(logMessage).catch((error) => {
            Logger.error("Send log Error:" + JSON.stringify(error));
          });
        }
      }

      //TODO TEST
      //curModel.result = null;

      if (curModel.result === null) {
        const errorObj = Errors.RESULT_NOT_START_RACE;
        errorObj.message = errorObj.message + curModel.roundInfo.fullGameId;
        ErrorHandler.instance.normalErrorHandler(errorObj, false);

        return [];
      } else {
        Logger.info("Start Race Video Set Data:" + curModel.roundInfo.fullGameId + " Result:" + JSON.stringify(curModel.result));

        // format result time
        const formatedResul = Object.assign({}, curModel.result);
        formatedResul.first = Object.assign({}, curModel.result.first);
        formatedResul.second = Object.assign({}, curModel.result.second);
        if (curModel.result.third) {
          formatedResul.third = Object.assign({}, curModel.result.third);
        }
        const firstTime = parseFloat(curModel.result.first.time);
        if (firstTime < 60) {
          formatedResul.first.time = Logic.implementation.formatTime(firstTime, {
            minutes: false,
            seconds: true,
            hundredth: true
          });
        } else {
          formatedResul.first.time = Logic.implementation.formatTime(firstTime, {
            minutes: true,
            seconds: true,
            hundredth: true
          });
        }
        const secondTime = parseFloat(curModel.result.second.time);
        if (secondTime < 60) {
          formatedResul.second.time = Logic.implementation.formatTime(secondTime, {
            minutes: false,
            seconds: true,
            hundredth: true
          });
        } else {
          formatedResul.second.time = Logic.implementation.formatTime(secondTime, {
            minutes: true,
            seconds: true,
            hundredth: true
          });
        }

        Logger.debug("Fill Result:" + curModel.roundInfo.fullGameId + " Result:" + JSON.stringify(formatedResul));
        if (Logic.videoScreen instanceof VideoScreenHorseC4) {
          Logic.videoScreen.fillResult(formatedResul);
        } else if (!(Logic.videoScreen instanceof VideoScreenHorseC4)) {
          Logic.videoScreen.fillResult(curModel.track, curModel.roundInfo, curModel.colors, formatedResul, curModel.raceIntervals, curModel.drivers, curModel.odds);
        }

        // at horse video mute time depends on finish1 time
        if ((curModel.gameType === "horse" || curModel.gameType === "sulky") && formatedResul.resultOffsetTime) {
          Logic.videoRef.horseMuteTime = formatedResul.resultOffsetTime;
        }

        if (curModel.gameType === "box") {
          const kickboxScreen = Logic.videoScreen as VideoScreenKickBox;
          const kickboxModel = curModel as ModelBox;
          let resolvedUrls: string[] = [];

          if (kickboxScreen !== undefined && kickboxModel.fightResult && kickboxModel.fightVideos) {
            // for kickbox we need some additional data
            // additionally the videourls need to be set up correctly
            kickboxScreen.fillAdditionalResult(kickboxModel.drivers, kickboxModel.fightResult, kickboxModel.fightVideos);

            Logger.debug("Set Hits:" + JSON.stringify(kickboxModel.fightResult.hits));

            resolvedUrls = (curModel as ModelBox).getRaceVideos();
            const raceVideoInfos = (curModel as ModelBox).getRaceVideoInfos();

            const result = resolvedUrls.map((x) => {
              return {
                url: x,
                length: raceVideoInfos.filter((info) => x.includes(info.url))[0].length
              };
            });

            Logger.debug("Set Kickbox Videos:" + JSON.stringify(result));

            return result;
          } else {
            Logger.error("Error kickbox no screen fighterResult of fighterVideos");
            const logMessage = new SockServLogMessage(Errors.KICKBOX_DATA_ERROR.code, "Error kickbox no screen fighterResult of fighterVideos");
            ServerSocketLogic.instance.sendLogRequest(logMessage).catch((errorData) => {
              Logger.error("Send log Error:" + JSON.stringify(errorData));
            });
          }
        } else {
          const videoUrl = this.getResultRaceUrl(curModel.result).video;
          return [
            {
              url: videoUrl,
              length: Logic.getRaceLength()
            }
          ];
        }
      }
    }

    return [];
  }

  private isHorseC4Model(object: unknown): object is IHorseDog6C4Model {
    return Object.prototype.hasOwnProperty.call(object, "prevRoundInfo");
  }

  public getIntroUrls(initResult: ISockServResponseMessageInit): IVideoUrls {
    if (initResult !== null) {

      let videoData = {
        video: initResult.intro.mp4,
        image: initResult.intro.jpg,
        sound: initResult.intro.mp3
      };


      // TODO: This is a hack to get the video to play from the local server

      /* let assetsUrl = window.location.origin + "/assets/";

      if (videoData.video.includes("/sdcard/")) {
        videoData.video = videoData.video.replace(
          "/sdcard/",
          assetsUrl
        );
      }

      if (videoData.image.includes("/sdcard/")) {
        videoData.image = videoData.image.replace(
          "/sdcard/",
          assetsUrl
        );
      }

      if (videoData.sound.includes("/sdcard/")) {
        videoData.sound = videoData.sound.replace(
          "/sdcard/",
          assetsUrl
        );
      }
 */

      // TODO TEST
      // if(initResult.setting.betoffers.length > 0){
      //   if(this.initResult?.setting.betoffers[0].eventtype === "horse"){
      //     videoData = {video: "/.local/horse/intro_5_h.mp4?token=ZMQF9G3MSX3UQZWC", image: "/.local/horse/intro_5_h.jpg?token=ZMQF9G3MSX3UQZWC", sound: "/.local/horse/intro.mp3?token=ZMQF9G3MSX3UQZWC"};
      //   }
      // }
      // TODO TEST
      // if(initResult.setting.betoffers.length > 0){
      //   if(this.initResult?.setting.betoffers[0].eventtype === "dog63"){
      //     videoData = {video: "/.local/dog63/intro_5_it_h.mp4?token=ZMQF9G3MSX3UQZWC", image: "/.local/dog63/intro_5_it_h.jpg?token=ZMQF9G3MSX3UQZWC", sound: "/.local/dog63/intro.mp3?token=ZMQF9G3MSX3UQZWC"};
      //   }
      // }
      // TODO TEST
      // videoData.image = "/.local/sulky7/intro_6_h50.jpg?token=ZMQF9G3MSX3UQZWC";
      // videoData.sound =  "/.local/sulky7/intro.mp3?token=ZMQF9G3MSX3UQZWC";
      // if(initResult.setting.betoffers.length > 0){
      //   if(this.initResult?.setting.betoffers[0].eventtype === "sulky"){
      //     videoData = {video: "/.local/sulky7/intro_6_h50.mp4?token=ZMQF9G3MSX3UQZWC", image: "/.local/sulky7/intro_6_h50.jpg?token=ZMQF9G3MSX3UQZWC", sound: "/.local/sulky7/intro.mp3?token=ZMQF9G3MSX3UQZWC"};
      //   }
      // }

      // // TODO TEST
      // if(initResult.setting.betoffers.length > 0){
      //   if(this.initResult?.setting.betoffers[0].eventtype === "horse"){
      //     videoData = {video: "/.local/horse_c4/intro_4_h.mp4?token=ZMQF9G3MSX3UQZWC", image: "/.local/horse_c4/intro_4_h.jpg?token=ZMQF9G3MSX3UQZWC", sound: "/.local/horse_c4/intro.mp3?token=ZMQF9G3MSX3UQZWC"};
      //     // videoData = {video: "/sdcard/DSVideo/horse/intro_4_h.mp4?token=ZMQF9G3MSX3UQZWC", image: "/sdcard/DSVideo/horse/intro_4_h.jpg?token=ZMQF9G3MSX3UQZWC", sound: "/sdcard/DSVideo/horse/intro.mp3?token=ZMQF9G3MSX3UQZWC"};
      //   }
      // }

      // // TODO TEST
      if (initResult.setting.betoffers.length > 0) {
        if (this.initResult?.setting.betoffers[0].eventtype === "dog") {
          //videoData = {video: "/.local/dog6_c4/intro_4_h.mp4?token=ZMQF9G3MSX3UQZWC", image: "/.local/dog6_c4/intro_4_h.jpg?token=ZMQF9G3MSX3UQZWC", sound: "/.local/dog6_c4/intro.mp3?token=ZMQF9G3MSX3UQZWC"};
          videoData = { video: "/sdcard/DSVideo/dog/intro.mp4", image: "/sdcard/DSVideo/dog/intro.jpg", sound: "/sdcard/DSVideo/dog/intro.mp3" };
        }
      }

      // TODO TEST
      // if(initResult.setting.betoffers.length > 0){
      //   if(this.initResult?.setting.betoffers[0].eventtype === "rtt"){
      //     videoData = {video: "/.local/roulette_c4/intro_4_h.mp4?token=ZMQF9G3MSX3UQZWC", image: "/.local/roulette_c4/intro_4_h.jpg?token=ZMQF9G3MSX3UQZWC", sound: "/.local/roulette_c4/intro.mp3?token=ZMQF9G3MSX3UQZWC"};
      //     // videoData = {video: "/sdcard/DSVideo/rtt17/intro_4_h.mp4?token=ZMQF9G3MSX3UQZWC", image: "/sdcard/DSVideo/rtt17/intro.jpg?token=ZMQF9G3MSX3UQZWC", sound: "/sdcard/DSVideo/rtt17/intro.mp3?token=ZMQF9G3MSX3UQZWC"};
      //   }
      // }

      Logger.debug("Intro Video Data:" + JSON.stringify(videoData));
      return this.fixVideoUrlsPath(videoData);
      return videoData;
    } else {
      return { video: "", image: "" };
    }
  }

  public getResultRaceUrl(result: IResultExtern): IVideoUrls {
    // TODO TEST
    // if(this.initResult){
    //   if(this.initResult.setting.betoffers.length > 0) {
    //     if (this.initResult?.setting.betoffers[0].eventtype === "horse") {
    //       const vidUrls: IVideoUrls = {video: "/.local/horse_c4/R0001_h.mp4", sound: "/.local/horse_c4/intro.mp3", image: "/.local/horse_c4/R0001_h.jpg"}
    //       // const vidUrls: IVideoUrls = {video: "/sdcard/DSVideo/horse/R0001_h.mp4", sound: "/sdcard/DSVideo/horse/intro.mp3", image: "/sdcard/DSVideo/horse/R0001_h.jpg"}
    //       return vidUrls;
    //     }
    //   }
    // }
    // TODO TEST
    // if(this.initResult){
    //   if(this.initResult.setting.betoffers.length > 0) {
    //     if (this.initResult?.setting.betoffers[0].eventtype === "dog") {
    //       const vidUrls: IVideoUrls = {video: "/.local/dog6_c4/R0001_h.mp4", sound: "/.local/dog6_c4/intro.mp3", image: "/.local/dog6_c4/R0001_h.jpg"}
    //       // const vidUrls: IVideoUrls = {video: "/sdcard/DSVideo/dog/R0001_h.mp4", sound: "/sdcard/DSVideo/dog/intro.mp3", image: "/sdcard/DSVideo/dog/R0001_h.jpg"}
    //       return vidUrls;
    //     }
    //   }
    // }
    // TODO TEST
    // if(this.initResult){
    //   if(this.initResult.setting.betoffers.length > 0) {
    //     if (this.initResult?.setting.betoffers[0].eventtype === "rtt") {
    //       const vidUrls: IVideoUrls = {video: "/.local/roulette_c4/R0001_h.mp4", sound: "/.local/roulette_c4/intro.mp3", image: "/.local/roulette_c4/R0001_h.jpg"}
    //       // const vidUrls: IVideoUrls = {video: "/sdcard/DSVideo/rtt17/00_01_g.fhd.mp4", sound: "/sdcard/DSVideo/rtt17/intro.mp3", image: "/sdcard/DSVideo/rtt17/00_01_g.fhd.jpg"}
    //       return vidUrls;
    //     }
    //   }
    // }

    console.info('getResultRaceUrl', result);

    return this.getVideoUrlsWithLink(result.videoname);
  }

  public getRaceDataModelByVideoSecondsState(): ModelBase {
    // when round switch done in Game Model
    if (this.gameTimer.getVideoSec() >= Util.floatNumber(GAME_VIDEO_START_MS / 1000, 3)) {
      // to be started race is current
      return this.gamesModel.getCurrentGameData();
    } else {
      // video time before round switch

      // to be started race is next
      return this.gamesModel.getNextGameData();
    }
  }

  public getRaceDataIndexByVideoSecondsState(): number {
    // when round switch done in Game Model
    if (this.gameTimer.getVideoSec() >= Util.floatNumber(GAME_VIDEO_START_MS / 1000, 3)) {
      // to be started race is current
      return this.gamesModel.getIndexCurGame();
    } else {
      // video time before round switch

      // to be started race is next
      return this.gamesModel.getIndexCurGame() - 1;
    }
  }

  public onStartIntroAction(simulateGameSwitchDone: boolean, ignoreRaceBreak: boolean): ModelBase {
    if (this.gamesModel.raceBreak && !ignoreRaceBreak) {
      Logger.debug("ON Start Intro Action return because off racebreak"); // TODO Handle racebreak correctly!
      const model = this.gamesModel.getCurrentGameData();
      return model;
    }

    let curModel: ModelBase | null = null;
    let beforeModel: ModelBase | null = null;

    Logger.debug(
      "ON Start Intro Action getVideoSec:" +
      this.gameTimer.getVideoSec() +
      " GAME_VIDEO_START_MS/ 1000:" +
      Util.floatNumber(GAME_VIDEO_START_MS / 1000, 3) +
      " INTRO_VIDEO_LENGTH:" +
      INTRO_VIDEO_LENGTH
    );

    // at start up when game loop switch allready done, but intro video not finished
    if (this.gameTimer.getVideoSec() >= Util.floatNumber(GAME_VIDEO_START_MS, 3) / 1000 && this.gameTimer.getVideoSec() < INTRO_VIDEO_LENGTH && !simulateGameSwitchDone) {
      Logger.debug(
        "ON Start Intro Action, take current race data at start up, getVideoSec:" +
        this.gameTimer.getVideoSec() +
        " GAME_VIDEO_START_MS:" +
        GAME_VIDEO_START_MS +
        " INTRO_VIDEO_LENGTH:" +
        INTRO_VIDEO_LENGTH
      );
      // take current (allready started game) data
      curModel = this.gamesModel.getCurrentGameData();
      if (Logic.videoScreen instanceof VideoScreenHorseC4 || Logic.videoScreen instanceof VideoScreenRouletteC4) {
        beforeModel = this.gamesModel.getBeforeGameData();
      }
    } else {
      // normal loop take nex game data
      curModel = this.gamesModel.getNextGameData();
      if (Logic.videoScreen instanceof VideoScreenHorseC4 || Logic.videoScreen instanceof VideoScreenRouletteC4) {
        beforeModel = this.gamesModel.getCurrentGameData();
      }
    }

    this.setNextModel = curModel;
    // model.currentRound++;
    Logger.info(
      "BBBBBBBBBBBBBBBBBB for round:" +
      curModel.roundInfo.gameId +
      " bonus Value:" +
      curModel.roundInfo.jackpotValue +
      " Old bonus Value:" +
      curModel.roundInfo.oldJackpotValue +
      " bonus History:" +
      curModel.jackpotHistory
    );
    Logger.debug("BBBBBBBBBBBBBBBBBB history:" + JSON.stringify(curModel.history));

    if (curModel.roundInfo.jackpotValue && curModel.roundInfo.oldJackpotValue) {
      if (curModel.roundInfo.oldJackpotValue > curModel.roundInfo.jackpotValue) {
        // when jackpot was won, old is bigger than new --> set old to new to not count down
        curModel.roundInfo.oldJackpotValue = curModel.roundInfo.jackpotValue;
        Logger.debug("Set old to new Jackpot Value:" + curModel.roundInfo.oldJackpotValue + " for round:" + curModel.roundInfo.fullGameId);
      }
    }

    let setRoundInfo = curModel.roundInfo;
    let setHistory = curModel.history;
    this.hasItalianShedule = false;
    if (
      curModel.roundInfo.it_code_event !== undefined &&
      curModel.roundInfo.it_code_event !== null &&
      curModel.roundInfo.it_code_event !== "" &&
      curModel.roundInfo.it_code_schedule !== undefined &&
      curModel.roundInfo.it_code_schedule !== null &&
      curModel.roundInfo.it_code_schedule !== ""
    ) {
      this.hasItalianShedule = true;

      setRoundInfo = Object.assign({}, curModel.roundInfo);
      setRoundInfo.sendPlan = curModel.roundInfo.it_code_schedule;
      setRoundInfo.raceNumber = curModel.roundInfo.it_code_event;
      setRoundInfo.gameId = parseInt(curModel.roundInfo.it_code_event, 10);

      setHistory = [];

      for (const curHistory of curModel.history) {
        const copyHist = Object.assign({}, curHistory);
        if (copyHist.it_code_event) {
          copyHist.round = parseInt(copyHist.it_code_event, 10);
        }
        setHistory.push(copyHist);
      }
    }
    if (Logic.videoScreen instanceof VideoScreenRouletteC4) {
      const rttHistory: IRouletteRoundHistory[] = [];
      for (const histElem of setHistory) {
        rttHistory.push({ round: histElem.round, winnerNumber: histElem.first.driverIndex });
      }

      if (beforeModel) {
        const lastRoundInfo = beforeModel.roundInfo;
        lastRoundInfo.winningNumber = beforeModel?.result?.first.driverIndex;
        Logic.videoScreen.fillLastRoundInfo(lastRoundInfo);
      } else {
        Logger.error("No before Model data");
      }

      Logic.videoScreen.fillRound(setRoundInfo, rttHistory, (beforeModel as ModelRtt).statusData);
    } else if (Logic.videoScreen instanceof VideoScreenHorseC4) {
      if (beforeModel) {
        Logic.videoScreen.fillPrevRound(beforeModel.roundInfo);
      } else {
        const logMessage = new SockServLogMessage(
          Errors.LOG_INFO.code,
          "Geolocation lat:" + Util.geoResult.lat + " lon:" + Util.geoResult.lon + " Timezone:" + Util.timezoneId + "  offset to UTC:" + Util.timeZoneOffset
        );
        ServerSocketLogic.instance.sendLogRequest(logMessage).catch((errorData) => {
          const errMsg = "Before Model not set game:" + this.gameInfo.gameType;
          Logger.error(errMsg);
          const logMessage = new SockServLogMessage(Errors.INVALID_DATA.code, errMsg);
          ServerSocketLogic.instance.sendLogRequest(logMessage).catch((errorData) => {
            Logger.error("Send log Error:" + JSON.stringify(errorData));
          });
        });
      }

      const bonus: IHorseC4Bonus = ModelHorseC4.bonus;

      if (curModel.roundInfo.jackpotValue) {
        bonus.value = curModel.roundInfo.jackpotValue;
      }

      bonus.infoText4 = Languages.instance.getText("infotext_lotoffun") + " " + Languages.instance.getText("c4GameNa" + this.gameInfo.eventType);
      bonus.infoText5 = Languages.instance.getText("infotext_current_id") + " " + curModel.roundInfo.fullGameId;

      if (curModel.jackpotHistory) {
        if (curModel.jackpotHistory?.length > 0) {
          const curJackpotData = curModel.jackpotHistory[0];
          bonus.infoText1 = Languages.instance.getText("infotext_last_bonus") + " " + Util.formatValueC4(curJackpotData.amountUnformated, 2) + " | " + curJackpotData.date + " " + curJackpotData.time;
          bonus.infoText2 = Languages.instance.getText("infotext_last_bonus_win") + " " + curJackpotData.name;
          bonus.infoText3 = Languages.instance.getText("infotext_winning_ticket") + " " + curJackpotData.id;
        }
      }

      Logic.videoScreen.fillRound(setRoundInfo, curModel.odds, curModel.colors, setHistory, bonus);
      if (beforeModel && beforeModel.result) {
        beforeModel.result.first.odds = beforeModel.serverOdds[beforeModel.result.first.driverIndex];
        beforeModel.result.second.odds = beforeModel.getForcastOdd(beforeModel.result.first.driverIndex, beforeModel.result.second.driverIndex);
        Logic.videoScreen.fillLastRoundResult(beforeModel.result);
      } else {
        const errMsg = "Before Model not set or no result, game:" + this.gameInfo.gameType + " current round:" + curModel.roundInfo.fullGameId;
        Logger.error(errMsg);
        const logMessage = new SockServLogMessage(Errors.INVALID_DATA.code, errMsg);
        ServerSocketLogic.instance.sendLogRequest(logMessage).catch((errorData) => {
          Logger.error("Send log Error:" + JSON.stringify(errorData));
        });
      }
    } else {
      Logic.videoScreen.fillRound(setRoundInfo, curModel.drivers, curModel.odds, setHistory, curModel.jackpotHistory, curModel.track, curModel.colors);
    }

    if (curModel.gameType === "box") {
      const kickboxScreen = Logic.videoScreen as VideoScreenKickBox;
      const kickboxModel = curModel as ModelBox;
      if (kickboxModel.resultBet && kickboxModel.fightQuotes && kickboxModel.fightHistory && kickboxModel.boxRingPresentationFacts) {
        kickboxScreen.fillRoundAdditional(kickboxModel.drivers, kickboxModel.resultBet, kickboxModel.fightQuotes, kickboxModel.fightHistory, kickboxModel.boxRingPresentationFacts);
      } else {
        Logger.error("Error, Not all kickbox data set!!");
        const logMessage = new SockServLogMessage(Errors.KICKBOX_DATA_ERROR.code, "Error, Not all kickbox data set!!");
        ServerSocketLogic.instance.sendLogRequest(logMessage).catch((errorData) => {
          Logger.error("Send log Error:" + JSON.stringify(errorData));
        });
      }

      if (kickboxModel.wgpInfo) {
        // load first image of Intro Video
        this.loadTexture(kickboxModel.wgpInfo?.jpg, true).then((textureImg) => {
          this.fillIntroTexture(textureImg);
        });
      } else {
        Logger.error("Error, kickbox data wpgInfo not set!!");
        const logMessage = new SockServLogMessage(Errors.KICKBOX_DATA_ERROR.code, "Error, kickbox data wpgInfo not set!!");
        ServerSocketLogic.instance.sendLogRequest(logMessage).catch((errorData) => {
          Logger.error("Send log Error:" + JSON.stringify(errorData));
        });
      }
    } else if (curModel.gameType === "dog63") {
      // fill additional Data for dog63
      const dog63Screen = Logic.videoScreen as VideoScreenDog63;
      const dog63Model = curModel as ModelDog63;
      let oddsGridFirstTwoInOrder = true;
      if (this.initResult) {
        oddsGridFirstTwoInOrder = this.initResult.setting.oddsGridFirstTwoInOrder;
      }

      if (dog63Model.dog63History && dog63Model.dog63Suprimi && dog63Model.dog63Quotes && dog63Model.dog63rd && dog63Model.dog63QuotesSide)
        dog63Screen.fillRoundAdditional(
          dog63Model.roundInfo,
          dog63Model.drivers,
          dog63Model.dog63History,
          dog63Model.dog63Suprimi,
          dog63Model.dog63Quotes,
          dog63Model.dog63rd,
          dog63Model.odds,
          dog63Model.dog63QuotesSide,
          oddsGridFirstTwoInOrder
        );
    }

    ErrorHandler.instance.checkHideErrorDialog();
    return curModel;
  }

  //  called from time to time with time of video (there might be a little gap between intro and race...)
  // this might be used to load data from server (e.g. prefil model for next round or race....)
  public onVideoTimeUpdate(videoTime: number) {
    if (videoTime === 0) {
      // Logger.debug("OnTimeUpdate: " + videoTime + ", timestamp:" + Date.now());
    } else {
      // Logger.debug("OnTimeUpdate: " + videoTime );
    }
  }

  // general update event -> fired more often and even if no video plays
  public onUpdate(deltaTime: number) {
    if (this.continiousSync && !this.getGamesModel().raceBreakShown) {
      // do not handle during race break time
      this.countVidUpdate++;

      // check on every nth update
      if (this.countVidUpdate % 30 === 0 && this.countVidUpdate > 0) {
        if (!ServerSocketLogic.instance.serverSocketClient.isWebsocketOpenState()) {
          Logger.error("Websocket not open, no sync");
          return;
        }

        const exactServerVideoSecond = this.gameTimer.getExactVideoSec();

        const exactVideoVideoSecond = Logic.getVideoTime();
        const difference = Math.abs(exactServerVideoSecond - exactVideoVideoSecond) * 1000;
        // Logger.debug("1---- GameLoop Video Seconds:" + exactServerVideoSecond);
        // Logger.debug("2---- Video Video Seconds:" + exactVideoVideoSecond);
        // Logger.debug("D---- Difference:" + difference);

        // check intro delay
        if (exactServerVideoSecond < INTRO_VIDEO_LENGTH - 5 && exactServerVideoSecond > 5 && this.setUpVideoStarted) {
          // Logger.debug("Check Intro");
          if (difference > MAX_INTRO_OFFSET) {
            // Correct Video Time
            Logger.debug("UUUUUUUUUUUUUUUUUU Udate video Seconds difference:" + difference + " exact server seconds:" + exactServerVideoSecond + " exactVideoVideoSecond:" + exactVideoVideoSecond);
            Logic.setStateTime(exactServerVideoSecond + VIDEO_START_DELAY_TIME + this.extraLoadTime / 1000);
          }
        }

        // check race video delay
        if (exactServerVideoSecond > INTRO_VIDEO_LENGTH + 5 && exactServerVideoSecond < GAME_LOOP_LENGTH - 5 && this.raceVideoStarted) {
          // Logger.debug("Check Race, countVidUpdate:" + this.countRaceVidDoneUpd);

          if (difference > MAX_RACE_OFFSET + this.raceVideoDelay + this.extraLoadTime && this.countRaceVidDoneUpd < this.maxRaceSyncUpdates) {
            // to be not complete unfluent update only several times, than leaf delayed
            // Correct Video Time
            //Logger.debug("RUUUUUUUUUUUUUUUUUU Udate video Seconds!!!!!!!!!!!!!!!!!! raceVideoDelay:" + this.raceVideoDelay + " netto difference:" + (difference - this.raceVideoDelay / 1000));
            //Logger.debug("Set Video Time:" + (exactServerVideoSecond + VIDEO_START_DELAY_TIME - this.raceVideoDelay / 1000 + this.extraLoadTime / 1000));
            Logic.setStateTime(exactServerVideoSecond + VIDEO_START_DELAY_TIME - this.raceVideoDelay / 1000 - Logic.getIntroLength() + this.extraLoadTime / 1000);
            this.countRaceVidDoneUpd++;
          }
        }
      }

      if (this.countVidUpdate >= Number.MAX_SAFE_INTEGER) {
        this.countVidUpdate = 0;
      }
    }
  }

  public checkStartNextVideoLoop(vidoeSec: number) {
    let noFadeOffset = 0;

    if (this.gamesModel.raceBreakShown) {
      noFadeOffset = START_NEXT_VIDEO_START_OFFSET + 1; // start intro after race break, no fade time
    }

    // the next round might start before the video has ended => ...
    if (vidoeSec >= GAME_LOOP_LENGTH - START_NEXT_VIDEO_START_OFFSET - 1 + noFadeOffset && !this.nextVideoStarted) {
      this.nextVideoStarted = true;
      this.raceVideoStarted = false;
      this.countRaceVidDoneUpd = 0;

      let toScecondTimeout = START_NEXT_TO_SEC_DELAY;

      if (vidoeSec >= GAME_LOOP_LENGTH - START_NEXT_VIDEO_START_OFFSET) {
        // when start up is one second before next video loop
        toScecondTimeout = 0; // emediatly start nex video loop;
      }

      Logger.debug("Race break shown:" + this.gamesModel.raceBreakShown + " noFadeOffset:" + noFadeOffset);
      Logger.info("Start nex video to second offset:" + toScecondTimeout);

      clearTimeout(this.startNextTimer);
      this.startNextTimer = setTimeout(() => {
        Logger.debug("--------------- > Start nex video Video, timestamp:" + Date.now());
        this.canPlayIntroReceived = false;

        if (this.gamesModel.raceBreakShown) {
          // first intro after a race break period
          Logger.debug("Set Pause Overlay to false");
          this.getGamesModel().hideGamePauseView();
        } else {
          // normal intro start
          Logic.fadeTo(VideoState.Intro);
        }
      }, toScecondTimeout);
    }
    if (vidoeSec >= GAME_LOOP_LENGTH) {
      Logger.debug("--------------- > Start nex video loop, timestamp:" + Date.now());

      this.nextVideoStarted = false;
      this.raceVideoStarted = false;
      this.countRaceVidDoneUpd = 0;
      this.fadeToRaceReceived = false;
      this.gameTimer.resetTimes();
    }
  }

  // get the localized text for a certain textid...
  public getText(textId: string) {
    // Logger.debug("Get Text ID<" + textId + ">");
    return Languages.instance.getText(textId);
  }

  /**
   * Sets up the rounds from actual round info (must be read from server before call)
   * sets game settings
   * synchronizes and starts game loop
   * @param initData
   * @private
   */
  private async initSettings(initData: IInitData) {
    Logger.info(" - Init settings -");

    const gamesList: ModelBase[] = [];
    const numberFutureGames = INIT_NUMB_FUTURE;

    this.initStettingsData = initData.initResult.setting;

    GamesModel.RASTER_SIZE = this.initStettingsData.betoffers[0].numberCompetitor;

    for (const item of initData.gamepool) {
      let newModel = null;

      switch (this.gameInfo.gameType) {
        case "kart5":
          newModel = new ModelKart(this.gameInfo.gameType);
          break;
        case "dog6":
          newModel = new ModelDog(this.gameInfo.gameType);
          break;
        case "dog8":
          newModel = new ModelDog(this.gameInfo.gameType);
          break;
        case "box":
          newModel = new ModelBox(this.gameInfo.gameType);
          break;
        case "dog63":
          newModel = new ModelDog63(this.gameInfo.gameType);
          break;
        case "horse":
          newModel = new ModelHorse(this.gameInfo.gameType);
          break;
        case "roulette":
          newModel = new ModelRtt(this.gameInfo.gameType);
          break;
        case "sulky":
          newModel = new ModelSulky(this.gameInfo.gameType);
          break;
      }

      if (newModel === null) {
        throw new Error("Invalied Game Type:" + this.gameInfo.gameType);
      } else {
        newModel.setServerData(item);

        newModel.convertFromServerOdds();
        gamesList.unshift(newModel);
      }
    }

    this.gamesModel.initalGamesSet(gamesList, numberFutureGames);

    if (this.gameInfo.gameType === "roulette") {
      // set statistic data at all possible current games
      // will be updated by game result
      const beforeGame = this.gamesModel.getBeforeGameData() as ModelRtt;
      if (beforeGame) {
        beforeGame.setRttStatisitc(initData.rttStatistics);
      }
      const currentGame = this.gamesModel.getCurrentGameData() as ModelRtt;
      if (currentGame) {
        currentGame.setRttStatisitc(initData.rttStatistics);
      }
      const nextGame = this.gamesModel.getNextGameData() as ModelRtt;
      if (nextGame) {
        nextGame.setRttStatisitc(initData.rttStatistics);
      }
    }

    const savedGameResult = this.gamesModel.getSaveGameResult();

    if (savedGameResult !== null) {
      Logger.info("Result was read before Init --> set in Games list");
      this.gamesModel.updateGameResult(savedGameResult.id, savedGameResult as IGameRoundResultData, false);
      this.gamesModel.resetSavedGameResult();
    }

    this.nextVideoStarted = false;

    this.intiVideoStartTime = await this.gameTimer.synchronizeGameLoop(true);
    this.intiVideoStartTime = this.intiVideoStartTime + SETUP_OFFSET_FOR_PLAY;

    this.intiVideoStartTime = Math.min(this.intiVideoStartTime, GAME_LOOP_LENGTH); // not more than video length, intro video will be started emedeatly
    this.intiVideoStartTimestamp = Date.now();

    Logger.info("Start game loop initial video Start time:" + this.intiVideoStartTime);
    Logger.debug("Timestamp vor video start time:" + this.intiVideoStartTimestamp);

    this.roundRequestSecond = Util.floatNumber(GAME_VIDEO_START_MS / 1000 + 5, 3);
    if (this.gameTimer.getVideoSec() > this.roundRequestSecond) {
      this.gameTimer.roundRequestDone = true;
    } else {
      this.gameTimer.roundRequestDone = false;
    }

    // if (this.intiVideoStartTime > this.videStartSecond) {
    //   numberFutureGames += 1;
    // }

    Logger.debug("intiVideoStartTime:" + this.intiVideoStartTime + " INTRO_VIDEO_LENGTH:" + INTRO_VIDEO_LENGTH);

    if (
      this.intiVideoStartTime >= INTRO_VIDEO_LENGTH && // at start up Start intro Action must be simulated if video loop is in race allredy started state
      this.lastStartRaceId !== this.gamesModel.getCurrentGameData().roundInfo.fullGameId && // at reinit it is possible that race hase been started before
      this.gamesModel.getIndexCurGame() < this.gamesModel.getGamesList().length - 1 // and there are enough games in list
    ) {
      // and not in race break time

      Logger.debug("getVideoSec:" + this.gameTimer.getVideoSec() + " GAME_VIDEO_START_MS / 1000:" + Util.floatNumber(GAME_VIDEO_START_MS / 1000, 3));
      let simulateSwitch = false;

      if (this.gameTimer.getVideoSec() >= Util.floatNumber(GAME_VIDEO_START_MS / 1000, 3)) {
        simulateSwitch = true;
        this.gamesModel.simulateNextGameStatusAtInit(); // because race switch was allready done, decrease current index to simulate before race start state
      }

      this.onStartIntroAction(simulateSwitch, true);

      if (this.gameTimer.getVideoSec() >= Util.floatNumber(GAME_VIDEO_START_MS / 1000, 3)) {
        this.gamesModel.simulateRaceStartedAtInit(); // set to correct state again
      }
    }
  }

  public reloadWidow() {
    // reload window
    window.location = window.location;
  }

  public getGamesModel(): GamesModel {
    return this.gamesModel;
  }

  public getGameTimer(): GameTimer {
    return this.gameTimer;
  }

  public getMaxRoundNumber(): number {
    return this.maxRoundNumber;
  }

  public getBeforeBreakNumber(): number {
    return this.beforeBreakNumber;
  }

  public setBeforeBreakNumber(beforeBreakeNumber: number): number {
    return (this.beforeBreakNumber = beforeBreakeNumber);
  }

  public getRoundRequestSecond(): number {
    return this.roundRequestSecond;
  }

  public getInitResult(): ISockServResponseMessageInit | null {
    return this.initResult;
  }

  public getBetTypeDecimalPlaces(): IBetCodeDecimals[] | null | undefined {
    if (this.initResult) {
      return this.initResult.betCodeDecimals;
    }

    return null;
  }

  public hasJackpotBounus(): boolean {
    if (this.initResult !== null) {
      return this.initResult.haveDbPot;
    }
    return false;
  }

  private calcSyncInfo(videoTime?: number) {
    const serverVideoTime = this.gameTimer.getExactVideoSec();
    videoTime = videoTime !== undefined ? videoTime : Logic.getVideoTime();
    const diff = (videoTime - serverVideoTime) * 1000;
    const diffAbs = Math.abs(diff);
    const info = {
      serverVideoTime,
      videoTime,
      diff,
      diffAbs
    };
    return info;
  }

  hasItalianSchedule(): boolean {
    return this.hasItalianShedule;
  }

  public isIntroSoundEnabled(): boolean {
    if (this.initResult) {
      if ((this.initResult.setting.skinVersion as SkinType) === SkinTypeDefinition.CLASSIC) {
        Logger.debug("INTOR SOUND ENABLED: false");
        return false;
      }
      Logger.debug("INTOR SOUND ENABLED:" + this.initResult.setting.enableSound);
      return this.initResult.setting.enableSound;
    }
    Logger.debug("INTOR SOUND ENABLED: false");
    return false;
  }
}
