import { RouletteHelper } from "./RouletteHelper";
import { settings, _t } from "../../Logic/Logic";
import { Group } from "../../Graphics/Group";
import { Logic, _s } from "../../Logic/Logic";
import { IRoundInfo } from "../../Logic/LogicDefinitions";
import * as PIXI from "pixi.js";

export class StatOddArea extends Group {
  private area: string;
  private areaText: PIXI.Text;
  private areaOddText: PIXI.Text;
  private oddBar: PIXI.Graphics;

  public constructor(area: string) {
    super();
    this.area = area;
    const rec = new PIXI.Graphics();

    rec.beginFill(0x7ebd19);
    rec.drawRect(0, 0, _s(90), _s(30));
    rec.endFill();
    this.add(rec);
    this.oddBar = new PIXI.Graphics();

    // Add text
    this.areaText = Logic.createPixiText();
    this.areaText.anchor.set(0.5, 0.5);
    this.areaOddText = Logic.createPixiText();
    this.areaOddText.anchor.set(0.5, 0.5);
    this.areaText.anchor.set(0.5, 0.5);

    const areaNameStyle = new PIXI.TextStyle({
      fontFamily: "Roboto-Light",
      fontSize: _s(16),
      fill: 0xffffff
    });
    const oddStyle = RouletteHelper.smallOddTextStyle;
    this.areaOddText.style = oddStyle;
    this.areaText.style = areaNameStyle;
    this.add(this.areaText);
    this.add(this.areaOddText);
    this.add(this.oddBar);
  }

  public onLayout(): void {
    this.areaText.position.x = _s(45);
    this.areaText.position.y = _s(15);

    this.oddBar.beginFill(0xaaaaaa);
    this.oddBar.drawRoundedRect(0, 0, _s(5), _s(11), _s(1));
    this.oddBar.position.x = _s(95);
    this.oddBar.position.y = _s(10);
    this.oddBar.endFill();

    this.areaOddText.position.y = _s(15);
    if (settings.debug) {
    }
  }

  public fill(roundInfo: IRoundInfo, oddNumber: number): void {
    const maxOdd = 200;
    const maxWidth = _s(121.5);
    const width = (maxWidth / maxOdd) * oddNumber;

    this.areaOddText.position.x = _s(115) + width;
    this.areaOddText.text = oddNumber.toString();

    this.oddBar.width = width;
    this.areaText.text = _t(this.area);
  }

  public update(dt: number): void {
    super.update(dt);
  }
}
