import * as PIXI from "pixi.js";
import { Group } from "../../Graphics/Group";
import { RtcLogic } from "../../Rtc/RtcLogic";
import { _s, Logic } from "../../Logic/Logic";
import {
  IRoundInfo,
  IDriver,
  IRoundHistory,
  IJackpotHistory,
  ITrack,
  IColors,
  IResult,
  IRaceInterval,
  VideoState,
  IGameInfo,
  IAnimInterval,
  IDog63RoundHistory,
  IDog63Suprimi,
  IDog63Quotes,
  IDog633rd,
  IDog63SuprimiEntry,
  IDog63QuoteInfo
} from "../../Logic/LogicDefinitions";
import { FadeVideo } from "../common/FadeVideo";
import { RaceBarDog } from "./../dog/RaceBarDog";
import { TopBarLeftDog } from "./../dog/TopBarLeftDog";
import { BonusInfoBarDog } from "./../dog/BonusInfoBarDog";
import { BonusHistoryDog } from "./../dog/BonusHistoryDog";
import { AnimatedBonusBarDog } from "./../dog/AnimatedBonusBarDog";
import { TrackPresentationDog } from "./../dog/TrackPresentationDog";
import { RaceIntervalsDog } from "./../dog/Race/RaceIntervalsDog";
import { GameType, GameLength } from "../../common/Definitions";
import { FadeVideoDog } from "../common/FadeVideoDog";
import { TopBarLeftInfoDog } from "./../dog/TopBarLeftInfoDog";
import { TopBarLeftLogoDog } from "./../dog/TopBarLeftLogoDog";
import { Logger } from "../../Logic/Logger";
import { SendPlan } from "./../dog/SendPlan";
import { PauseOverlay } from "../pauseOverlay/PauseOverlay";
import { Dog63Quotes } from "./Intro/Dog63Quotes";
import { Dog63RacingHistory } from "./Intro/Dog63RacingHistory";
import { Dog63Quotes3 } from "./Intro/Dog63Quotes3";
import { Dog63SuPrimi3 } from "./Intro/Dog63SuPrimi3";
import { Dog633rd } from "./Intro/Dog633rd";
import { Winners } from "./Race/Winners";
import { LayoutHelper } from "../Util/LayoutHelper";
import { WinnerDogBig } from "./Race/WinnerDogBig";
import { Dog63QuotesHelper } from "../../LogicImplementation/Dog63Quotes";
import { DogHelper } from "../dog/DogHelper";

interface IExtendedAnimInterval extends IAnimInterval {
  isRight: boolean;
}

export class VideoScreenDog63 extends Group {
  // required
  public videoSprite?: PIXI.Sprite;
  public videoSprite2?: PIXI.Sprite;
  public fadeVideo: FadeVideo;
  public pauseOverlay: PauseOverlay;
  public racerCount: number;
  public gameInfo: IGameInfo;
  // game components
  public sendPlan: SendPlan;

  public raceBar: RaceBarDog;
  //public bottomBar: BottomBarDog;
  //public driverPresentation: DriverPresentationDog;
  public quotes: Dog63Quotes;
  public racingHistory: Dog63RacingHistory;
  public quotes3: Dog63Quotes3;
  public suprimi3: Dog63SuPrimi3;
  public dog633rd: Dog633rd;
  public topBarLeft: TopBarLeftDog;
  public topBarLeftInfo: TopBarLeftInfoDog;
  public topBarLeftLogo?: TopBarLeftLogoDog;
  public bonusInfoBar: BonusInfoBarDog;
  public bonusHistory: BonusHistoryDog;
  public animatedBonusBar: AnimatedBonusBarDog;
  public trackPresentation: TrackPresentationDog | undefined = undefined;
  public raceIntervals: RaceIntervalsDog;
  public winners: Winners;
  public winnerDogBig: WinnerDogBig;
  private dogHelper: DogHelper;

  public createWinnerDogAnims(gameType: GameType, gameLength: GameLength): IExtendedAnimInterval[][] {
    return [
      [{ startTime: 32.0, duration: 7.5, isRight: false }],
      [{ startTime: 40.5, duration: Logic.getRaceLength(), isRight: false }],
      [{ startTime: 40.5, duration: Logic.getRaceLength(), isRight: true }]
    ];
  }

  // add game components here
  public constructor(gameInfo: IGameInfo) {
    super();

    const gameType = gameInfo.gameType;
    const gameLength = gameInfo.gameLength;

    this.dogHelper = new DogHelper();

    const racerCount = Logic.getRacerCount(gameType);
    this.gameInfo = gameInfo;
    this.racerCount = racerCount;

    if (RtcLogic.instance.isProducer()) {
      this.videoSprite = new PIXI.Sprite();
      this.add(this.videoSprite);
    }

    this.sendPlan = new SendPlan(gameType, gameLength);
    if (gameInfo.videoLanguage === "it") this.add(this.sendPlan);

    // this.oddsScreen = new OddsScreenDog(gameType, gameLength, gameInfo.videoLanguage);
    // this.add(this.oddsScreen);

    // this.bottomBar = new BottomBarDog(gameInfo);
    // this.add(this.bottomBar);

    // this.racingHistory = new RacingHistoryDog(gameType, gameLength);
    // this.add(this.racingHistory);

    // this.driverPresentation = new DriverPresentationDog(gameType, gameLength);
    // this.add(this.driverPresentation);

    this.bonusInfoBar = new BonusInfoBarDog(gameType, gameLength);
    // this.add(this.bonusInfoBar);

    this.bonusHistory = new BonusHistoryDog(gameType, gameLength, true); // MS TODO: WITH BONUS?
    this.add(this.bonusHistory);

    this.animatedBonusBar = new AnimatedBonusBarDog(gameType, gameLength);
    this.add(this.animatedBonusBar);

    if (gameLength > 120) {
      this.trackPresentation = new TrackPresentationDog(gameType, gameLength, this.dogHelper);
      this.add(this.trackPresentation);
    }

    this.quotes = new Dog63Quotes(gameType, gameLength);
    this.add(this.quotes);
    this.racingHistory = new Dog63RacingHistory(gameType, gameLength, gameInfo.videoLanguage);
    this.add(this.racingHistory);
    this.quotes3 = new Dog63Quotes3(gameType, gameLength);
    this.add(this.quotes3);
    this.suprimi3 = new Dog63SuPrimi3(gameType, gameLength);
    this.add(this.suprimi3);
    this.dog633rd = new Dog633rd(gameType, gameLength);
    this.add(this.dog633rd);

    this.raceIntervals = new RaceIntervalsDog(gameType);
    this.add(this.raceIntervals);

    // const winnerDogAnims = this.createWinnerDogAnims(gameType, gameInfo.gameLength);
    // const types = ["winner", "winner", "firstTwo"] as const;
    // for (let i = 0; i < 3; i++) {
    //   const winner = new WinnerDog(gameType, gameInfo.gameLength, winnerDogAnims[i], types[i], gameInfo.videoLanguage);
    //   this.winnerDogs.push(winner);
    //   this.add(winner);
    // }
    this.winnerDogBig = new WinnerDogBig(gameType, gameLength);
    this.add(this.winnerDogBig);
    this.winners = new Winners(gameType, gameLength);
    this.add(this.winners);

    this.fadeVideo = new FadeVideo(new FadeVideoDog(gameType));
    this.add(this.fadeVideo);

    this.raceBar = new RaceBarDog(gameType, gameLength, gameInfo.videoLanguage, false);
    this.add(this.raceBar);

    this.topBarLeftInfo = new TopBarLeftInfoDog(gameType, gameLength);
    this.add(this.topBarLeftInfo);

    if (gameInfo.companyLogo) {
      this.topBarLeftLogo = new TopBarLeftLogoDog(gameType, gameLength, false);
      this.topBarLeftLogo.fillLogo(gameInfo.companyLogo.image, gameInfo.companyLogo.color, gameInfo.companyLogo.color2);
      this.add(this.topBarLeftLogo);
    }

    this.topBarLeft = new TopBarLeftDog(gameInfo, false);
    this.add(this.topBarLeft);

    const fi = this.fadeVideo.fadeItems; // alles hinterm Wischer 20.4.2020
    if (fi) this.add(fi);

    this.pauseOverlay = new PauseOverlay(gameInfo);
    this.pauseOverlay.visible = false;
    this.add(this.pauseOverlay);
  }

  public async init(): Promise<void> {
    await this.pauseOverlay.init();
  }

  // position and resize when layout changes (usually on resize)
  public onLayout(): void {
    Logic.updateVideoSpriteSize();
    this.fadeVideo.position.x = _s(0);
    this.fadeVideo.position.y = _s(0);
    this.fadeVideo.width = this.width;
    this.fadeVideo.height = this.height;
    // this.fadeVideo.visible = false;

    this.pauseOverlay.position.x = _s(0);
    this.pauseOverlay.position.y = _s(0);
    this.pauseOverlay.width = this.width;
    this.pauseOverlay.height = this.height;

    this.sendPlan.position.x = _s(268);
    this.sendPlan.position.y = _s(210);
    this.sendPlan.width = _s(815);
    this.sendPlan.height = _s(320);

    // this.oddsScreen.position.x = _s(280);
    // this.oddsScreen.position.y = _s(151);
    // this.oddsScreen.width = this.width * 0.63;
    // this.oddsScreen.height = this.height * 0.415;

    LayoutHelper.setScaledRectangle(this.quotes, 0, 0, 1280, 720);
    LayoutHelper.setScaledRectangle(this.racingHistory, 108, 133, 1063, 452);
    LayoutHelper.setScaledRectangle(this.quotes3, 0, 0, 1280, 720);
    LayoutHelper.setScaledRectangle(this.suprimi3, 0, 0, 1280, 720);
    LayoutHelper.setScaledRectangle(this.dog633rd, 0, 0, 1280, 720);

    this.raceBar.width = _s(185);
    this.raceBar.height = _s(61);
    this.raceBar.position.x = this.width - this.raceBar.width - _s(8);
    this.raceBar.position.y = _s(7 / 2);

    this.topBarLeft.setDefaultPos(this.racerCount);
    if (this.topBarLeftLogo) {
      this.topBarLeftLogo.setDefaultPos(this.topBarLeft, this.gameInfo.gameType);
    }

    this.topBarLeftInfo.x = this.topBarLeft.getNextX(this.gameInfo.gameType); // might get overruled in update...
    this.topBarLeftInfo.height = this.topBarLeft.height;
    this.topBarLeftInfo.y = this.topBarLeft.y;
    this.topBarLeftInfo.width = _s(192);

    // this.driverPresentation.position.x = _s(620);
    // this.driverPresentation.position.y = _s(208);
    // this.driverPresentation.width = _s(263);
    // this.driverPresentation.height = _s(392);

    this.bonusInfoBar.width = _s(1000);
    this.bonusInfoBar.height = _s(58);
    this.bonusInfoBar.position.x = this.width * 0.5 - this.bonusInfoBar.width * 0.5;
    this.bonusInfoBar.position.y = _s(680) - this.bonusInfoBar.height * 0.5; // - _s(200);

    this.bonusHistory.width = _s(818);
    this.bonusHistory.height = _s(229);
    this.bonusHistory.position.x = _s(232);
    this.bonusHistory.position.y = _s(293);

    // this.raceTimeBar.width = _s(226);
    // this.raceTimeBar.height = _s(50);
    // this.raceTimeBar.position.x = this.width - _s(266);
    // this.raceTimeBar.position.y = _s(34);

    this.animatedBonusBar.width = _s(455);
    this.animatedBonusBar.height = _s(44);
    this.animatedBonusBar.position.x = _s(414);
    this.animatedBonusBar.position.y = _s(214); // - this.animatedBonusBar.height * 0.5;

    if (this.trackPresentation !== undefined) {
      this.trackPresentation.position.x = _s(358);
      this.trackPresentation.position.y = _s(167);
      this.trackPresentation.width = _s(563);
      this.trackPresentation.height = _s(443);
    }

    this.raceIntervals.position.x = _s(1008);
    this.raceIntervals.position.y = _s(44);
    this.raceIntervals.width = _s(253);
    this.raceIntervals.height = _s(202);

    // const winnerDogSettings: any[] = [
    //   { x: 62, y: 152, width: 648, height: 296 },
    //   { x: 62, y: 152, width: 648, height: 296 },
    //   { x: 485, y: 208.5, width: 566, height: 274 }
    // ];

    this.winnerDogBig.position.x = _s(224);
    this.winnerDogBig.position.y = _s(148);
    this.winnerDogBig.width = _s(470);
    this.winnerDogBig.height = _s(230);

    this.winners.position.x = _s(0);
    this.winners.position.y = _s(0);
    this.winners.width = this.width;
    this.winners.height = this.height;

    // for (let i = 0; i < this.winnerDogs.length; i++) {
    //   this.winnerDogs[i].position.x = _s(winnerDogSettings[i].x);
    //   this.winnerDogs[i].position.y = _s(winnerDogSettings[i].y);
    //   this.winnerDogs[i].width = _s(winnerDogSettings[i].width);
    //   this.winnerDogs[i].height = _s(winnerDogSettings[i].height);
    // }
  }

  public update(dt: number): void {
    const fadeX = this.fadeVideo.updateFade(dt);
    Logic.fadeX = fadeX;
    super.update(dt);

    if (this.topBarLeftLogo) {
      this.topBarLeftInfo.x = this.topBarLeftLogo.position.x + this.topBarLeftLogo.panel.calcContentWidth(1.0) + _s(3);
    } else {
    }
  }

  // **** public methods called from outside ****
  // general information for round should be filled
  public fillRound(roundInfo: IRoundInfo, drivers: IDriver[], odds: number[], history: IRoundHistory[], bonusHistory: IJackpotHistory[] | undefined, track: ITrack, colors: IColors): void {
    Logger.info("*** RoundInfo: " + roundInfo.gameId + "  J: " + roundInfo.jackpotValue);
    const language = this.gameInfo.videoLanguage;

    this.sendPlan.fill(roundInfo.sendPlan, roundInfo.raceNumber, roundInfo.raceStart);
    //this.oddsScreen.fill(drivers, odds, colors, roundInfo.jackpotValue !== undefined);
    this.raceBar.fill(roundInfo, language);
    //this.bottomBar.fill(drivers, colors, roundInfo.jackpotValue !== undefined, language);
    //this.racingHistory.fill(history, drivers, roundInfo.jackpotValue !== undefined);
    this.topBarLeft.fill();
    //this.driverPresentation.fill(drivers, odds, roundInfo.jackpotValue !== undefined);
    this.bonusHistory.fill(bonusHistory ? bonusHistory : [], roundInfo.jackpotValue !== undefined);
    this.topBarLeftInfo.fill(roundInfo.jackpotValue !== undefined);
    // this.raceTimeBar.fill(roundInfo);
    this.animatedBonusBar.fill(roundInfo);
    if (this.trackPresentation !== undefined) {
      this.trackPresentation.fill(track, roundInfo.jackpotValue !== undefined);
    }
  }

  public fillRoundAdditional(
    roundInfo: IRoundInfo,
    driver: IDriver[],
    history: IDog63RoundHistory[],
    suprimi: IDog63Suprimi,
    quotes: IDog63Quotes,
    quotes3rd: IDog633rd,
    odds: number[],
    oddsSide: IDog63SuprimiEntry[][],
    oddsGridFirstTwoInOrder: boolean
  ): void {
    const withBonus = roundInfo.jackpotValue !== undefined;

    this.racingHistory.fill(history, driver, withBonus);
    this.suprimi3.fill(driver, suprimi, withBonus);
    this.dog633rd.fill(driver, quotes3rd, withBonus);
    this.quotes3.fill(driver, odds, oddsSide, withBonus, oddsGridFirstTwoInOrder);
    this.quotes.fill(driver, quotes, withBonus);
  }

  // how is this called?
  // private fillDriverInfos(round: IRoundInfo, drivers: IDriver[], odds: number[], history: IHistory[], bonusHistory: IBonusHistory[] | undefined, colors: IColors, withBonus: boolean) {
  //   this.oddsScreen.fill(drivers, odds, colors);
  //   // this.racingHistory.fill(history, withBonus);
  //   // this.bonusHistory.fill(bonusHistory ? bonusHistory : [], withBonus);
  //   // this.driverPresentation.fill(drivers, odds);

  // }

  // information for race should be filled (race will sent before race starts...)
  public fillResult(track: ITrack, roundInfo: IRoundInfo, colors: IColors, result: IResult, intervals: IRaceInterval[], drivers: IDriver[], odds: number[]): void {
    // TODO: Start Position
    Logger.info("**** RoundInfo: " + roundInfo.gameId + "  J: " + roundInfo.jackpotValue + "  BT: " + result.roundBonusType);

    this.raceIntervals.fill(track, intervals, drivers);

    // this.raceTimeBar.fillRace(result);
    this.bonusInfoBar.fill(result);

    this.raceBar.fillRace(result);

    const dog63Quotes = new Dog63QuotesHelper(odds);

    const first = result.first;
    const second = result.second;
    //const thirdIndex = result.third ? result.third : 0;
    const thirdTime = result.third ? result.third.time : "0";
    const driverIndexThird = result.third ? result.third.driverIndex : 0;
    const oddsForFirstDriver: IDog63QuoteInfo = { quote: odds[first.driverIndex], betCodeId: 1 };
    const oddsForSecondDriver: IDog63QuoteInfo = dog63Quotes.getSecondOddWithBetCodeId(result.second.driverIndex);
    let oddsForThirdDriver: IDog63QuoteInfo = { quote: 0, betCodeId: 14 };
    if (result.third) {
      oddsForThirdDriver = dog63Quotes.getThirdOddWithBetCodeId(result.third.driverIndex);
    }

    this.winners.fill(
      roundInfo.jackpotValue !== undefined,
      drivers,
      [first.driverIndex, second.driverIndex, driverIndexThird],
      [first.time, second.time, thirdTime],
      [
        [oddsForFirstDriver, dog63Quotes.getInFirstTwoWithBetId(first.driverIndex), dog63Quotes.getInFirstThreeWithBetId(first.driverIndex)],
        [oddsForSecondDriver, dog63Quotes.getInFirstTwoWithBetId(second.driverIndex), dog63Quotes.getInFirstThreeWithBetId(second.driverIndex)],
        [oddsForThirdDriver, dog63Quotes.getInFirstThreeWithBetId(driverIndexThird)]
      ],
      odds,
      first.driverIndex,
      result.second.driverIndex,
      driverIndexThird
    );

    {
      this.winnerDogBig.fill(false, first.driverIndex + 1, drivers[first.driverIndex], first.time, [oddsForFirstDriver]);
    }

    // this.winnerDogs[0].fill(result, drivers, odds);
    // this.winnerDogs[1].fill(result, drivers, odds);
    // this.winnerDogs[2].fill(result, drivers, odds);
  }

  // fading between intro and race...
  public startFading(targetState: VideoState): void {
    this.fadeVideo.startFading(targetState);
  }

  public stopFading(): void {
    this.fadeVideo.stopFading();
  }

  public fillIntroTexture(tex: PIXI.Texture | undefined): void {
    this.fadeVideo.introTexture = tex;
  }

  public fillRaceTexture(tex: PIXI.Texture | undefined): void {
    this.fadeVideo.raceTexture = tex;
  }
}
