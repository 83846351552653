import { BonusHistorySettings } from "../../settings/OddsAlwaysOnSettings";
import { DogHelper } from "./DogHelper";
import * as PIXI from "pixi.js";
import { Group } from "../../Graphics/Group";
import { Logic, _s, _t, settings } from "../../Logic/Logic";
import { AnimHelper } from "./../common/Anim";
import { IJackpotHistory, IAnimInterval } from "../../Logic/LogicDefinitions";
import { GameType, GameLength } from "../../common/Definitions";

let rowOffset = 164 / 4;
const rowCount = 4;

class RowItem {
  public raceText: PIXI.Text;
  public raceNumber: PIXI.Text;
  public idText: PIXI.Text;
  public dateText: PIXI.Text;
  public timeText: PIXI.Text;
  public nameText: PIXI.Text;
  public amountText: PIXI.Text;

  public constructor(raceText: PIXI.Text, raceNumber: PIXI.Text, idText: PIXI.Text, dateText: PIXI.Text, timeText: PIXI.Text, nameText: PIXI.Text, amountText: PIXI.Text) {
    this.raceText = raceText;
    this.raceNumber = raceNumber;
    this.idText = idText;
    this.dateText = dateText;
    this.timeText = timeText;
    this.nameText = nameText;
    this.amountText = amountText;
  }
}

export class BonusHistoryDog extends Group {
  private titleText: PIXI.Text;
  private rows: RowItem[] = [];
  private anims: IAnimInterval[] = [];
  private gameType: GameType;
  private oddsAlwaysOn;

  public constructor(gameType: GameType, gameLength: GameLength, withBonus: boolean, oddsAlwaysOn = false) {
    super();

    this.gameType = gameType;
    this.oddsAlwaysOn = oddsAlwaysOn;
    this.anims = this.createAnims(gameType, gameLength, withBonus);
    this.showDebug(settings.debug);

    if (oddsAlwaysOn) rowOffset = BonusHistorySettings[gameType as keyof typeof BonusHistorySettings].rowOffset;

    {
      const style = new PIXI.TextStyle({
        fontFamily: "DIN-Regular",
        fontSize: _s(21),
        fill: "white",
        align: "center"
      });
      if (this.oddsAlwaysOn) {
        style.fontSize = _s(17);
      }
      this.titleText = Logic.createPixiText(style);
      this.titleText.anchor.set(0, 0.5);
      this.titleText.roundPixels = true;
      this.add(this.titleText);
    }

    const { idStyle, timeStyle, dateStyle, nameStyle, amountStyle, raceStyle, raceNumberStyle } = DogHelper.getBonusHistoryStyles(this.oddsAlwaysOn);

    for (let i = 0; i < rowCount; i++) {
      const raceText = Logic.createPixiText(raceStyle);
      raceText.anchor.set(0.5, 0.5);
      this.add(raceText);
      const raceNumber = Logic.createPixiText(raceNumberStyle);
      raceNumber.anchor.set(0.5, 0.5);
      this.add(raceNumber);
      const idText = Logic.createPixiText(idStyle);
      idText.anchor.set(0.0, 0.5);
      this.add(idText);
      const timeText = Logic.createPixiText(timeStyle);
      timeText.anchor.set(0.0, 0.5);
      this.add(timeText);
      const dateText = Logic.createPixiText(dateStyle);
      dateText.anchor.set(0.0, 0.5);
      this.add(dateText);
      const nameText = Logic.createPixiText(nameStyle);
      nameText.anchor.set(0.0, 0.5);
      this.add(nameText);
      const amountText = Logic.createPixiText(amountStyle);
      amountText.anchor.set(1.0, 0.5);
      this.add(amountText);
      const row = new RowItem(raceText, raceNumber, idText, dateText, timeText, nameText, amountText);
      this.rows.push(row);
    }
  }

  private createAnims(gameType: GameType, gameLength: GameLength, withBonus: boolean): IAnimInterval[] {
    if (this.oddsAlwaysOn) {
      return [{ startTime: 147.6, duration: 14.0 }];
    }
    if (!withBonus) return [];

    if (gameType === "horse") {
      switch (gameLength) {
        case 320:
          return [{ startTime: 150.5, duration: 8.8 }];
      }
    }

    if (gameType === "sulky") {
      return [{ startTime: 148.8, duration: 10.7 }];
    }

    if (gameType === "dog6") {
      switch (gameLength) {
        case 120:
          return [{ startTime: 25.5, duration: 9.3 }];
        case 180:
          return [{ startTime: 80.7, duration: 9.2 }];
        case 240:
          return [{ startTime: 135.7, duration: 14.0 }];
        case 300:
          return [{ startTime: 135.7, duration: 19.2 }];
      }
      return [{ startTime: 139.0, duration: 10.2 }];
    }

    if (gameType === "dog63") {
      switch (gameLength) {
        case 300:
          return [{ startTime: 157.2, duration: 19.5 }];
      }
      return [{ startTime: 157.7, duration: 19.2 }];
    } else {
      // dog 8
      switch (gameLength) {
        case 120:
          return [{ startTime: 25.0, duration: 10.1 }];
        case 180:
          return [{ startTime: 80.3, duration: 9.3 }];
        case 240:
          return [{ startTime: 140.4, duration: 13.8 }];
        case 300:
          return [{ startTime: 140.4, duration: 19.2 }];
      }
      return [{ startTime: 146.5, duration: 10.2 }];
    }
  }

  public fill(history: IJackpotHistory[], withBonus: boolean) {
    if (withBonus === false) this.anims = [];

    this.titleText.text = _t("bonusHistory");

    for (let i = 0; i < history.length; i++) {
      const item = history[i];

      const row = this.rows[i];
      row.raceText.text = _t("race").toUpperCase();
      Logic.autoSize(row.raceText, _s(65));
      row.raceNumber.text = Logic.implementation.formatRound(item.round);
      row.idText.text = item.id;
      Logic.autoSize(row.idText, _s(this.oddsAlwaysOn ? 90 : 125));
      row.dateText.text = item.date;
      row.timeText.text = item.time;
      row.nameText.text = item.name;
      Logic.autoSize(row.nameText, _s(this.oddsAlwaysOn ? 80 : 160));
      row.amountText.text = item.amount;
    }
  }

  public onLayout() {
    if (this.oddsAlwaysOn) return this.createSmallGrid();
    this.titleText.position.x = _s(90);
    this.titleText.position.y = _s(17);
    let rowIndex = 0;
    for (const row of this.rows) {
      row.raceText.position.x = _s(32);
      row.raceText.position.y = _s(47 + rowIndex * rowOffset);
      row.raceNumber.position.x = _s(32);
      row.raceNumber.position.y = _s(62 + rowIndex * rowOffset);
      row.idText.position.x = _s(88);
      row.idText.position.y = _s(56 + rowIndex * rowOffset);
      row.dateText.position.x = _s(247);
      row.dateText.position.y = row.idText.position.y;
      row.timeText.position.x = _s(382);
      row.timeText.position.y = row.idText.position.y;
      row.nameText.position.x = _s(500);
      row.nameText.position.y = row.idText.position.y;
      row.amountText.position.x = _s(777);
      row.amountText.position.y = row.idText.position.y;

      // matchMedia,es toString,es üpomts

      rowIndex++;
    }
  }

  private createSmallGrid() {
    const { x, y } = BonusHistorySettings[this.gameType as keyof typeof BonusHistorySettings].title;

    this.titleText.position.x = _s(x);
    this.titleText.position.y = _s(y);

    let rowIndex = 0;
    const firstCol = 0;
    const secCol = 59;
    for (const row of this.rows) {
      row.raceText.position.x = _s(33);
      row.raceText.position.y = _s(50 + rowIndex * rowOffset);
      row.raceNumber.position.x = _s((firstCol + secCol) / 2);
      row.raceNumber.position.y = _s(62 + rowIndex * rowOffset);
      row.idText.position.x = _s(75);
      row.idText.position.y = _s(57 + rowIndex * rowOffset);
      row.dateText.position.x = _s(198);
      row.dateText.position.y = row.idText.position.y;
      row.timeText.position.x = _s(205);
      row.timeText.position.y = _s(89 + rowIndex * rowOffset);
      row.nameText.anchor.set(0.5, 0.5);
      row.nameText.position.x = _s(118);
      row.nameText.position.y = _s(90 + rowIndex * rowOffset);
      row.amountText.anchor.set(0.5, 0.5);
      row.amountText.position.x = _s(325);
      row.amountText.position.y = row.idText.position.y;

      rowIndex++;
    }
  }

  public update(dt: number) {
    super.update(dt);

    const time = Logic.getVideoTime();
    const anim = Logic.getAnim(time, this.anims, this);
    if (!anim) {
      this.visible = false;
      return;
    }
    this.visible = true;

    let baseFactor = time - anim.startTime;
    if (baseFactor >= anim.duration - 2.0) {
      baseFactor = anim.duration - baseFactor;
      this.setDebugFade(AnimHelper.clamp(baseFactor));
      this.titleText.alpha = (baseFactor - 0.4) * 2;

      const ff = (row: number, col: number) => {
        return (baseFactor - 0.38 - row * 0.18 - col * 0.085) * 3;
      };

      let rowIndex = 0;
      for (const row of this.rows) {
        row.raceNumber.alpha = ff(rowIndex, 0);
        row.raceText.alpha = ff(rowIndex, 0);
        row.idText.alpha = ff(rowIndex, 1);
        row.dateText.alpha = ff(rowIndex, 2);
        row.timeText.alpha = ff(rowIndex, 3);
        row.nameText.alpha = ff(rowIndex, 4);
        row.amountText.alpha = ff(rowIndex, 5);
        rowIndex++;
      }
    } else {
      this.setDebugFade(AnimHelper.clamp(baseFactor));
      this.titleText.alpha = baseFactor - 0.2;

      const ff = (row: number, col: number) => {
        return (baseFactor - 0.38 - row * 0.18 - col * 0.085) * 2;
      };

      let rowIndex = 0;
      for (const row of this.rows) {
        row.raceNumber.alpha = ff(rowIndex, 0);
        row.raceText.alpha = ff(rowIndex, 0);
        row.idText.alpha = ff(rowIndex, 1);
        row.dateText.alpha = ff(rowIndex, 2);
        row.timeText.alpha = ff(rowIndex, 3);
        row.nameText.alpha = ff(rowIndex, 4);
        row.amountText.alpha = ff(rowIndex, 5);
        rowIndex++;
      }
    }
  }
}
