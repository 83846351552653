import * as PIXI from "pixi.js";
import { Engine } from "../../Graphics/Engine";
import { Group } from "../../Graphics/Group";
import { _s, _t, Logic } from "../../Logic/Logic";

import overlayImage from "../../assets/kickbox/pause_KIBO_hg.jpg";
//import overlayImage from "assets/kickbox/pause_KIBO_entwurf.png";
//
//import centerElement from "assets/kickbox/pauseCenter_KIBO.svg";
import centerElementImage from "../../assets/kickbox/pauseCenter_KIBO.png";

import { IGameInfo, IRoundInfo } from "../../Logic/LogicDefinitions";

import topRight from "../../assets/kickbox/topRight_KIBO.png";
import topLeft from "../../assets/kickbox/header_left_logo_KIBO.png";
import { TopBarRightFightTime } from "../kickbox/TopBarRightFightTime";
import { TopBarRightRaceNumber } from "../kickbox/TopBarRightRaceNumber";
import { TopBarLeftKickBox } from "../kickbox/TopBarLeftKickBox";
import { LayoutHelper } from "../Util/LayoutHelper";

export class PauseOverlayKickbox extends Group {
  private sprite: PIXI.Sprite;
  private centerBackground: PIXI.Sprite;
  public bottomText: PIXI.Text;
  public centerText: PIXI.Text;
  private gameInfo: IGameInfo;
  private topRightBackground: PIXI.Sprite;
  private topLeftBackground: PIXI.Sprite;
  private topRightText: PIXI.Text;
  // private topBarLeft: TopBarLeftDog;
  // private topBarLeftLogo?: TopBarLeftLogoDog;
  //private topBarRight: TopBarRightFightTime;
  //private topBarRightRaceNumber: TopBarRightRaceNumber;
  private fightTime: PIXI.Text;
  private raceNumber: PIXI.Text;
  private topBarLeft: TopBarLeftKickBox;

  public constructor(gameInfo: IGameInfo) {
    super();

    this.gameInfo = gameInfo;

    this.sprite = new PIXI.Sprite();
    this.add(this.sprite);

    this.centerBackground = new PIXI.Sprite();
    this.add(this.centerBackground);

    this.topRightBackground = new PIXI.Sprite();
    this.topRightBackground.anchor.set(1, 0);
    this.add(this.topRightBackground);

    this.topLeftBackground = new PIXI.Sprite();
    this.topLeftBackground.anchor.set(0, 0);
    this.add(this.topLeftBackground);

    const fightTimeStyle = new PIXI.TextStyle({
      fontFamily: "DIN-HeavyItalic",
      fontSize: _s(30),
      fill: "red",
      align: "left",
      letterSpacing: _s(0),
      fontStyle: "italic"
    });
    this.fightTime = Logic.createPixiText(fightTimeStyle);
    this.fightTime.text = "00:24";
    this.fightTime.anchor.set(0, 1);
    this.add(this.fightTime);

    const raceNumberStyle = new PIXI.TextStyle({
      fontFamily: "DIN-HeavyItalic",
      fontSize: _s(30),
      fill: "white",
      align: "right",
      letterSpacing: _s(0),
      fontStyle: "italic"
    });
    this.raceNumber = Logic.createPixiText(raceNumberStyle);
    this.raceNumber.anchor.set(1, 1);
    this.add(this.raceNumber);

    // this.topBarRight = new TopBarRightFightTime(gameInfo.gameType, gameInfo.gameLength, gameInfo.videoLanguage, () => false, () => undefined);
    // this.add(this.topBarRight);

    // this.topBarRightRaceNumber = new TopBarRightRaceNumber(gameInfo.gameType, gameInfo.gameLength, gameInfo.videoLanguage, () => false, () => undefined);
    // this.add(this.topBarRightRaceNumber);

    this.topBarLeft = new TopBarLeftKickBox(gameInfo.gameType, () => false);
    this.add(this.topBarLeft);

    // this.topBarLeft = new TopBarLeftDog(gameInfo, true);
    // this.add(this.topBarLeft);

    // if (gameInfo.companyLogo) {
    //   this.topBarLeftLogo = new TopBarLeftLogoDog(gameInfo.gameType, gameInfo.gameLength, true);
    //   this.topBarLeftLogo.fillLogo(gameInfo.companyLogo.image, gameInfo.companyLogo.color, gameInfo.companyLogo.color2);
    //   this.add(this.topBarLeftLogo);
    // }

    const bottomTextStyle = new PIXI.TextStyle({
      fontFamily: "DIN-MediumItalic",
      fontSize: _s(24),
      fill: "black",
      align: "center",
      letterSpacing: _s(3),
      fontStyle: "italic"
    });

    this.bottomText = Logic.createPixiText(bottomTextStyle);
    this.bottomText.anchor.set(0.5, 0.5);
    this.add(this.bottomText);

    const centerTextStyle = new PIXI.TextStyle({
      fontFamily: "DIN-BoldItalic",
      fontSize: _s(120),
      fill: "white",
      align: "center",
      letterSpacing: _s(0),
      fontStyle: "italic"
    });

    this.centerText = Logic.createPixiText(centerTextStyle);
    this.centerText.anchor.set(0.5, 0.5);
    this.add(this.centerText);

    const topRightTextStyle = new PIXI.TextStyle({
      fontFamily: "DIN-MediumItalic",
      fontSize: _s(18),
      fill: "white",
      align: "right",
      letterSpacing: _s(0.65),
      fontStyle: "italic"
    });
    this.topRightText = Logic.createPixiText(topRightTextStyle);
    this.topRightText.anchor.set(1, 1);
    //this.add(this.topRightText);
  }

  public async init() {
    const tex = await Logic.loadTexture(overlayImage);
    this.sprite.texture = tex;

    const centerTex = await Logic.loadTexture(centerElementImage);
    this.centerBackground.texture = centerTex;

    const topRightTex = await Logic.loadTexture(topRight);
    this.topRightBackground.texture = topRightTex;

    const topLeftTex = await Logic.loadTexture(topLeft);
    this.topLeftBackground.texture = topLeftTex;

    //this.topBarLeft.fill();
    this.topBarLeft.fill();

    this.topRightText.text = _t("timeNextRound");
  }

  public onLayout() {
    this.sprite.width = this.width;
    this.sprite.height = this.height;

    this.centerBackground.width = _s(400);
    this.centerBackground.height = _s(206);

    //this.centerBackground.width = Engine.instance.width * 0.32;

    this.centerBackground.position.x = this.width * 0.5;
    this.centerBackground.position.y = this.height * 0.5;
    this.centerBackground.anchor.set(0.5, 0.5);

    this.bottomText.position.x = this.width * 0.5;
    this.bottomText.position.y = this.centerBackground.position.y + this.height * 0.116;

    this.centerText.position.x = this.width * 0.5;
    this.centerText.position.y = this.centerBackground.position.y - this.height * 0.02;

    this.topRightText.position.x = this.width - _s(14);
    this.topRightText.position.y = _s(27);

    this.raceNumber.x = this.width - _s(195);
    this.raceNumber.y = _s(67);
    this.fightTime.x = this.width - _s(165);
    this.fightTime.y = _s(67);

    LayoutHelper.setScaledRectangleSprite(this.topRightBackground, 1280, 11, 200, 105);
    // Logic.loadSVG(topRight, { width: this.topRightBackground.width }).then((el) => { //width: this.centerBackground.width
    //       this.topRightBackground.texture = el;
    //       // this.centerBackground.width = el.width;
    //       // this.centerBackground.height = el.height;
    //     });

    LayoutHelper.setScaledRectangleSprite(this.topLeftBackground, 18, 8, (500 * 107) / 160, 107);
    LayoutHelper.setScaledRectangle(this.topBarLeft, 127, 50, 207, 16);
    // LayoutHelper.setScaledRectangle(this.topBarRight, 984, 24, 253, 41);
    // LayoutHelper.setScaledRectangle(this.topBarRightRaceNumber, 0, 0, 253, 41);

    // Logic.loadSVG(centerElement, { width: this.centerBackground.width }).then((el) => { //width: this.centerBackground.width
    //   this.centerBackground.texture = el;
    //   // this.centerBackground.width = el.width;
    //   // this.centerBackground.height = el.height;
    // });

    // const racerCount = Logic.getRacerCount(this.gameInfo.gameType);
    // this.topBarLeft.setDefaultPos(racerCount);
    // if (this.topBarLeftLogo) {
    //   this.topBarLeftLogo.setDefaultPos(this.topBarLeft, this.gameInfo.gameType);
    // }

    this.updateSvg(true);
  }

  public updateNextRaceTime(time: number | undefined) {
    if (time !== undefined) this.fightTime.text = Logic.implementation.formatTime(time);

    this.fightTime.visible = time !== undefined;
    this.topRightBackground.visible = this.fightTime.visible;
  }

  public setNextRound(round: IRoundInfo | undefined) {
    if (round && round.gameId) this.raceNumber.text = round.gameId.toString();
    else this.raceNumber.text = "";

    this.updateSvg(!!round);
  }

  private updateSvg(withRound: boolean) {
    // const tr = this.gameInfo.gameType === "dog6" ? topRight : topRight8;
    // const trTimeOnly = this.gameInfo.gameType === "dog6" ? topRightTimeOnly : topRightTimeOnly8;
    // const svg = withRound ? tr : trTimeOnly;
    // const svg = topRight;
    // this.topRightBackground.position.x = this.width * 0.816;
    // this.topRightBackground.position.y = this.height * 0.01;
    // this.topRightBackground.width = _s(228);
    // // this.topRight.anchor.set(0.5);
    // Logic.loadSVG(svg, { width: this.topRightBackground.width }).then((el) => {
    //   this.topRightBackground.texture = el;
    //   this.topRightBackground.height = el.height;
    // });
  }
}

// function _t(arg0: string): string {
//   throw new Error("Function not implemented.");
// }
