import { raceingHistorySettings } from "../../settings/OddsAlwaysOnSettings";
import * as PIXI from "pixi.js";
import { Group } from "../../Graphics/Group";
import { Logic, _s, _t, settings } from "../../Logic/Logic";
import { AnimHelper } from "./../common/Anim";
import { IRoundHistory, IDriver, IAnimInterval, DriverPattern } from "../../Logic/LogicDefinitions";
import { BonusAnnotationDog } from "./BonusAnnotationDog";
import { MultiStyleText } from "./../common/MultiStyleText";
import { DrawHelper } from "../common/DrawHelper";
import { GameType, GameLength } from "../../common/Definitions";
import { DogHelper } from "./DogHelper";

class RowItem {
  public raceText: PIXI.Text;
  public raceNumber: PIXI.Text;
  public firstNumber: PIXI.Text;
  public firstNumber2: PIXI.Text;
  public secondNumber: PIXI.Text;
  public firstName: MultiStyleText;
  public firstName2: MultiStyleText;
  public secondName: MultiStyleText;
  public firstLine1: PIXI.Sprite;
  public firstLine2: PIXI.Sprite;
  public secondLine: PIXI.Sprite;
  public firstTime: PIXI.Text;
  public firstTime2: PIXI.Text;
  public secondTime: PIXI.Text;
  public quotesFirst: PIXI.Text;
  public quotesSecond: PIXI.Text;
  public bonusAnnotation: BonusAnnotationDog;

  public firstLineText: PIXI.Text; // horse first line 1st
  public secondLineFirstText: PIXI.Text; // horse second line 1st
  public secondLineSecondText: PIXI.Text; // horse 2nd

  public constructor(
    raceText: PIXI.Text,
    raceNumber: PIXI.Text,
    firstNumber: PIXI.Text,
    firstNumber2: PIXI.Text,
    secondNumber: PIXI.Text,
    firstLine1: PIXI.Sprite,
    firstLine2: PIXI.Sprite,
    secondLine: PIXI.Sprite,
    firstName: MultiStyleText,
    firstName2: MultiStyleText,
    secondName: MultiStyleText,
    firstTime: PIXI.Text,
    firstTime2: PIXI.Text,
    secondTime: PIXI.Text,
    quotesFirst: PIXI.Text,
    quotesSecond: PIXI.Text,
    bonusAnnotation: BonusAnnotationDog,
    firstLineText: PIXI.Text,
    secondLineFirstText: PIXI.Text,
    secondLineSecondText: PIXI.Text
  ) {
    this.raceText = raceText;
    this.raceNumber = raceNumber;
    this.firstNumber = firstNumber;
    this.firstNumber2 = firstNumber2;
    this.secondNumber = secondNumber;
    this.firstLine1 = firstLine1;
    this.firstLine2 = firstLine2;
    this.secondLine = secondLine;
    this.firstName = firstName;
    this.firstName2 = firstName2;
    this.secondName = secondName;
    this.firstTime = firstTime;
    this.firstTime2 = firstTime2;
    this.secondTime = secondTime;
    this.quotesFirst = quotesFirst;
    this.quotesSecond = quotesSecond;
    this.bonusAnnotation = bonusAnnotation;
    this.firstLineText = firstLineText;
    this.secondLineFirstText = secondLineFirstText;
    this.secondLineSecondText = secondLineSecondText;
  }
}

export class RacingHistoryDog extends Group {
  private dogHelper: DogHelper;
  private titleText: PIXI.Text;
  private rows: RowItem[] = [];
  private anims: (IAnimInterval & { fadeInFactor?: number; fadeOutFactor?: number })[] = [];
  private gameType: GameType;
  private gameLength: GameLength;
  private oddsAlwaysOn: boolean;

  public constructor(gameType: GameType, gameLength: GameLength, dogHelper: DogHelper, oddsAlwaysOn = false) {
    super();

    this.gameType = gameType;
    this.gameLength = gameLength;
    this.oddsAlwaysOn = oddsAlwaysOn;
    this.dogHelper = dogHelper;
    this.showDebug(settings.debug, 0.6, "RaceHistoryDog");

    {
      this.titleText = Logic.createPixiText(dogHelper.getRacingHistoryTitleStyle(this.oddsAlwaysOn));
      this.titleText.roundPixels = true;
      this.add(this.titleText);
    }

    const quotesStyle = dogHelper.getRacingHistoryQuotesStyle(this.oddsAlwaysOn);
    const timeStyle = dogHelper.getRacingHistoryTimeStyle();
    const raceStyle = dogHelper.getRacingHistoryRaceStyle(this.oddsAlwaysOn);
    const raceNumberStyle = dogHelper.getRacingHistoryRaceNumberStyle(this.oddsAlwaysOn);
    const numberStyle = dogHelper.getRacingHistoryNumberStyle(this.oddsAlwaysOn);

    for (let i = 0; i < 5; i++) {
      const raceText = Logic.createPixiText(raceStyle);
      raceText.anchor.set(0.5, 0);
      if (gameType === "horse" || gameType === "sulky") raceText.anchor.set(1, 0);
      this.add(raceText);
      const raceNumber = Logic.createPixiText(raceNumberStyle);
      raceNumber.anchor.set(0.5, 0);
      if (gameType === "horse" || gameType === "sulky") raceNumber.anchor.set(1, 0);
      this.add(raceNumber);
      const firstNumber = Logic.createPixiText(numberStyle);
      firstNumber.anchor.set(0.5);
      this.add(firstNumber);
      const firstNumber2 = Logic.createPixiText(numberStyle);
      firstNumber2.anchor.set(0.5);
      this.add(firstNumber2);
      const secondNumber = Logic.createPixiText(numberStyle);
      secondNumber.anchor.set(0.5);
      this.add(secondNumber);
      const firstLine1 = new PIXI.Sprite();
      const firstLine2 = new PIXI.Sprite();
      const secondLine = new PIXI.Sprite();
      const firstName = new MultiStyleText();
      const firstName2 = new MultiStyleText();
      const secondName = new MultiStyleText();

      const firstTime = Logic.createPixiText(timeStyle);
      firstTime.anchor.set(0.5);

      const firstTime2 = Logic.createPixiText(timeStyle);
      firstTime2.anchor.set(0.5);

      const secondTime = Logic.createPixiText(timeStyle);
      secondTime.anchor.set(0.5);

      if (!this.oddsAlwaysOn) {
        this.add(firstName);
        this.add(firstName2);
        this.add(secondName);
        this.add(firstTime);
        this.add(firstTime2);
        this.add(secondTime);
      } else {
        this.add(firstLine1);
        this.add(firstLine2);
        this.add(secondLine);
      }

      const quotesFirst = Logic.createPixiText(quotesStyle);
      quotesFirst.anchor.set(0.5);
      this.add(quotesFirst);
      const quotesSecond = Logic.createPixiText(quotesStyle);
      quotesSecond.anchor.set(0.5);
      this.add(quotesSecond);
      const bonusAnnotation = new BonusAnnotationDog(this.oddsAlwaysOn);
      this.add(bonusAnnotation);

      const firstTextStyle = this.dogHelper.getFirstTextStyle();

      const firstLineText = Logic.createPixiText(firstTextStyle);
      const secondLineFirstText = Logic.createPixiText(firstTextStyle);
      const secondLineSecondText = Logic.createPixiText(firstTextStyle);
      if (gameType === "horse" || gameType === "sulky") {
        this.add(firstLineText);
        this.add(secondLineFirstText);
        this.add(secondLineSecondText);
      }

      const row = new RowItem(
        raceText,
        raceNumber,
        firstNumber,
        firstNumber2,
        secondNumber,
        firstLine1,
        firstLine2,
        secondLine,
        firstName,
        firstName2,
        secondName,
        firstTime,
        firstTime2,
        secondTime,
        quotesFirst,
        quotesSecond,
        bonusAnnotation,
        firstLineText,
        secondLineFirstText,
        secondLineSecondText
      );
      this.rows.push(row);
    }
  }

  public createAnims(gameType: GameType, gameLength: GameLength, withBonus: boolean): RacingHistoryDog["anims"] {
    if (this.oddsAlwaysOn) {
      const animArr = [
        { startTime: 21.7, duration: 18.2, fadeInFactor: 0.58, fadeOutFactor: 0.8 },
        { startTime: 84.7, duration: 18.1, fadeInFactor: 0.58, fadeOutFactor: 0.8 }
      ];
      if (!withBonus) animArr.push({ startTime: 148, duration: 12.8, fadeInFactor: 0.5, fadeOutFactor: 0.8 });
      return animArr;
    }
    if (gameType === "horse" || gameType === "sulky") {
      switch (gameLength) {
        case 384:
          return [
            { startTime: 30.5, duration: 18.0, fadeInFactor: 0.98, fadeOutFactor: 1.2 },
            withBonus ? { startTime: 123.6, duration: 22.7, fadeInFactor: 0.5, fadeOutFactor: 1.2 } : { startTime: 123.4, duration: 29.7, fadeInFactor: 0.5, fadeOutFactor: 1.2 }
          ];
        default:
          return [
            { startTime: 30.5, duration: 18.0, fadeInFactor: 0.98, fadeOutFactor: 1.2 },
            withBonus ? { startTime: 123.4, duration: 24.7, fadeInFactor: 0.5, fadeOutFactor: 1.2 } : { startTime: 123.4, duration: 29.7, fadeInFactor: 0.5, fadeOutFactor: 1.2 }
          ];
      }
    }
    if (gameType === "dog6") {
      switch (gameLength) {
        case 120:
          return [withBonus ? { startTime: 15.0, duration: 8.8, fadeInFactor: 0.7 } : { startTime: 15.5, duration: 13.2, fadeInFactor: 0.7 }];
        case 180:
          return [withBonus ? { startTime: 20.7, duration: 17.6 } : { startTime: 26.3, duration: 21.8, fadeInFactor: 1.2, fadeOutFactor: 1.5 }];
        case 240:
          return [
            { startTime: 30.7, duration: 17.7 },
            withBonus ? { startTime: 120.6, duration: withBonus ? 13.0 : 17.4, fadeInFactor: 0.9 } : { startTime: 120.8, duration: withBonus ? 13.0 : 17.4 }
          ];
        case 300:
          return [
            withBonus ? { startTime: 30.8, duration: 17.3 } : { startTime: 36.2, duration: 21.6, fadeInFactor: 1.2, fadeOutFactor: 1.2 },
            withBonus ? { startTime: 120.3, duration: 13.0 } : { startTime: 131.2, duration: 21.7, fadeInFactor: 1.3, fadeOutFactor: 1.2 }
          ];
        default: {
          return [
            { startTime: 30.3, duration: 15.3, fadeInFactor: 0.9 },
            { startTime: 96.1, duration: 14.6, fadeInFactor: 0.9 },
            { startTime: 148.9, duration: withBonus ? 10.5 : 15.0, fadeInFactor: 0.75 } as IAnimInterval
          ];
        }
      }
    }
    // dog8
    switch (gameLength) {
      case 120:
        return [withBonus ? { startTime: 15.0, duration: 8.5, fadeInFactor: 0.7 } : { startTime: 15.0, duration: 13.5, fadeInFactor: 0.7 }];
      case 180:
        return [withBonus ? { startTime: 20.5, duration: 17.8, fadeInFactor: 1.1 } : { startTime: 25.7, duration: 22.1, fadeInFactor: 1.3, fadeOutFactor: 1.2 }];
      case 240:
        return [
          { startTime: 30.5, duration: 17.5 },
          { startTime: 124.5, duration: withBonus ? 13.4 : 18.8 }
        ];
      case 300:
        return [withBonus ? { startTime: 31.0, duration: 17.4 } : { startTime: 35.8, duration: 22.2 }, withBonus ? { startTime: 124.5, duration: 13.3 } : { startTime: 134.5, duration: 23.8 }];
      default: {
        return [
          { startTime: 37.8, duration: 17.4 },
          { startTime: 134.5, duration: withBonus ? 9.5 : 15.0, fadeInFactor: 0.5 }
        ];
      }
    }
  }

  public fill(history: IRoundHistory[], drivers: IDriver[], withBonus: boolean) {
    this.titleText.text = _t("raceHistory");

    this.anims = this.createAnims(this.gameType, this.gameLength, withBonus);

    for (let i = 0; i < history.length; i++) {
      const historyItem = history[i];
      const first = historyItem.first;
      const second = historyItem.second;

      const row = this.rows[i];
      if (row === undefined) continue; // MS TODO: remove skip, is just for now
      row.raceText.text = _t("race");
      Logic.autoSize(row.raceText, _s(65));
      row.raceNumber.text = Logic.implementation.formatRound(history[i].round);

      if (this.oddsAlwaysOn) {
        Logic.autoSize(row.raceText, _s(22));
      }

      row.firstNumber.text = "" + (first.driverIndex + 1);
      row.firstNumber2.text = "" + (first.driverIndex + 1);
      row.secondNumber.text = "" + (second.driverIndex + 1);

      row.firstLineText.text = _t("numberSign");
      row.secondLineFirstText.text = _t("numberSign");
      row.secondLineSecondText.text = _t("numberSignTwo");

      const firstDriver = drivers[first.driverIndex];
      row.firstLine1.texture = DrawHelper.getCachedPattern(4, 32, 0, firstDriver.color, firstDriver.color2, firstDriver.driverPattern);
      row.secondLine.texture = DrawHelper.getCachedPattern(4, 32, 0, firstDriver.color, firstDriver.color2, firstDriver.driverPattern);
      const secondDriver = drivers[second.driverIndex];
      row.firstLine2.texture = DrawHelper.getCachedPattern(4, 32, 0, secondDriver.color, secondDriver.color2, secondDriver.driverPattern);

      // row.firstLine1.position.x = 0;
      // row.firstLine1.position.y = 0;

      const driverNameSize = _s(16);
      row.firstName.styles = this.dogHelper.getRacingHistoryDriverStyle();
      row.firstName.anchor.set(0, 0.5);
      row.firstName.text = first.firstName.toUpperCase() + " <b>" + first.lastName.toUpperCase() + "</b>";
      row.firstName2.styles = this.dogHelper.getRacingHistoryDriverStyle();
      row.firstName2.anchor.set(0, 0.5);
      row.firstName2.text = first.firstName.toUpperCase() + " <b>" + first.lastName.toUpperCase() + "</b>";
      row.secondName.styles = this.dogHelper.getRacingHistoryDriverStyle();
      row.secondName.anchor.set(0, 0.5);
      row.secondName.text = second.firstName.toUpperCase() + " <b>" + second.lastName.toUpperCase() + "</b>";

      row.firstTime.text = historyItem.first.finishTime;
      row.firstTime2.text = historyItem.first.finishTime;
      row.secondTime.text = historyItem.second.finishTime;

      row.quotesFirst.text = Logic.implementation.formatOdds(historyItem.first.quote);
      row.quotesSecond.text = Logic.implementation.formatOdds(historyItem.second.quote);

      row.bonusAnnotation.fill(historyItem.roundBonusType);
      row.bonusAnnotation.visible = historyItem.roundBonusType !== undefined;
    }
  }

  public onLayout() {
    const isHorse = this.gameType === "horse" || this.gameType === "sulky";

    this.titleText.position.x = _s(isHorse ? 79 : 89);
    this.titleText.position.y = _s(isHorse ? 4 : 4);

    let rowOffset = 83.2;
    let rowIndex = 0;
    let rowStartY = 34;

    if (this.oddsAlwaysOn) {
      const { titleText, rowStartY: rowY, rowOffset: rowOff } = raceingHistorySettings[this.gameType as keyof typeof raceingHistorySettings];

      this.titleText.position.x = _s(titleText.x);
      this.titleText.position.y = _s(titleText.y);
      rowStartY = rowY;
      rowOffset = rowOff;
    }

    const firstRowCenterY = rowOffset / 4;
    const secondRowCenterY = rowOffset / 4 + rowOffset / 2;

    const additionalOffset = isHorse ? 3 : 0;
    const additionalOffsetNumberX = isHorse ? 5 : 0;
    const additionalOffsetNumber2X = isHorse ? -3 : 0;

    for (const row of this.rows) {
      row.raceText.position.x = _s(isHorse ? 52 : 34);
      row.raceText.position.y = _s(rowStartY + 5 + rowIndex * rowOffset + additionalOffset);
      row.raceNumber.position.x = _s(isHorse ? 53 : 32);
      row.raceNumber.position.y = _s(rowStartY + 18 + rowIndex * rowOffset);
      row.firstNumber.position.x = _s(94 + additionalOffsetNumberX);
      row.firstNumber.position.y = _s(rowStartY + firstRowCenterY + rowIndex * rowOffset);
      row.firstNumber2.position.x = row.firstNumber.position.x;
      row.firstNumber2.position.y = _s(rowStartY + secondRowCenterY + rowIndex * rowOffset);
      row.secondNumber.position.x = row.firstNumber.position.x + _s(70 + additionalOffsetNumber2X);
      row.secondNumber.position.y = row.firstNumber2.position.y;
      row.firstLineText.x = row.firstNumber.position.x + _s(8);
      row.firstLineText.y = _s(rowStartY + firstRowCenterY + rowIndex * rowOffset - 13);
      row.secondLineFirstText.x = row.firstNumber.position.x + _s(8);
      row.secondLineFirstText.y = _s(rowStartY + firstRowCenterY + rowIndex * rowOffset + rowOffset / 2 - 13);
      row.secondLineSecondText.x = row.firstNumber.position.x + _s(70 + additionalOffsetNumber2X + 8);
      row.secondLineSecondText.y = _s(rowStartY + firstRowCenterY + rowIndex * rowOffset + rowOffset / 2 - 13);
      row.secondNumber.position.y = row.firstNumber2.position.y;
      row.firstLine1.x = _s(111);
      row.firstLine1.scale.x = _s(1);
      row.firstLine1.scale.y = _s(1);
      row.firstLine1.y = _s(rowStartY + 4 + rowIndex * rowOffset);
      row.firstLine1.width = _s(4);
      row.firstLine1.height = _s(32);
      row.firstLine2.x = _s(181);
      row.firstLine2.y = _s(rowStartY + 4 + rowOffset / 2 + rowIndex * rowOffset);
      row.firstLine2.width = _s(4);
      row.firstLine2.height = _s(32);

      row.secondLine.x = row.firstLine1.x;
      row.secondLine.y = _s(rowStartY + 6 + rowOffset / 2 + rowIndex * rowOffset);
      row.secondLine.width = _s(4);
      row.secondLine.height = _s(32);
      row.firstName.x = _s(224);
      row.firstName.y = _s(rowStartY + firstRowCenterY + rowIndex * rowOffset + additionalOffset);
      row.firstName2.x = row.firstName.x;
      row.firstName2.y = _s(rowStartY + secondRowCenterY + rowIndex * rowOffset + additionalOffset);
      row.secondName.x = row.firstName.x + _s(140);
      row.secondName.y = row.firstName2.y;
      row.firstTime.x = _s(542);
      row.firstTime.y = _s(rowStartY + firstRowCenterY + rowIndex * rowOffset + additionalOffset);
      row.firstTime2.x = row.firstTime.x;
      row.firstTime2.y = _s(rowStartY + secondRowCenterY + rowIndex * rowOffset + additionalOffset);
      row.secondTime.x = row.firstTime.x + _s(105);
      row.secondTime.y = row.firstTime2.y;
      row.quotesFirst.x = _s(761);
      row.quotesFirst.y = _s(rowStartY + firstRowCenterY + rowIndex * rowOffset + additionalOffset);
      row.quotesSecond.x = row.quotesFirst.x;
      row.quotesSecond.y = _s(rowStartY + secondRowCenterY + rowIndex * rowOffset + additionalOffset);
      row.bonusAnnotation.x = _s(0);
      row.bonusAnnotation.y = _s(31 + rowIndex * rowOffset);
      row.bonusAnnotation.width = _s(100);
      row.bonusAnnotation.height = _s(40);

      if (this.oddsAlwaysOn) {
        const { row: singleRow } = raceingHistorySettings[this.gameType as keyof typeof raceingHistorySettings];

        row.raceNumber.position.y = _s(rowStartY + singleRow.raceNumber.yOffset + rowIndex * rowOffset);
        row.raceNumber.position.x = _s(singleRow.raceNumber.x);

        row.bonusAnnotation.y = _s(singleRow.bonusAnnotation.y + rowIndex * rowOffset);
        row.bonusAnnotation.x = _s(singleRow.bonusAnnotation.x);

        row.raceText.position.x = _s(singleRow.raceText.x);

        row.firstNumber.position.x = _s(singleRow.firstNumbers.x + additionalOffsetNumberX);
        row.firstNumber2.position.x = _s(singleRow.firstNumbers.x + additionalOffsetNumberX);

        row.secondNumber.position.x = row.firstNumber.position.x + _s(singleRow.secondNumber.x + additionalOffsetNumber2X);
        row.secondNumber.position.y = row.firstNumber2.position.y;

        row.quotesFirst.x = _s(singleRow.quotes.x);
        row.quotesSecond.x = _s(singleRow.quotes.x);

        row.firstLine1.x = _s(singleRow.firstLine1.x);
        row.firstLine1.y = _s(rowStartY + singleRow.firstLine1.yOffset + rowIndex * rowOffset);
        row.firstLine2.x = _s(singleRow.firstLine2.x);
        row.firstLine2.y = _s(rowStartY + singleRow.firstLine2.yOffset + rowOffset / 2 + rowIndex * rowOffset);
        row.secondLine.x = row.firstLine1.x;
        row.secondLine.y = _s(rowStartY + singleRow.secondLine.yOffset + rowOffset / 2 + rowIndex * rowOffset);

        row.firstLine1.width = _s(singleRow.allLines.width);
        row.firstLine1.height = _s(singleRow.allLines.height);
        row.firstLine2.width = _s(singleRow.allLines.width);
        row.firstLine2.height = _s(singleRow.allLines.height);
        row.secondLine.width = _s(singleRow.allLines.width);
        row.secondLine.height = _s(singleRow.allLines.height);
      }

      rowIndex++;
    }
  }

  public update(dt: number) {
    super.update(dt);

    const time = Logic.getVideoTime();
    const anim = Logic.getAnim(time, this.anims, this);
    if (!anim) {
      this.visible = false;
      return;
    }
    this.visible = true;

    let baseFactor = time - anim.startTime;

    if (baseFactor >= anim.duration - 1.0) {
      baseFactor = 1.0 - (baseFactor - anim.duration);
      this.setDebugFade(AnimHelper.clamp(baseFactor));
      this.titleText.alpha = (baseFactor - 0.2) * 2;

      const sf = anim.fadeOutFactor ? anim.fadeOutFactor : 1.0;

      const ff = function (row: number, subRow: number, col: number) {
        return (baseFactor - (row * 2 + subRow) * 0.08 * sf - col * 0.05 * sf) * 3;
      }

      let rowIndex = 0;
      for (const row of this.rows) {
        row.raceText.alpha = ff(rowIndex, 0, 0);
        row.raceNumber.alpha = ff(rowIndex, 0, 0);
        row.bonusAnnotation.alpha = ff(rowIndex, 1, 0);

        row.firstNumber.alpha = ff(rowIndex, 0, 1);
        row.firstLine1.alpha = ff(rowIndex, 0, 1);
        row.firstLineText.alpha = ff(rowIndex, 0, 1);

        row.firstNumber2.alpha = ff(rowIndex, 0, 1);
        row.firstLine2.alpha = ff(rowIndex, 1, 1);
        row.secondLine.alpha = ff(rowIndex, 1, 2);
        row.secondLineFirstText.alpha = ff(rowIndex, 0, 1);
        row.secondNumber.alpha = ff(rowIndex, 1, 2);
        row.secondLineSecondText.alpha = ff(rowIndex, 1, 2);

        row.firstName.alpha = ff(rowIndex, 0, 3);
        row.firstName2.alpha = ff(rowIndex, 1, 3);
        row.secondName.alpha = ff(rowIndex, 1, 4);

        row.firstTime.alpha = ff(rowIndex, 0, 5);
        row.firstTime2.alpha = ff(rowIndex, 1, 5);
        row.secondTime.alpha = ff(rowIndex, 1, 6);
        row.quotesFirst.alpha = ff(rowIndex, 0, 7);
        row.quotesSecond.alpha = ff(rowIndex, 1, 7);
        rowIndex++;
      }
    } else {
      // fadeIn
      this.setDebugFade(AnimHelper.clamp(baseFactor));
      this.titleText.alpha = baseFactor - 0.3;

      const sf = anim.fadeInFactor ? anim.fadeInFactor : 1.0;

      const ff = function (row: number, subRow: number, col: number) {
        return (baseFactor - 0.54 - (row * 2 + subRow) * 0.14 * sf - col * 0.06 * sf) * 3;
      }

      let rowIndex = 0;
      for (const row of this.rows) {
        row.raceText.alpha = ff(rowIndex, 0, 0);
        row.raceNumber.alpha = ff(rowIndex, 0, 0);
        row.bonusAnnotation.alpha = ff(rowIndex, 1, 0);

        row.firstNumber.alpha = ff(rowIndex, 0, 1);
        row.firstLine1.alpha = ff(rowIndex, 0, 1);
        row.firstLineText.alpha = ff(rowIndex, 0, 1);

        row.firstNumber2.alpha = ff(rowIndex, 0, 1);
        row.firstLine2.alpha = ff(rowIndex, 1, 1);
        row.secondLine.alpha = ff(rowIndex, 1, 2);
        row.secondLineFirstText.alpha = ff(rowIndex, 0, 1);
        row.secondNumber.alpha = ff(rowIndex, 1, 2);
        row.secondLineSecondText.alpha = ff(rowIndex, 1, 2);

        row.firstName.alpha = ff(rowIndex, 0, 3);
        row.firstName2.alpha = ff(rowIndex, 1, 3);
        row.secondName.alpha = ff(rowIndex, 1, 4);

        row.firstTime.alpha = ff(rowIndex, 0, 5);
        row.firstTime2.alpha = ff(rowIndex, 1, 5);
        row.secondTime.alpha = ff(rowIndex, 1, 6);
        row.quotesFirst.alpha = ff(rowIndex, 0, 7);
        row.quotesSecond.alpha = ff(rowIndex, 1, 7);
        rowIndex++;
      }
    }
  }
}
