import * as mediasoupClient from "mediasoup-client";
//import { IRtcEnvelope, IRtcLoginMessage, MediaSoupClientMeta } from "../common/RtcDefinitions";
import { RtcSocket } from "../common/RtcSocket";
import { IProducerHelper, ProducerHelper, IConsumerHelper } from "./MediaSoupClientDefinitions";
import { Util } from "../common/Util";
import { RtpCodecCapability } from "mediasoup-client/lib/types";

export class MediaSoupClient {
  private id: string;
  private roomId: string;
  private type: "producer" | "consumer";
  private socket!: RtcSocket<WebSocket>;
  private device!: mediasoupClient.Device;
  private producerHelper: IProducerHelper = { state: "initialized", type: "producer" };
  private consumerHelper: IConsumerHelper = { state: "initialized", type: "consumer" };
  private meta?;

  private consumeCallback?: (ms: MediaStream[]) => void;

  public constructor(id: string, roomId: string, type: "producer" | "consumer", meta?: any) {
    this.id = id;
    this.roomId = roomId;
    this.type = type;
    this.meta = meta;
    console.log("MediaSoupClient.version: " + mediasoupClient.version);

    const handlerName = mediasoupClient.detectDevice();
    if (handlerName) {
      console.log("detected handler: %s", handlerName);
    } else {
      console.warn("no suitable handler found for current browser/device");
    }

    try {
      this.device = new mediasoupClient.Device();
    } catch (error) {
      console.warn("Couldn't create mediaSoupClient.Device: ", error);
    }
  }

  public async open(url: string): Promise<void> {
    console.log("Open Websocket: " + url);
    const socket = new RtcSocket(new WebSocket(url), this.id, this.roomId);
    this.socket = socket;

    // const id = setInterval(() => {
    //   console.log("Interval: ", socket.ws.readyState, socket.ws.readyState === WebSocket.OPEN);
    //   if (socket.ws.readyState === WebSocket.OPEN) {
    //     socket.sendMessage({ type: "ping", id: this.id, roomId: this.roomId });
    //   }
    // }, 10000);

    return new Promise<void>((resolve, reject) => {
      socket.ws.onopen = async (ev) => {
        const loginMessage = { type: "Login", id: this.id, roomId: this.roomId, producer: this.type === "producer", meta: this.meta };
        const ret = await socket.sendMessageWait(loginMessage);
        await this.initMediaSoup(ret.rtpCapabilities);
        resolve(undefined);
      };

      socket.ws.onclose = (ev) => {
        console.log("RtcClient ws.onclose: " + ev?.code + " " + ev?.reason + " (" + this.id + ", " + this.roomId + ", " + this.type + ")", ev);
        socket.clearPendingResponses();
        // clearInterval(id);
      };

      socket.ws.onerror = (ev) => {
        console.log("RtcClient ws.onerror", ev);
        // clearInterval(id);
        reject();
      };

      socket.ws.onmessage = (ev) => {
        const envelope = JSON.parse(ev.data);
        console.log("OnReceivedMessage: " + envelope.message?.type + " " + envelope.messageId + " " + envelope.responseId);
        if (envelope.responseId) {
          socket.onResponse(envelope);
        } else {
          const msg = envelope.message;
          console.log("Received message: " + msg.type, msg);
          switch (msg.type) {
            case "producerRemoved": {
              console.log("producerRemoved");
              // socket.sendMessage({ type: "resume", ids: [ vc.id, ac.id ] });
              break;
            }
            case "producerAdded": {
              console.log("producerAdded");
              this.close();
              this.subscribe();
              // socket.sendMessage({ type: "resume", ids: [ vc.id, ac.id ] });
              break;
            }
          }
        }
      };
    });
  }

  public close(): void {
    console.log("MediaSoupClient close");
    ProducerHelper.close(this.producerHelper);
    ProducerHelper.close(this.consumerHelper);
  }

  private async initMediaSoup(routerRtpCapabilities: mediasoupClient.types.RtpCapabilities) {
    // const data = await socket.request('getRouterRtpCapabilities');
    await this.device.load({ routerRtpCapabilities });
  }

  public async subscribe(): Promise<void> {
    const data = await this.socket.sendMessageWait({ type: "createConsumerTransport", forceTcp: false });

    const transport = this.device.createRecvTransport(data.options);
    transport.on("connect", async ({ dtlsParameters }, callback, errback) => {
      const __ret = await this.socket.sendMessageWait({ type: "connectConsumerTransport", transportId: transport.id, dtlsParameters });
      callback();
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    transport.on("close", (originator: "local" | "remote") => {
      console.log("onTransport close: " + originator);
    });

    // eslint-disable-next-line prefer-const
    let videoConsumerPromise: Promise<mediasoupClient.types.Consumer>;
    // eslint-disable-next-line prefer-const
    let audioConsumerPromise: Promise<mediasoupClient.types.Consumer>;
    const stream = new MediaStream();
    transport.on("connectionstatechange", async (state) => {
      console.log("connectionstatechange: " + state);
      switch (state) {
        case "connecting":
          break;

        case "connected": {
          const [vc, ac] = await Promise.all([videoConsumerPromise, audioConsumerPromise]);
          ProducerHelper.setStream(this.consumerHelper, vc, "video");
          ProducerHelper.setStream(this.consumerHelper, ac, "audio");
          if (this.consumeCallback) {
            stream.addTrack(vc.track);
            stream.addTrack(ac.track);
            this.consumeCallback([stream]);
          }
          this.socket.sendMessage({ type: "resume", ids: [vc.id, ac.id] });
          // await socket.request('resume');
          break;
        }

        case "failed":
          console.log("Connection failed!");
          transport.close();
          break;
        default:
          break;
      }
    });

    ProducerHelper.close(this.consumerHelper);
    this.consumerHelper = { state: "initialized", type: "consumer", transport };

    videoConsumerPromise = this.consume(this.roomId, "video", transport);
    audioConsumerPromise = this.consume(this.roomId, "audio", transport);
  }

  public async consume(roomId: string, kind: "video" | "audio", transport: mediasoupClient.types.Transport): Promise<mediasoupClient.types.Consumer> {
    const { rtpCapabilities } = this.device;
    if (rtpCapabilities === undefined) throw new Error("RtpCapabilities undefined!");
    const data = await this.socket.sendMessageWait({ type: "consume", rtpCapabilities, roomId, kind });
    const consumer = await transport.consume(data.consumerOptions);
    return consumer;
  }

  public async publishStream(stream: MediaStream): Promise<void> {
    if (!this.socket) throw new Error("Socket is invalid!");

    const videoTrack = stream.getVideoTracks()[0];
    const audioTrack = stream.getAudioTracks()[0];

    const canvas = {
      width: videoTrack.getSettings().width,
      height: videoTrack.getSettings().height
      // fps: videoTrack.getSettings().frameRate // always 60...
    };

    const data = await this.socket.sendMessageWait({ type: "createProducerTransport", forceTcp: false, rtpCapabilities: this.device.rtpCapabilities });

    const transport = this.device.createSendTransport(data.options);
    ProducerHelper.close(this.producerHelper);
    this.producerHelper = { state: "initialized", type: "producer", transport };

    transport.on("connect", async ({ dtlsParameters }, callback, errback) => {
      await this.socket.sendMessageWait({ type: "connectProducerTransport", dtlsParameters });
      callback();
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    transport.on("close", (originator: "local" | "remote") => {
      console.log("onTransport close: " + originator);
    });

    transport.on("produce", async ({ kind, rtpParameters }, callback, errback) => {
      console.log("Produce " + this.roomId + " " + kind);
      try {
        const ret = await this.socket.sendMessageWait({ type: "produce", transportId: transport.id, kind, rtpParameters, appData: { canvas } });
        callback({ id: ret.id });
      } catch (err) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        errback(err);
      }
    });

    transport.on("connectionstatechange", (state) => {
      console.log("connectionstatechange Producer: " + state);
      switch (state) {
        case "connecting":
          break;

        case "connected":
          // document.querySelector('#local_video').srcObject = stream;
          break;

        case "failed":
          transport.close();
          break;

        default:
          break;
      }
    });

    const codecIndex = Util.getUrlParameterInt(window.location.href, "codecIndex", -1);
    let videoCodec: RtpCodecCapability | undefined;
    const codecs = this.device.rtpCapabilities.codecs?.filter((f) => f.mimeType !== "video/rtx" && f.kind === "video");
    if (codecs && codecIndex >= 0 && codecIndex < codecs.length) {
      if (codecs[codecIndex].kind === "video") {
        videoCodec = codecs[codecIndex];
        console.log("Selected Videocodec: " + codecIndex, videoCodec);
      }
    }

    const maxBitrate: number | undefined = Util.getUrlParameterIntOrUndefined(window.location.href, "maxBitrate");
    if (maxBitrate) {
      console.log("Selected maxBitrate: " + maxBitrate);
    }

    const [video, audio] = await Promise.all([transport.produce({ track: videoTrack, encodings: [{ maxBitrate }], codec: videoCodec }), transport.produce({ track: audioTrack })]);
    // const [video, audio] = await Promise.all([transport.produce({ track: videoTrack, encodings: [ {maxBitrate: 4000000 }], codec: this.device.rtpCapabilities.codecs![1]}), transport.produce({ track: audioTrack })]);
    // const [video, audio] = await Promise.all([transport.produce({ track: videoTrack, encodings: [ {maxBitrate: 8000000 }], codec: this.device.rtpCapabilities.codecs![3]}), transport.produce({ track: audioTrack })]);
    ProducerHelper.setStream(this.producerHelper, video, "video");
    ProducerHelper.setStream(this.producerHelper, audio, "audio");
  }

  public async consumeStreams(callback: (ms: MediaStream[]) => void): Promise<void> {
    console.log("ConsumeStreams!");
    this.consumeCallback = callback;
    await this.subscribe();
  }

  public getType(): "producer" | "consumer" {
    return this.type;
  }
}
