import * as PIXI from "pixi.js";
import { Group } from "../../../Graphics/Group";
import { Logic, _s, _t, settings } from "../../../Logic/Logic";
import { IDriver, IAnimInterval, IDog63RoundHistory } from "../../../Logic/LogicDefinitions";
//import { BonusAnnotationDog } from "./BonusAnnotationDog";
import { GameType, GameLength } from "../../../common/Definitions";
import { Dog63RacingHistoryRow } from "./Dog63RacingHistoryRow";
import { LayoutHelper } from "../../../VideoScreen/Util/LayoutHelper";
import { Dog63Helper } from "../Dog63Helper";
import { DiagonalFadeHelper } from "../../../VideoScreen/common/DiagonalFadeHelper";

export class Dog63RacingHistory extends Group {
  private titleText: PIXI.Text;
  // private rows: RowItem[] = [];
  private anims: (IAnimInterval & { fadeInFactor?: number; fadeOutFactor?: number })[] = [];
  private gameType: GameType;
  private gameLength: GameLength;
  private language: string;

  private rows: Dog63RacingHistoryRow[] = [];

  public constructor(gameType: GameType, gameLength: GameLength, language: string) {
    super();

    this.gameType = gameType;
    this.gameLength = gameLength;
    this.language = language;

    this.showDebug(settings.debug, undefined, "RacingHistory");

    {
      const style = new PIXI.TextStyle({
        fontFamily: "DIN-Medium",
        fontSize: _s(22),
        fill: Dog63Helper.getWhiteColor(),
        align: "center",
        letterSpacing: _s(0.25)
      });
      this.titleText = Logic.createPixiText(style);
      this.titleText.roundPixels = true;
      this.add(this.titleText);
    }

    for (let i = 0; i < 5; i++) {
      const row = new Dog63RacingHistoryRow(gameType, gameLength);
      this.rows.push(row);
      this.add(row);
    }
  }

  public createAnims(gameType: GameType, gameLength: GameLength, withBonus: boolean): IAnimInterval[] {
    // if (gameType === "dog63"){
    //   switch (gameLength){
    //default:

    {
      return [
        this.language === "it" ? { startTime: 15.9, duration: 17.8 } : { startTime: 0.3, duration: 33.4 },
        { startTime: 137.5, duration: 17.8 }
        // { startTime: 148.9, duration: withBonus ? 10.5 : 15.0 } as IAnimInterval
      ];
    }

    //  }
    //}
  }

  public fill(history: IDog63RoundHistory[], drivers: IDriver[], withBonus: boolean): void {
    this.anims = this.createAnims(this.gameType, this.gameLength, withBonus);

    this.titleText.text = _t("raceHistory");
    this.titleText.x = _s(69);
    this.titleText.y = _s(7.5);

    for (let i = 0; i < Math.min(history.length, this.rows.length); i++) {
      this.rows[i].fill(history[i], drivers, withBonus);
    }
  }

  public onLayout(): void {
    for (let i = 0; i < this.rows.length; i++) {
      LayoutHelper.setScaledRectangle(this.rows[i], 0, 39.5 + 82.82 * i, 1063, 82);
    }
  }

  public update(dt: number): void {
    super.update(dt);

    const time = Logic.getVideoTime();
    const anim = Logic.getAnim(time, this.anims, this);
    if (!anim) {
      this.visible = false;
      return;
    }
    this.visible = true;

    const baseFactor = time - anim.startTime;
    //this.visible = baseFactor >= 0 && baseFactor < anim.duration;

    this.showDebugTime("RacingHistory", baseFactor);

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < this.rows.length; i++) {
      this.rows[i].updateAnims(baseFactor);
    }

    DiagonalFadeHelper.FadeDiagonal(this, baseFactor, anim.duration, 2, 0.5, 2, Logic.videoScreen.width, Logic.videoScreen.height);
  }
}
