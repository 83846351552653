import { TrackPresentationAnimTimings, TrackPresentationPositions } from "../../settings/TrackPresentationSettings";
import { TurnItemDog } from "./TurnItemDog";
import { Group } from "../../Graphics/Group";
import { Logic, _s, settings } from "../../Logic/Logic";
import { ITrack, IAnimInterval } from "../../Logic/LogicDefinitions";
import { AnimHelper } from "./../common/Anim";
import * as PIXI from "pixi.js";
import { TrackPresentationLapInfo } from "./TrackPresentationLapInfo";
import { TrackPresentationTimesCirlce } from "./TrackPresentationTimesCircle";
import { TrackPresentationLapSegment } from "./TrackPresentationLapSegment";
import { TrackPresentationLapMapFact } from "./TrackPresentationLapMapFact";
import { GameType, GameLength } from "../../common/Definitions";
import { DogHelper } from "./DogHelper";
import { Util } from "../../common/Util";

type TrackPresentationAnim = IAnimInterval & {
  times: IAnimInterval[];
};

class TrackPresentationAnims {
  public anims: TrackPresentationAnim[] = [];
  public startTime: number = 0;
  public circleStartTime: number = 0;
  public circleLetterOffset: number = 0;
  public lapInfoAnims: IAnimInterval[] = [];
  public turnItemStarts: IAnimInterval[] = [];
  public segmentAnims: IAnimInterval[] = [];
  public lapMapFactAnims: IAnimInterval[] = [];
}

/**
 * this function transforms the relative animation timings set in the settings into absolute timings
 * @param anim the animation to transform
 * @param scale the scale of the tranformation
 * @param offset the offset (startTime of section)
 */
function transformAnim(anim: IAnimInterval, scale: number, offset: number) {
  anim.startTime = anim.startTime * scale + offset;
  anim.duration = anim.duration * scale;
}

export class TrackPresentationDog extends Group {
  private turns: TurnItemDog[] = [];
  private trackName: PIXI.Text;
  private gameTypeCheck: "horse" | "sulky" | "default";
  //private anims: IAnimInterval[];

  private lapInfo: TrackPresentationLapInfo;
  private timesCircle: TrackPresentationTimesCirlce;
  private segments: TrackPresentationLapSegment[] = [];
  private lapMapFacts: TrackPresentationLapMapFact[] = [];
  private trackAnims: TrackPresentationAnims | undefined = undefined;
  private anims: TrackPresentationAnim[] = [];
  private gameType: GameType;
  private gameLength: GameLength;
  private helper: DogHelper;
  private oddsAlwaysOn: boolean;

  public constructor(gameType: GameType, gameLength: GameLength, helper: DogHelper, oddsAlwaysOn = false) {
    super();
    this.helper = helper;
    this.oddsAlwaysOn = oddsAlwaysOn;
    this.gameType = gameType;

    this.gameLength = gameLength;
    this.showDebug(settings.debug, undefined, "TrackPresentation");

    this.trackName = Logic.createPixiText(this.helper.getTrackNameStyle(oddsAlwaysOn));
    this.trackName.anchor.set(0.5, 0.5);
    this.add(this.trackName);

    if (this.gameType !== "horse" && this.gameType !== "sulky") this.gameTypeCheck = "default";
    else this.gameTypeCheck = this.gameType;

    this.lapInfo = new TrackPresentationLapInfo(oddsAlwaysOn, this.gameType);
    this.add(this.lapInfo);

    this.timesCircle = new TrackPresentationTimesCirlce(this.gameType, this.oddsAlwaysOn);
    this.add(this.timesCircle);

    // this.topInfo = new TrackPresentationTopInfo();
    // this.add(this.topInfo);
  }

  private calcTimeOffset(gameType: GameType, gameLength: GameLength, withBonus: boolean, second = false) {
    if (this.oddsAlwaysOn) {
      if (second) return 107;
      else return 44;
    }
    if (gameType === "horse" || gameType === "sulky") {
      return 51.0;
    }
    if (gameType === "dog63") {
      return 118.1;
    } else if (gameType === "dog6") {
      switch (gameLength) {
        case 180:
          return withBonus ? 41.5 : 51.5;
        case 240:
          return withBonus ? 51.5 : 51.5;
        case 300:
          return withBonus ? 51.5 : 61.5;
      }
    } else {
      // dog8
      switch (gameLength) {
        case 180:
          return withBonus ? 41.5 : 51.5;
        case 240:
          return withBonus ? 51.5 : 51.5;
        case 300:
          return withBonus ? 51.5 : 61.5;
      }
    }
    return 0;
  }

  public createAnims(gameType: GameType, gameLength: GameLength, withBonus: boolean, second = false): TrackPresentationAnims {
    const result = new TrackPresentationAnims();
    const timeOffset = this.calcTimeOffset(gameType, gameLength, withBonus, second);
    const sf = 1.0;

    const isHorse = gameType === "horse" || gameType === "sulky";

    let totalAnimDuration = 17 * sf;
    if (gameType === "dog63") totalAnimDuration = 18 * sf;

    // bottom bar text
    result.anims = [
      {
        startTime: 0,
        duration: isHorse ? 18 : totalAnimDuration,
        // TODO: check how to do with another gametype dog63 (add to settings aswell?)
        // duration: TrackPresentationAnimTimings[this.gameTypeCheck as keyof typeof TrackPresentationAnimTimings].bottomResultBar.duration,
        times: TrackPresentationAnimTimings[this.gameType as keyof typeof TrackPresentationAnimTimings].trackName
      }
    ];
    result.startTime = 9.65;
    if (this.oddsAlwaysOn) result.anims[0].duration = 18;
    if (second) result.startTime = 10.5;
    result.lapInfoAnims = Util.copyArrayOfObjects<IAnimInterval>(TrackPresentationAnimTimings[this.gameType as keyof typeof TrackPresentationAnimTimings].lapInfo);
    result.turnItemStarts = Util.copyArrayOfObjects<IAnimInterval>(TrackPresentationAnimTimings[this.gameType as keyof typeof TrackPresentationAnimTimings].turns);
    result.segmentAnims = Util.copyArrayOfObjects<IAnimInterval>(TrackPresentationAnimTimings[this.gameType as keyof typeof TrackPresentationAnimTimings].segments);
    result.lapMapFactAnims = Util.copyArrayOfObjects<IAnimInterval>(TrackPresentationAnimTimings[this.gameType as keyof typeof TrackPresentationAnimTimings].lapMapFacts);

    result.lapInfoAnims.forEach((m) => transformAnim(m, sf, timeOffset));
    result.turnItemStarts.forEach((m) => transformAnim(m, sf, timeOffset));
    result.segmentAnims.forEach((m) => transformAnim(m, sf, timeOffset));
    result.lapMapFactAnims.forEach((m) => transformAnim(m, sf, timeOffset));

    result.startTime = result.startTime * sf + timeOffset;

    result.anims.forEach((m) => {
      transformAnim(m, sf, timeOffset);
      m.times.forEach((t) => transformAnim(t, sf, 0));
    });

    return result;
  }

  public fill(track: ITrack, withBonus: boolean): void {
    const isHorse = this.gameType === "horse" || this.gameType === "sulky";
    let secondAnims: TrackPresentationAnims | undefined;
    this.trackAnims = this.createAnims(this.gameType, this.gameLength, withBonus);

    if (this.oddsAlwaysOn) {
      secondAnims = this.createAnims(this.gameType, this.gameLength, withBonus, true);
      this.anims = [...this.trackAnims.anims, ...secondAnims.anims];
      this.trackAnims.lapInfoAnims = [...this.trackAnims.lapInfoAnims, ...secondAnims.lapInfoAnims];
      this.trackAnims.lapMapFactAnims = [...this.trackAnims.lapMapFactAnims, ...secondAnims.lapMapFactAnims];
      this.trackAnims.segmentAnims = [...this.trackAnims.segmentAnims, ...secondAnims.segmentAnims];
      this.trackAnims.turnItemStarts = [...this.trackAnims.turnItemStarts, ...secondAnims.turnItemStarts];
    } else {
      this.anims = this.trackAnims.anims;
    }
    let startTime = this.trackAnims.startTime + (isHorse ? 0.1 : 0.9);

    if (this.oddsAlwaysOn && secondAnims !== undefined) {
      startTime += 0.5;
      this.timesCircle.fill([
        { startTime, duration: 10 },
        { startTime: secondAnims.startTime + 0.5, duration: 10 }
      ]);
    } else {
      this.timesCircle.fill([{ startTime, duration: 10 }]);
    }

    // spacing between trackname and country
    const spacer = this.gameType === "horse" || this.gameType === "sulky" ? " - " : "-";

    this.trackName.text = (track.name + spacer + track.country).toUpperCase();

    Logic.autoSize(this.trackName, this.oddsAlwaysOn ? _s(265) : _s(400));

    // MS hardcoded for now - if we need to change that dynamically the animtimes need to move into the provided data as well?
    const tempTrack = track;
    const lapInfoAnims: any[] = [];
    lapInfoAnims.push({ startTime: this.trackAnims.lapInfoAnims[0].startTime, duration: this.trackAnims.lapInfoAnims[0].duration, key: tempTrack.facts[0].key, value: tempTrack.facts[0].value });
    lapInfoAnims.push({ startTime: this.trackAnims.lapInfoAnims[1].startTime, duration: this.trackAnims.lapInfoAnims[1].duration, key: tempTrack.facts[1].key, value: tempTrack.facts[1].value });
    lapInfoAnims.push({ startTime: this.trackAnims.lapInfoAnims[2].startTime, duration: this.trackAnims.lapInfoAnims[2].duration, key: tempTrack.facts[2].key, value: tempTrack.facts[2].value });
    lapInfoAnims.push({ startTime: this.trackAnims.lapInfoAnims[3].startTime, duration: this.trackAnims.lapInfoAnims[3].duration, key: tempTrack.facts[3].key, value: tempTrack.facts[3].value });

    if (this.oddsAlwaysOn) {
      lapInfoAnims.push({ startTime: this.trackAnims.lapInfoAnims[4].startTime, duration: this.trackAnims.lapInfoAnims[4].duration, key: tempTrack.facts[0].key, value: tempTrack.facts[0].value });
      lapInfoAnims.push({ startTime: this.trackAnims.lapInfoAnims[5].startTime, duration: this.trackAnims.lapInfoAnims[5].duration, key: tempTrack.facts[1].key, value: tempTrack.facts[1].value });
      lapInfoAnims.push({ startTime: this.trackAnims.lapInfoAnims[6].startTime, duration: this.trackAnims.lapInfoAnims[6].duration, key: tempTrack.facts[2].key, value: tempTrack.facts[2].value });
      lapInfoAnims.push({ startTime: this.trackAnims.lapInfoAnims[7].startTime, duration: this.trackAnims.lapInfoAnims[7].duration, key: tempTrack.facts[3].key, value: tempTrack.facts[3].value });
    }

    this.lapInfo.anims = lapInfoAnims;
    {
      let i = 0;
      while (this.turns.length < track.items.length) {
        let anims = [this.trackAnims.turnItemStarts[i]];

        if (this.trackAnims.turnItemStarts.length > track.items.length && this.oddsAlwaysOn) {
          anims = [this.trackAnims.turnItemStarts[i], this.trackAnims.turnItemStarts[i + track.items.length]];
        }

        const turn = new TurnItemDog(anims, this.oddsAlwaysOn);
        this.turns.push(turn);
        this.add(turn);
        i++;
      }
    }
    for (let i = 0; i < track.items.length; i++) {
      this.turns[i].fill(track.items[i]);
      this.turns[i].visible = true;
    }
    for (let i = track.items.length; i < this.turns.length; i++) {
      this.turns[i].visible = false;
    }

    if (track.segments !== undefined) {
      let i = 0;
      while (this.segments.length < track.segments.length) {
        let anims = [this.trackAnims.segmentAnims[i]];
        if (this.trackAnims.segmentAnims.length > track.segments.length && this.oddsAlwaysOn) {
          anims = [this.trackAnims.segmentAnims[i], this.trackAnims.segmentAnims[i + track.segments.length]];
        }

        const segment = new TrackPresentationLapSegment(this.gameType, withBonus, anims, this.oddsAlwaysOn);
        this.segments.push(segment);
        segment.fill(track.segments[i]);
        this.add(segment);
        i++;
      }
    }

    // track lamp map facts
    {
      // weather, temp, humidity, wind
      let vailableWidths: number[] = [138, 108, 136, 122];
      if (this.oddsAlwaysOn) vailableWidths = [98, 72, 98, 84];
      const alignLeft: boolean[] = [true, false, false, true];
      if (track.lapMapFacts !== undefined) {
        let i = 0;
        while (this.lapMapFacts.length < track.lapMapFacts.length) {
          let anims = [this.trackAnims.lapMapFactAnims[i]];

          if (this.trackAnims.lapMapFactAnims.length > track.lapMapFacts.length && this.oddsAlwaysOn) {
            anims = [this.trackAnims.lapMapFactAnims[i], this.trackAnims.lapMapFactAnims[i + track.lapMapFacts.length]];
          }

          const lapMapFact = new TrackPresentationLapMapFact(anims, alignLeft[i], vailableWidths[i] - 10, this.oddsAlwaysOn);
          this.lapMapFacts.push(lapMapFact);
          lapMapFact.fill(track.lapMapFacts[i], vailableWidths[i] - 10);
          this.add(lapMapFact);
          i++;
        }
      }
    }

    this.onLayout();
  }

  public onLayout(): void {
    if (!this.turns || this.turns.length === 0) {
      return;
    }
    for (const t of this.turns) {
      t.width = _s(100);
      t.height = _s(100);
    }

    for (const s of this.segments) {
      s.width = _s(200);
      s.height = _s(110);
    }

    let key: string = this.gameType;
    if (this.oddsAlwaysOn) key = "oddsAlwaysOn";
    // get turns position for gametype
    for (let i = 0; i < this.turns.length; i++) {
      const turn = this.turns[i];
      turn.x = _s(TrackPresentationPositions[key as keyof typeof TrackPresentationPositions].turns[i].x);
      turn.y = _s(TrackPresentationPositions[key as keyof typeof TrackPresentationPositions].turns[i].y);
    }

    // get segments position for gametype
    for (let i = 0; i < this.segments.length; i++) {
      const segment = this.segments[i];
      segment.x = _s(TrackPresentationPositions[key as keyof typeof TrackPresentationPositions].segments[i].x);
      segment.y = _s(TrackPresentationPositions[key as keyof typeof TrackPresentationPositions].segments[i].y);
    }

    // get lapmapfact position for gametype
    for (let i = 0; i < this.lapMapFacts.length; i++) {
      const lapMapFact = this.lapMapFacts[i];
      lapMapFact.x = _s(TrackPresentationPositions[key as keyof typeof TrackPresentationPositions].lapMapFacts[i].x);
      lapMapFact.y = _s(TrackPresentationPositions[key as keyof typeof TrackPresentationPositions].lapMapFacts[i].y);
      // lapMapFact.width = _s(TrackPresentationPositions[key as keyof typeof TrackPresentationPositions].lapMapFacts[i].width!)
      lapMapFact.height = _s(30);
    }

    // get timesCircle position for gametype
    this.timesCircle.position.x = _s(TrackPresentationPositions[key as keyof typeof TrackPresentationPositions].timesCircle.x);
    this.timesCircle.position.y = _s(TrackPresentationPositions[key as keyof typeof TrackPresentationPositions].timesCircle.y);

    // get trackName Y position for gametype
    this.trackName.y = _s(TrackPresentationPositions[key as keyof typeof TrackPresentationPositions].trackName.y);

    // fixed for all gametypes
    this.lapInfo.position.x = _s(422);
    this.lapInfo.position.y = _s(-50);
    this.lapInfo.width = _s(152);
    this.lapInfo.height = _s(50);

    if (this.oddsAlwaysOn) {
      this.lapInfo.position.set(_s(260), _s(0));
    }
  }

  public update(dt: number): void {
    super.update(dt);
    const t = Logic.getVideoTime();

    const anim: TrackPresentationAnim | undefined = Logic.getAnim(t, this.anims, this);
    if (!anim) {
      this.visible = false;
      return;
    }
    this.visible = true;

    const baseFactor = t - anim.startTime;
    this.visible = baseFactor >= 0 && baseFactor <= anim.duration;
    if (this.turns.length < 4) return;

    // trackname - fades in/out some times
    if (baseFactor > 0 && baseFactor < anim.times[1].startTime)
      AnimHelper.animateInOut(baseFactor, anim.times[0].startTime, anim.times[0].startTime + anim.times[0].duration, 0.5, 0, 1, (val) => this.fadeTrackName(this.trackName, val), 0.4, 0.0);
    else if (baseFactor < anim.times[2].startTime)
      AnimHelper.animateInOut(baseFactor, anim.times[1].startTime, anim.times[1].startTime + anim.times[1].duration, 0.5, 0.0, 1, (val) => this.fadeTrackName(this.trackName, val), 0.4, 0.0);
    else AnimHelper.animateInOut(baseFactor, anim.times[2].startTime, anim.times[2].startTime + anim.times[2].duration, 0.5, 0.0, 1, (val) => this.fadeTrackName(this.trackName, val), 0.4, 0.0);

    for (const turn of this.turns) AnimHelper.animateInOut(baseFactor, 0, 3.7, 0.0, 0, 1, (val) => (turn.alpha = val), 0.5, 0);

    // fade out segments
    for (const segment of this.segments) {
      AnimHelper.animateIn(baseFactor, 8.6, 10, 0.5, 1, 0, (val) => (segment.alpha = val));
    }

    AnimHelper.animateInOut(baseFactor, 9.87, 16.5, 0.3, 0, 1, (val) => (this.timesCircle.alpha = val), 0.6, 0);
  }

  fadeTrackName(trackName: PIXI.Text, val: number): void {
    const isHorse = this.gameType === "horse" || this.gameType === "sulky";

    let fadeToX = 227;
    if (isHorse) fadeToX = 236;
    else if (this.oddsAlwaysOn) fadeToX = 160;

    const f = 100;
    trackName.x = _s(fadeToX - f + f * val);
    trackName.alpha = val;
  }
}
