import { oddsAlwaysOnStyles, bottomBarSettings, oddsAlwaysOnTimings } from "../../settings/OddsAlwaysOnSettings";
import { AnimHelper } from "./../common/Anim";
import * as PIXI from "pixi.js";
import { Group } from "../../Graphics/Group";
import { Logic, _s, settings } from "../../Logic/Logic";
import { IColors, IDriver, IGameInfo, IAnimInterval } from "../../Logic/LogicDefinitions";
import { MultiStyleText } from "./../common/MultiStyleText";
import { DrawHelper } from "../common/DrawHelper";
import { Util } from "../../common/Util";
import { GameType, GameLength } from "../../common/Definitions";
import { DogHelper } from "./DogHelper";

class BottomBarItemDogAnim implements IAnimInterval {
  public startTime: number = 0;
  public duration: number = 0;
  public infoStartTime?: number;
  public infoTime?: number;
  public fadeInTime?: number;
  public fadeOutStart?: number;
  public fadeOutTime?: number;
  public fadeTimePerLine?: number = 0.12;
  public atOnce?: boolean;
}

export class BottomBarItemDog extends Group {
  private index: number;
  private numberText: PIXI.Text;
  private driverName!: MultiStyleText;
  private infoKeys: PIXI.Text[] = [];
  private infoValues: PIXI.Text[] = [];
  private infoGroup: PIXI.Container;
  private infoGroupWithMask: PIXI.Container;
  private mask: PIXI.Sprite;

  private fieldCount: number = 4;
  private lineHeight: number;
  private xOffsetPerField: number = 20 / 4;
  private gameInfo: IGameInfo;
  private racerCount: number;
  private _helper: DogHelper;
  private oddsAlwaysOn;

  public constructor(index: number, gameInfo: IGameInfo, helper: DogHelper, oddsAlwaysOn = false) {
    super();

    this.gameInfo = gameInfo;
    this.racerCount = Logic.getRacerCount(gameInfo.gameType);
    this.lineHeight = 88 / this.fieldCount;
    this._helper = helper;
    this.oddsAlwaysOn = oddsAlwaysOn;
    this.showDebug(settings.debug, 1, "BottomBarItemDog");
    // this.showDebug(true);

    if (this.oddsAlwaysOn) {
      const { lineHeigth, xOffsetPerField } = bottomBarSettings[this.gameInfo.gameType as keyof typeof bottomBarSettings];
      this.lineHeight = lineHeigth / this.fieldCount;

      this.xOffsetPerField = xOffsetPerField;
    }

    this.index = index;
    {
      const style = new PIXI.TextStyle({
        fontFamily: "DIN-HeavyItalic",
        fontSize: _s(22),
        fill: DogHelper.getWhiteColorNumber(),
        fontStyle: "italic"
      });
      this.numberText = Logic.createPixiText(style, "" + (index + 1));
      this.numberText.position.y = _s(12);
      this.numberText.anchor.set(0.5);
      // this.container.addChild(this.numberText);
    }

    this.driverName = new MultiStyleText();
    this.add(this.driverName);

    this.infoGroupWithMask = new PIXI.Container();
    this.add(this.infoGroupWithMask);

    this.infoGroup = new PIXI.Container();
    this.infoGroupWithMask.addChild(this.infoGroup);
    {
      const greyStyle = new PIXI.TextStyle({
        fontFamily: "DIN-UltraLightItalic",
        fontSize: _s(12),
        fill: DogHelper.getLightGrayColorNumber(),
        fontStyle: "italic"
      });
      const whiteStyle = new PIXI.TextStyle({
        fontFamily: "DIN-RegularItalic",
        fontSize: _s(12),
        fill: DogHelper.getWhiteColor(),
        fontStyle: "italic"
      });

      if (this.oddsAlwaysOn) {
        const {
          bottomBar: { fontSize }
        } = oddsAlwaysOnStyles[this.gameInfo.gameType as keyof typeof oddsAlwaysOnStyles];
        greyStyle.fontSize = _s(fontSize);
        whiteStyle.fontSize = _s(fontSize);
      }

      // detail info per driver
      for (let i = 0; i < this.fieldCount; i++) {
        const key = Logic.createPixiText(greyStyle);
        key.x = _s(24 - this.xOffsetPerField * i);
        key.y = _s(10 + this.lineHeight * i);

        if (this.oddsAlwaysOn) {
          const {
            key: { xOffset, yOffset }
          } = bottomBarSettings[this.gameInfo.gameType as keyof typeof oddsAlwaysOnStyles];
          key.x = _s(xOffset - this.xOffsetPerField * i);
          key.y = _s(yOffset + this.lineHeight * i);
        }

        key.anchor.set(0, 0.5);
        this.infoKeys.push(key);
        this.infoGroup.addChild(key);

        const value = Logic.createPixiText(whiteStyle);
        value.x = key.x;
        value.y = key.y;
        value.anchor.set(1, 0.5);
        this.infoValues.push(value);
        this.infoGroup.addChild(value);
      }

      // const graphics  = DrawHelper.createSkewedRoundedRectangleGraphics(0, 0, _s(152.5), _s(87.5), _s(12.5), _s(15));
      // graphics.alpha = 0.5;
      // this.infoGroup.addChild(graphics);
      let maskWidth = _s(152.5);
      let maskHeigth = _s(87.5);

      if (this.oddsAlwaysOn) {
        const {
          mask: { width, heigth }
        } = bottomBarSettings[this.gameInfo.gameType as keyof typeof bottomBarSettings];
        maskWidth = _s(width);
        maskHeigth = _s(heigth);
      }
      const maskTexture = DrawHelper.createSkewedRoundedRectangleTexture(maskWidth, maskHeigth, _s(12.5), _s(19), { type: "solid", color: "red" });
      this.mask = new PIXI.Sprite(maskTexture);
      this.mask.alpha = 1;
      this.infoGroupWithMask.addChild(this.mask);
      this.infoGroupWithMask.mask = this.mask; // comment/uncomment mask
    }
  }

  public createAnims(gameType: GameType, gameLength: GameLength, withBonus: boolean, language: string): BottomBarItemDogAnim[] {
    if (this.oddsAlwaysOn) {
      return oddsAlwaysOnTimings[gameType as keyof typeof oddsAlwaysOnTimings].bottomBar.anims;
    }
    if (gameType === "horse" || gameType === "sulky") {
      switch (gameLength) {
        default: {
          // 300
          return [
            {
              startTime: 2.0,
              duration: 25.6,
              infoStartTime: 1.6,
              infoTime: 3.28,
              fadeInTime: 0.6,
              fadeOutStart: 2.05,
              fadeOutTime: 0.4,
              fadeTimePerLine: undefined
            }, // general bar fade in/out2
            { startTime: 70.4, duration: 29.3 }, // 44.0 },
            {
              startTime: 102.8,
              duration: 18.5,
              infoStartTime: 1.4,
              infoTime: 2.323,
              fadeInTime: 0.4,
              fadeOutStart: 1.7,
              fadeOutTime: 0.3,
              fadeTimePerLine: undefined
            }, // general bar fade in/out
            withBonus
              ? {
                startTime: 162.0,
                duration: 15.2,
                infoStartTime: 1.8,
                infoTime: 1.75,
                fadeInTime: 0.4,
                fadeOutStart: 1.7,
                fadeOutTime: 0.3,
                fadeTimePerLine: 0.1
              }
              : {
                startTime: 157.0,
                duration: 20.3,
                infoStartTime: 1.7,
                infoTime: 2.55,
                fadeInTime: 0.4,
                fadeOutStart: 1.7,
                fadeOutTime: 0.3,
                fadeTimePerLine: 0.1
              }
          ];
        }
      }
    }
    if (gameType === "dog6") {
      switch (gameLength) {
        case 120:
          return [
            {
              startTime: 1.8,
              duration: 12.8,
              infoStartTime: 1.19,
              infoTime: 1.99,
              fadeInTime: 0.2,
              fadeOutStart: 1.24,
              fadeOutTime: 0.2,
              fadeTimePerLine: undefined
            },
            withBonus
              ? {
                startTime: 37.8,
                duration: 20.5,
                infoStartTime: 1.7,
                infoTime: 3.18,
                fadeInTime: 0.4,
                fadeOutStart: 1.95,
                fadeOutTime: 0.25,
                fadeTimePerLine: undefined
              } // general bar fade in/out
              : {
                startTime: 33.5,
                duration: 24.7,
                infoStartTime: 1.9,
                infoTime: 3.84,
                fadeInTime: 0.4,
                fadeOutStart: 2.4,
                fadeOutTime: 0.25,
                fadeTimePerLine: undefined
              } // general bar fade in/out
          ];
        case 180:
          return [
            withBonus
              ? {
                startTime: 2.8,
                duration: 16.6,
                infoStartTime: 1.05,
                infoTime: 2.65,
                fadeInTime: 0.4,
                fadeOutStart: 1.73,
                fadeOutTime: 0.4,
                fadeTimePerLine: undefined
              }
              : {
                startTime: 3.5,
                duration: 21.1,
                infoStartTime: 1.4,
                infoTime: 3.32,
                fadeInTime: 0.4,
                fadeOutStart: 2.16,
                fadeOutTime: 0.4,
                fadeTimePerLine: undefined
              }, // general bar fade in/out
            withBonus
              ? {
                startTime: 60.6,
                duration: 19.5,
                infoStartTime: undefined,
                infoTime: undefined,
                fadeInTime: undefined,
                fadeOutStart: undefined,
                fadeOutTime: undefined,
                fadeTimePerLine: undefined
              } // 44.0 },
              : {
                startTime: 70.6,
                duration: 19.5,
                infoStartTime: undefined,
                infoTime: undefined,
                fadeInTime: undefined,
                fadeOutStart: undefined,
                fadeOutTime: undefined,
                fadeTimePerLine: undefined
              }, // 44.0 },
            {
              startTime: 93.5,
              duration: 24.5,
              infoStartTime: 2.0,
              infoTime: 3.81,
              fadeInTime: 0.4,
              fadeOutStart: 2.5,
              fadeOutTime: 0.4,
              fadeTimePerLine: undefined
            }
          ]; // same with and without bonus
        case 240:
          return [
            language === "it"
              ? {
                startTime: 11.5,
                duration: 18.3,
                infoStartTime: 5.03,
                infoTime: 2.08,
                fadeInTime: 0.4,
                fadeOutStart: 1.45,
                fadeOutTime: 0.3,
                fadeTimePerLine: undefined
              }
              : {
                startTime: 3.5,
                duration: 25.9,
                infoStartTime: 2.4,
                infoTime: 3.92,
                fadeInTime: 0.4,
                fadeOutStart: 2.42,
                fadeOutTime: 0.4,
                fadeTimePerLine: undefined
              }, // general bar fade in/out
            {
              startTime: 70.1,
              duration: 20.0,
              infoStartTime: undefined,
              infoTime: undefined,
              fadeInTime: undefined,
              fadeOutStart: undefined,
              fadeOutTime: undefined,
              fadeTimePerLine: undefined
            }, // 44.0 },
            language === "it"
              ? {
                startTime: 93.5,
                duration: 26.3,
                infoStartTime: 6.5,
                infoTime: 3.15,
                fadeInTime: 0.4,
                fadeOutStart: 2.0,
                fadeOutTime: 0.4,
                fadeTimePerLine: undefined
              }
              : {
                startTime: 93.5,
                duration: 25.3,
                infoStartTime: 2.2,
                infoTime: 3.93,
                fadeInTime: 0.4,
                fadeOutStart: 2.5,
                fadeOutTime: 0.4,
                fadeTimePerLine: undefined
              }, // general bar fade in/out
            withBonus
              ? {
                startTime: 153.5,
                duration: 25.3,
                infoStartTime: 2.05,
                infoTime: 3.96,
                fadeInTime: 0.25,
                fadeOutStart: 2.32,
                fadeOutTime: 0.25,
                fadeTimePerLine: 0.08
              }
              : language === "it"
                ? {
                  startTime: 144.35,
                  duration: 33.9,
                  infoStartTime: 8.7,
                  infoTime: 4.2,
                  fadeInTime: 0.5,
                  fadeOutStart: 2.78,
                  fadeOutTime: 0.4,
                  fadeTimePerLine: 0.2
                }
                : {
                  startTime: 144.35,
                  duration: 33.9,
                  infoStartTime: 3.0,
                  infoTime: 5.28,
                  fadeInTime: 0.5,
                  fadeOutStart: 3.28,
                  fadeOutTime: 0.4,
                  fadeTimePerLine: 0.2
                }
          ];
        default: {
          // 300
          return [
            withBonus
              ? {
                startTime: 3.5,
                duration: 25.9,
                infoStartTime: 2.4,
                infoTime: 3.96,
                fadeInTime: 0.4,
                fadeOutStart: 2.42,
                fadeOutTime: 0.4,
                fadeTimePerLine: undefined
              }
              : language === "it"
                ? {
                  startTime: 12.5,
                  duration: 22.75,
                  infoStartTime: 5.75,
                  infoTime: 2.62,
                  fadeInTime: 0.6,
                  fadeOutStart: 1.9,
                  fadeOutTime: 0.3,
                  fadeTimePerLine: undefined
                }
                : {
                  startTime: 4.5,
                  duration: 29.5,
                  infoStartTime: 2.35,
                  infoTime: 4.63,
                  fadeInTime: 0.6,
                  fadeOutStart: 2.93,
                  fadeOutTime: 0.6,
                  fadeTimePerLine: undefined
                }, // general bar fade in/out
            withBonus ? { startTime: 70.1, duration: 20.0 } : { startTime: 80.6, duration: 19.5 }, // 44.0 },
            withBonus
              ? {
                startTime: 93.5,
                duration: 25.3,
                infoStartTime: 2.2,
                infoTime: 4.0,
                fadeInTime: 0.4,
                fadeOutStart: 2.5,
                fadeOutTime: 0.4,
                fadeTimePerLine: undefined
              }
              : language === "it"
                ? {
                  startTime: 103.7,
                  duration: 26.3,
                  infoStartTime: 6.4,
                  infoTime: 3.12,
                  fadeInTime: 0.4,
                  fadeOutStart: 2.1,
                  fadeOutTime: 0.4,
                  fadeTimePerLine: undefined
                }
                : {
                  startTime: 103.7,
                  duration: 25.3,
                  infoStartTime: 2.15,
                  infoTime: 3.98,
                  fadeInTime: 0.4,
                  fadeOutStart: 2.5,
                  fadeOutTime: 0.4,
                  fadeTimePerLine: undefined
                }, // general bar fade in/out
            withBonus
              ? {
                startTime: 160.0,
                duration: 32.9,
                infoStartTime: 2.6,
                infoTime: 5.18,
                fadeInTime: 0.6,
                fadeOutStart: 2.9,
                fadeOutTime: 0.6,
                fadeTimePerLine: 0.08
              }
              : language === "it"
                ? {
                  startTime: 159.7,
                  duration: 34.5,
                  infoStartTime: 8.5,
                  infoTime: 4.13,
                  fadeInTime: 0.7,
                  fadeOutStart: 2.5,
                  fadeOutTime: 0.68,
                  fadeTimePerLine: 0.1
                }
                : {
                  startTime: 159.7,
                  duration: 32.9,
                  infoStartTime: 2.7,
                  infoTime: 5.18,
                  fadeInTime: 0.7,
                  fadeOutStart: 3.0,
                  fadeOutTime: 0.68,
                  fadeTimePerLine: 0.1
                } // Same for bonus and without
          ];
        }
      }
    }

    // dog8
    switch (gameLength) {
      case 120:
        return [
          withBonus
            ? {
              startTime: 2.05,
              duration: 12.1,
              infoStartTime: 1.0,
              infoTime: 1.345,
              fadeInTime: 0.1,
              fadeOutStart: 0.85,
              fadeOutTime: 0.1,
              fadeTimePerLine: 0.06
            }
            : {
              startTime: 2.05,
              duration: 12.1,
              infoStartTime: 1.0,
              infoTime: 1.335,
              fadeInTime: 0.1,
              fadeOutStart: 0.85,
              fadeOutTime: 0.1,
              fadeTimePerLine: 0.06
            },
          withBonus
            ? {
              startTime: 35.8,
              duration: 22.7,
              infoStartTime: 2.79,
              infoTime: 2.36,
              fadeInTime: 0.4,
              fadeOutStart: 1.37,
              fadeOutTime: 0.2,
              fadeTimePerLine: 0.08
            } // general bar fade in/out
            : {
              startTime: 31.0,
              duration: 27.7,
              infoStartTime: 3.1,
              infoTime: 2.94,
              fadeInTime: 0.4,
              fadeOutStart: 1.65,
              fadeOutTime: 0.25,
              fadeTimePerLine: undefined
            } // general bar fade in/out
        ];
      case 180:
        return [
          withBonus
            ? {
              startTime: 2.8,
              duration: 16.8,
              infoStartTime: 0.7,
              infoTime: 1.92,
              fadeInTime: 0.4,
              fadeOutStart: 1.35,
              fadeOutTime: 0.25,
              fadeTimePerLine: undefined
            }
            : {
              startTime: 2.8,
              duration: 21.8,
              infoStartTime: 1.5,
              infoTime: 2.41,
              fadeInTime: 0.4,
              fadeOutStart: 1.7,
              fadeOutTime: 0.25,
              fadeTimePerLine: undefined
            },
          withBonus
            ? {
              startTime: 60.2,
              duration: 20.0,
              infoStartTime: undefined,
              infoTime: undefined,
              fadeInTime: undefined,
              fadeOutStart: undefined,
              fadeOutTime: undefined,
              fadeTimePerLine: undefined
            } // 44.0 },
            : {
              startTime: 70.2,
              duration: 20.0,
              infoStartTime: undefined,
              infoTime: undefined,
              fadeInTime: undefined,
              fadeOutStart: undefined,
              fadeOutTime: undefined,
              fadeTimePerLine: undefined
            }, // 44.0 },
          {
            startTime: 91.8,
            duration: 26.7,
            infoStartTime: 3.3,
            infoTime: 2.79,
            fadeInTime: 0.4,
            fadeOutStart: 1.5,
            fadeOutTime: 0.25,
            fadeTimePerLine: undefined
          } // general bar fade in/out
        ];
      case 240:
        return [
          language === "it"
            ? {
              startTime: 11.4,
              duration: 18.9,
              infoStartTime: 4.2,
              infoTime: 1.68,
              fadeInTime: 0.4,
              fadeOutStart: 1.35,
              fadeOutTime: 0.25,
              fadeTimePerLine: undefined
            }
            : {
              startTime: 3.0,
              duration: 26.7,
              infoStartTime: 2.15,
              infoTime: 2.9,
              fadeInTime: 0.4,
              fadeOutStart: 1.8,
              fadeOutTime: 0.4,
              fadeTimePerLine: undefined
            }, // general bar fade in/out
          {
            startTime: 70.1,
            duration: 30.0,
            infoStartTime: undefined,
            infoTime: undefined,
            fadeInTime: undefined,
            fadeOutStart: undefined,
            fadeOutTime: undefined,
            fadeTimePerLine: undefined
          }, // 44.0 },
          language === "it"
            ? {
              startTime: 101.0,
              duration: 24.2,
              infoStartTime: 6.1,
              infoTime: 2.1,
              fadeInTime: 0.4,
              fadeOutStart: 1.5,
              fadeOutTime: 0.4,
              fadeTimePerLine: undefined
            }
            : {
              startTime: 101.0,
              duration: 23.7,
              infoStartTime: 3.3,
              infoTime: 2.43,
              fadeInTime: 0.4,
              fadeOutStart: 1.4,
              fadeOutTime: 0.4,
              fadeTimePerLine: undefined
            }, // general bar fade in/out
          withBonus
            ? {
              startTime: 156.0,
              duration: 22.3,
              infoStartTime: 3.1,
              infoTime: 2.28,
              fadeInTime: 0.25,
              fadeOutStart: 1.28,
              fadeOutTime: 0.25,
              fadeTimePerLine: 0.08
            }
            : language === "it"
              ? {
                startTime: 146.5,
                duration: 31.3,
                infoStartTime: 8.26,
                infoTime: 2.83,
                fadeInTime: 0.3,
                fadeOutStart: 1.6,
                fadeOutTime: 0.3,
                fadeTimePerLine: 0.08
              }
              : {
                startTime: 146.5,
                duration: 31.3,
                infoStartTime: 4.4,
                infoTime: 3.3,
                fadeInTime: 0.3,
                fadeOutStart: 1.5,
                fadeOutTime: 0.3,
                fadeTimePerLine: 0.08
              }
        ];
      default: {
        // 300
        return [
          withBonus
            ? {
              startTime: 3.0,
              duration: 26.7,
              infoStartTime: 2.15,
              infoTime: 2.9,
              fadeInTime: 0.4,
              fadeOutStart: 1.8,
              fadeOutTime: 0.4,
              fadeTimePerLine: undefined
            }
            : language === "it"
              ? {
                startTime: 11.6,
                duration: 23.4,
                infoStartTime: 5.45,
                infoTime: 2.115,
                fadeInTime: 0.4,
                fadeOutStart: 1.4,
                fadeOutTime: 0.3,
                fadeTimePerLine: undefined
              }
              : {
                startTime: 3.2,
                duration: 30.7,
                infoStartTime: 2.65,
                infoTime: 3.41,
                fadeInTime: 0.4,
                fadeOutStart: 2.2,
                fadeOutTime: 0.4,
                fadeTimePerLine: undefined
              }, // general bar fade in/out
          withBonus ? { startTime: 70.1, duration: 30.0 } : { startTime: 80.1, duration: 30.3 }, // 44.0 },
          withBonus
            ? {
              startTime: 101.0,
              duration: 23.7,
              infoStartTime: 3.3,
              infoTime: 2.43,
              fadeInTime: 0.4,
              fadeOutStart: 1.4,
              fadeOutTime: 0.4,
              fadeTimePerLine: undefined
            }
            : language === "it"
              ? {
                startTime: 111.0,
                duration: 24.2,
                infoStartTime: 6.0,
                infoTime: 2.11,
                fadeInTime: 0.4,
                fadeOutStart: 1.5,
                fadeOutTime: 0.4,
                fadeTimePerLine: undefined
              }
              : {
                startTime: 111.0,
                duration: 23.7,
                infoStartTime: 3.3,
                infoTime: 2.43,
                fadeInTime: 0.4,
                fadeOutStart: 1.4,
                fadeOutTime: 0.4,
                fadeTimePerLine: undefined
              }, // general bar fade in/out
          withBonus
            ? {
              startTime: 161.7,
              duration: 31.8,
              infoStartTime: 4.35,
              infoTime: 3.3,
              fadeInTime: 0.25,
              fadeOutStart: 1.75,
              fadeOutTime: 0.4,
              fadeTimePerLine: 0.08
            }
            : language === "it"
              ? {
                startTime: 161.7,
                duration: 31.8,
                infoStartTime: 8.25,
                infoTime: 2.865,
                fadeInTime: 0.25,
                fadeOutStart: 1.7,
                fadeOutTime: 0.4,
                fadeTimePerLine: 0.08
              }
              : {
                startTime: 161.7,
                duration: 31.8,
                infoStartTime: 4.35,
                infoTime: 3.32,
                fadeInTime: 0.25,
                fadeOutStart: 1.6,
                fadeOutTime: 0.4,
                fadeTimePerLine: 0.08
              } // Same for bonus and without
        ];
      }
    }
  }

  public fill(driver: IDriver, colors: IColors, withBonus: boolean, language: string): void {
    this.numberText.tint = driver.color;
    this.driverName.text = driver.firstName.toUpperCase(); //  + " <b>" + driver.lastName.toUpperCase() + "</b>";
    this.driverName.styles = this._helper.getBottomBarItemNameStyle();
    this.driverName.anchor.set(0, 0.51);

    for (let i = 0; i < this.infoKeys.length; i++) {
      this.infoKeys[i].text = driver.driverInfos[i].key;
      // resize fontsize to fit in box
      Logic.autoSize(this.infoKeys[i], _s(85));
      this.infoValues[i].text = driver.driverInfos[i].value;
      Logic.autoSize(this.infoValues[i], _s(50));
    }

    // fadeTimePerLine not set: 0.12
    this.anims = this.createAnims(this.gameInfo.gameType, this.gameInfo.gameLength, withBonus, language);
  }

  public onLayout() {
    this.driverName.position.y = this.height * 0.5 + _s(1);

    this.infoGroupWithMask.y = _s(-88);
    this.infoGroupWithMask.x = _s(17);

    if (this.oddsAlwaysOn) {
      const { infoGroupWithMask } = bottomBarSettings[this.gameInfo.gameType as keyof typeof bottomBarSettings];

      this.infoGroupWithMask.y = _s(infoGroupWithMask.y);
      this.infoGroupWithMask.x = _s(infoGroupWithMask.x);
    }
  }
  private anims: BottomBarItemDogAnim[] = [];

  public update(dt: number) {
    super.update(dt);

    const ct = Logic.getVideoTime();
    const anim = Logic.getAnim(ct, this.anims, this);
    if (!anim) return;

    // baseFactor is 0 from startTime of the video
    const baseFactor = ct - (anim.startTime + 0.1 * this.index);
    let indexFactor = this.index;
    if (this.oddsAlwaysOn) indexFactor = 0;

    const fadeOutFactor = AnimHelper.clamp((ct - (anim.startTime - 0.1 * indexFactor) - anim.duration) * 2);
    this.container.alpha = 1.0 - fadeOutFactor;

    if (ct < anim.startTime + anim.duration - 2.0) {
      // fadein
      const driverNameFactor = ct - (anim.startTime + 0.13 * this.index);
      // some movement to the right and back
      this.driverName.x = _s(53) + Math.sin(Util.clamp(driverNameFactor * 5, 0, Math.PI)) * 10;
      // fake letterspacing animation with scale - blurred...
      this.driverName.scale.x = 1 + Math.sin(Util.clamp(driverNameFactor * 6, 0, Math.PI)) * 0.15;
      // some alpha animation
      this.driverName.alpha = driverNameFactor;
    } else {
      this.driverName.alpha = (anim.startTime + anim.duration - ct - 0.14 * this.index) * 2;
    }

    const f = AnimHelper.easeOut(AnimHelper.clampedRange(baseFactor, 0.5, 1.0));
    this.numberText.alpha = f;
    this.numberText.x = _s(23 + (1.0 - f) * 100);

    if (anim.infoTime) {
      let infoFactorBase = ct - (anim.startTime + (anim.infoStartTime ? anim.infoStartTime : 0) + anim.infoTime * this.index);

      // let fade = AnimHelper.easeOut(infoFactorBase * 1.5, 5);
      const fadeInTime = anim.fadeInTime === undefined ? 1 : anim.fadeInTime;

      const lastRacer = this.index === this.racerCount - 1;
      const fadeOutTime = anim.fadeOutTime === undefined ? 1 : anim.fadeOutTime;
      const fadeOutStartNormal = anim.fadeOutStart === undefined ? 1 : anim.fadeOutStart;
      // MS fadeOutStartLast does not exist?
      const fadeOutStartLast = fadeOutStartNormal; // anim.fadeOutStartLast ? anim.fadeOutStartLast : fadeOutStartNormal;
      const fadeOutStart = lastRacer ? fadeOutStartLast : fadeOutStartNormal;
      const fadeTimePerLine = anim.fadeTimePerLine === undefined ? 0.12 : anim.fadeTimePerLine;
      if (anim.atOnce) {
        let factor = oddsAlwaysOnTimings[this.gameInfo.gameType as keyof typeof oddsAlwaysOnTimings].bottomBar.factor;
        if (!(infoFactorBase * fadeTimePerLine <= fadeInTime) && factor !== 0.0) factor = -0.1;
        infoFactorBase = ct - (anim.startTime + (anim.infoStartTime ? anim.infoStartTime : 0) + anim.infoTime + this.index * factor);
      }
      for (let i = 0; i < this.infoKeys.length; i++) {
        let fade: number = 1.0;
        let fadeDistance = 140;

        if (infoFactorBase - i * fadeTimePerLine <= fadeInTime) {
          fade = AnimHelper.easeOut((infoFactorBase - i * fadeTimePerLine) * (1.0 / fadeInTime), 5);
        } else {
          fade = AnimHelper.easeIn(1.0 - (infoFactorBase - fadeOutStart + i * fadeTimePerLine) * (1.0 / fadeOutTime), 5);
          fadeDistance = 70;
        }

        const fadeX = fade;
        const alpha = fade * fade;

        let xPositionKey = 24;
        let xPositionValue = 118;
        if (this.oddsAlwaysOn) {
          const { finalKeyValXPos } = bottomBarSettings[this.gameInfo.gameType as keyof typeof bottomBarSettings];
          xPositionKey = finalKeyValXPos.key;
          xPositionValue = finalKeyValXPos.value;
        }
        this.infoKeys[i].x = _s((1.0 - fadeX) * -fadeDistance) + _s(xPositionKey - this.xOffsetPerField * i);
        this.infoValues[i].x = this.infoKeys[i].x + _s(xPositionValue);
        this.infoKeys[i].alpha = alpha;
        this.infoValues[i].alpha = alpha;
      }

      this.infoGroup.alpha = 1;
    } else {
      this.infoGroup.alpha = 0;
    }
  }
}
