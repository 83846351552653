import { MultiText } from "./../common/MultiText";
import * as PIXI from "pixi.js";
import { Group } from "../../Graphics/Group";
import { _s, settings } from "../../Logic/Logic";
import { Util } from "../../common/Util";
import { DriverInfoBarKart } from "./DriverInfoBarKart";
import { Logic } from "../../Logic/Logic";
import { IDriver, IAnimInterval } from "../../Logic/LogicDefinitions";
import { GameLength } from "../../common/Definitions";

type Anim = IAnimInterval & { pause: number };
interface ICombinedAnim {
  driver: Anim;
  bar: Anim;
}

export class DriverPresentationKart extends Group {
  private racerCount: number;
  private driverName: MultiText;
  private driverNumberMain!: PIXI.Text;
  private oddsNumberMain!: PIXI.Text;
  private driverNumberCol1: PIXI.Text[] = [];
  private driverNumberCol2: PIXI.Text[] = [];
  private driverOdds: PIXI.Text[] = [];
  private currentDriverIndex: number = -1;
  private currentBarIndex: number = -1;
  private driverInfoBar: DriverInfoBarKart;
  private drivers: IDriver[] = [];
  private odds: number[] = [];
  private anim: ICombinedAnim | undefined;
  private gameLength: GameLength;

  public constructor(racerCount: number, gameLength: GameLength) {
    super();
    this.racerCount = racerCount;
    this.gameLength = gameLength;
    this.showDebug(settings.debug);

    this.driverName = MultiText.fromName(["", ""], _s(26));
    this.add(this.driverName);

    const styleBold = new PIXI.TextStyle({
      fontFamily: "DIN-HeavyItalic",
      fontSize: _s(35),
      fill: "white",
      align: "center",
      fontStyle: "italic"
    });
    this.driverNumberMain = new PIXI.Text("", styleBold);
    this.driverNumberMain.anchor.set(0.5);
    this.driverNumberMain.roundPixels = true;
    // this.add(this.driverNumberMain);

    const styleOdds = new PIXI.TextStyle({
      fontFamily: "DIN-Medium",
      fontSize: _s(22),
      fill: "white",
      align: "center"
    });
    this.oddsNumberMain = new PIXI.Text("", styleOdds);
    this.oddsNumberMain.anchor.set(0.5);
    this.oddsNumberMain.roundPixels = true;
    this.add(this.oddsNumberMain);

    const styleBoldSmall = new PIXI.TextStyle({
      fontFamily: "DIN-HeavyItalic",
      fontSize: _s(26),
      fill: "white",
      align: "center",
      fontStyle: "italic"
    });
    for (let i = 0; i < racerCount - 1; i++) {
      this.driverNumberCol1.push(new PIXI.Text("", styleBoldSmall));
      this.driverNumberCol1[i].anchor.set(0.5);
      this.driverNumberCol1[i].roundPixels = true;
      // this.add(this.driverNumberCol1[i]);
      this.driverNumberCol2.push(new PIXI.Text("", styleBoldSmall));
      this.driverNumberCol2[i].anchor.set(0.5);
      this.driverNumberCol2[i].roundPixels = true;
      // this.add(this.driverNumberCol2[i]);
      this.driverOdds.push(new PIXI.Text("", styleOdds));
      this.driverOdds[i].anchor.set(0.5);
      this.driverOdds[i].roundPixels = true;
      this.add(this.driverOdds[i]);
    }

    this.driverInfoBar = new DriverInfoBarKart();
    this.add(this.driverInfoBar);
  }

  public fill(drivers: IDriver[], odds: number[], withBonus: boolean) {
    this.drivers = drivers;
    this.odds = odds;
    this.anim = this.createAnim(this.gameLength, withBonus);
  }

  private createAnim(gameLength: GameLength, withBonus: boolean): ICombinedAnim | undefined {
    switch (gameLength) {
      case 120:
        return undefined;
      case 180:
        return {
          driver: withBonus ? { startTime: 60.0, duration: 3.8, pause: 0.1 } : { startTime: 70.0, duration: 3.8, pause: 0.1 },
          bar: withBonus ? { startTime: 60.3, duration: 3.35, pause: 0.68 } : { startTime: 70.4, duration: 3.34, pause: 0.67 }
        };
      case 240:
        return {
          driver: { startTime: 70.0, duration: 3.85, pause: 0.05 },
          bar: { startTime: 70.35, duration: 3.25, pause: 0.75 }
        };
      case 300:
        return {
          driver: withBonus ? { startTime: 69.8, duration: 3.75, pause: 0.2 } : { startTime: 79.9, duration: 3.94, pause: 0.0 },
          bar: withBonus ? { startTime: 70.5, duration: 3.1, pause: 0.85 } : { startTime: 80.6, duration: 2.94, pause: 1.0 }
        };
    }
  }

  public updateDriverTexts(index: number) {
    const odds = this.odds;
    const drivers = this.drivers;
    if (!drivers || !drivers[index]) {
      return false;
    }
    const driver = drivers[index];
    this.driverName.updateTexts([driver.firstName, driver.lastName]);

    const driverNumber = "" + (index + 1);
    this.driverNumberMain.text = driverNumber;
    this.driverNumberMain.tint = driver.color;

    const oddsMainDriver = Logic.getOddsForDriver(odds, index, index, drivers.length);
    this.oddsNumberMain.text = Logic.implementation.formatOdds(oddsMainDriver);

    for (let i = 0; i < this.racerCount - 1; i++) {
      this.driverNumberCol1[i].text = driverNumber;
      const otherDriverIndex = i + (index <= i ? 1 : 0);
      this.driverNumberCol2[i].text = "" + (otherDriverIndex + 1);
      this.driverNumberCol2[i].tint = drivers[otherDriverIndex].color;
      const oddsCurrentDriver = Logic.getOddsForDriver(odds, index, otherDriverIndex, drivers.length);
      this.driverOdds[i].text = Logic.implementation.formatOdds(oddsCurrentDriver);
    }

    return true;
  }

  public updateBarTexts(index: number) {
    const drivers = this.drivers;
    if (!drivers || !drivers[index]) {
      return false;
    }
    const driver = drivers[index];
    this.driverInfoBar.fill(driver);
    return true;
  }

  public onLayout() {
    this.driverName.position.x = _s(50);
    this.driverName.position.y = _s(8);

    this.driverNumberMain.position.x = _s(119);
    this.driverNumberMain.position.y = _s(64);

    this.oddsNumberMain.position.x = _s(315);
    this.oddsNumberMain.position.y = _s(65);

    for (let i = 0; i < this.racerCount - 1; i++) {
      this.driverNumberCol1[i].x = _s(84);
      this.driverNumberCol1[i].y = _s(103 + i * 40);
      this.driverNumberCol2[i].x = _s(152);
      this.driverNumberCol2[i].y = _s(103 + i * 40);
      this.driverOdds[i].x = _s(316);
      this.driverOdds[i].y = _s(104 + i * 40);
    }

    this.driverInfoBar.position.x = -this.position.x;
    this.driverInfoBar.position.y = -this.position.y + _s(655);
    this.driverInfoBar.width = settings.screen.width;
    this.driverInfoBar.height = _s(25);
  }

  private calcAnim(anim: Anim, t: number): [IAnimInterval, number] {
    const index = Util.clamp(Math.floor((t - anim.startTime) / (anim.duration + anim.pause)), 0, this.racerCount - 1);
    const resultAnim = {
      startTime: anim.startTime + index * (anim.duration + anim.pause),
      duration: anim.duration
    };
    return [resultAnim, index];
  }

  public update(dt: number) {
    super.update(dt);

    if (!this.anim) return;

    const t = Logic.getVideoTime();
    const [driverAnim, driverIndex] = this.calcAnim(this.anim.driver, t);
    const [barAnim, barIndex] = this.calcAnim(this.anim.bar, t);
    const dAnim = Logic.getAnim(t, [driverAnim], this);
    if (!dAnim) return;

    if (driverIndex !== this.currentDriverIndex) {
      if (this.updateDriverTexts(driverIndex)) this.currentDriverIndex = driverIndex;
    }

    if (barIndex !== this.currentBarIndex) {
      if (this.updateBarTexts(barIndex)) this.currentBarIndex = barIndex;
    }

    {
      const anim = dAnim;
      let baseFactor = t - anim.startTime;
      if (baseFactor < anim.duration - 0.4) {
        this.setDebugFade(baseFactor);
        this.driverName.alpha = baseFactor;
        this.driverName.animateText(baseFactor * 2 - 0.5);

        this.driverNumberMain.alpha = baseFactor - 0.5;
        this.oddsNumberMain.alpha = baseFactor - 0.6;
        for (let i = 0; i < this.racerCount - 1; i++) {
          this.driverNumberCol1[i].alpha = baseFactor - (0.5 + (i + 1) * 0.1);
          this.driverNumberCol2[i].alpha = baseFactor - (0.6 + (i + 1) * 0.1);
          this.driverOdds[i].alpha = baseFactor - (0.7 + (i + 1) * 0.1);
        }
      } else {
        baseFactor = (anim.duration - baseFactor) * 5;
        this.setDebugFade(baseFactor);
        this.driverName.alpha = baseFactor;
        this.driverName.animateText(baseFactor);

        this.driverNumberMain.alpha = baseFactor;
        this.oddsNumberMain.alpha = baseFactor;
        for (let i = 0; i < this.racerCount - 1; i++) {
          this.driverNumberCol1[i].alpha = baseFactor;
          this.driverNumberCol2[i].alpha = baseFactor;
          this.driverOdds[i].alpha = baseFactor;
        }
      }
    }
    {
      const anim = barAnim;
      let baseFactor = t - anim.startTime;
      if (baseFactor < anim.duration - 0.4) {
        this.driverInfoBar.setFadeFactor(baseFactor * 2);
      } else {
        baseFactor = (anim.duration - baseFactor) * 5;
        this.driverInfoBar.setFadeFactor(baseFactor);
      }
    }
  }
}
