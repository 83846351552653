import { IAnimInterval } from "../Logic/LogicDefinitions";

interface IPositions {
  x: number;
  y: number;
  width?: number;
}

interface ITrackPositionsGametype {
  trackName: IPositions;
  timesCircle: IPositions;
  turns: IPositions[];
  segments: IPositions[];
  lapMapFacts: IPositions[];
}

interface ITrackPositions {
  dog6: ITrackPositionsGametype;
  dog8: ITrackPositionsGametype;
  dog63: ITrackPositionsGametype;
  horse: ITrackPositionsGametype;
  sulky: ITrackPositionsGametype;
  oddsAlwaysOn: ITrackPositionsGametype;
}
interface IAnimsGametype {
  bottomResultBar: IAnimInterval;
  trackName: IAnimInterval[];
  lapInfo: IAnimInterval[];
  turns: IAnimInterval[];
  segments: IAnimInterval[];
  lapMapFacts: IAnimInterval[];
}
interface IElementTimings {
  dog6: IAnimsGametype;
  dog8: IAnimsGametype;
  dog63: IAnimsGametype;
  horse: IAnimsGametype;
  sulky: IAnimsGametype;
}

const TrackPresentationDefaultPositions: ITrackPositionsGametype = {
  trackName: { x: 0, y: 424 },
  timesCircle: { x: 288, y: 204 },
  turns: [
    { x: 435, y: 211 }, // Finish line
    { x: 340, y: 311 }, // Startbox 1
    { x: 172, y: 36 }, // Startbox 2
    { x: 122, y: 84 }, // Startbox 3
    { x: 277, y: 354 } // Startbox 4
  ],
  segments: [
    { x: 466, y: 153 },
    { x: 185, y: 53 },
    { x: 53, y: 259 },
    { x: 337, y: 348 },
    { x: 409, y: 262 }
  ],
  lapMapFacts: [
    { x: 455, y: 40, width: 145 },
    { x: 32, y: 78, width: 118 },
    { x: -32, y: 338, width: 140 },
    { x: 385, y: 291, width: 145 }
  ]
};

const TrackPresentationDefaultAnimTimings: IAnimsGametype = {
  bottomResultBar: { startTime: 0, duration: 426 },
  trackName: [
    { startTime: 0.0, duration: 4.62 }, // trackname
    { startTime: 5.16, duration: 5.1 }, // trackname
    { startTime: 10.75, duration: 7.2 } // trackname
  ],
  lapInfo: [
    //? Lapinfo top right
    { startTime: 5.02, duration: 2.3 },
    { startTime: 7.7, duration: 2.35 },
    { startTime: 10.6, duration: 3.05 },
    { startTime: 13.8, duration: 3.65 }
  ],
  turns: [
    { startTime: 2.15, duration: 6 }, // Finish Line
    { startTime: 0.52, duration: 6 }, // Startbox 1
    { startTime: 0.72, duration: 6 }, // Startbox 2
    { startTime: 1.28, duration: 6 }, // Startbox 3
    { startTime: 1.58, duration: 6 } // Startbox 4
  ],
  segments: [
    { startTime: 5.99, duration: 10 }, // 100m
    { startTime: 6.46, duration: 10, fromRight: true },
    { startTime: 6.88, duration: 10, fromRight: true },
    { startTime: 7.3, duration: 10 },
    { startTime: 7.82, duration: 10 }
  ],
  lapMapFacts: [
    { startTime: 13.85, duration: 3.6 }, // Weather
    { startTime: 14.65, duration: 3.35 }, // Temp
    { startTime: 15.25, duration: 2.65 }, // Humidity
    { startTime: 15.8, duration: 1.7 } // Wind
  ]
};

const TrackPresentationDog6AnimTimings: IAnimsGametype = {
  bottomResultBar: TrackPresentationDefaultAnimTimings.bottomResultBar,
  trackName: TrackPresentationDefaultAnimTimings.trackName,
  lapInfo: TrackPresentationDefaultAnimTimings.lapInfo,
  turns: TrackPresentationDefaultAnimTimings.turns,
  segments: [
    { startTime: 5.99, duration: 10 }, // 100m
    { startTime: 6.46, duration: 10, fromRight: true },
    { startTime: 6.88, duration: 10, fromRight: true },
    { startTime: 7.3, duration: 10 }
  ],
  lapMapFacts: TrackPresentationDefaultAnimTimings.lapMapFacts
};

export const TrackPresentationPositions: ITrackPositions = {
  dog6: TrackPresentationDefaultPositions,
  dog8: TrackPresentationDefaultPositions,
  dog63: TrackPresentationDefaultPositions,
  horse: {
    trackName: { x: 0, y: 426 },
    timesCircle: { x: 259, y: 246 },
    turns: [
      { x: 287, y: 52 }, // Finish line
      { x: 425, y: 141 }, // Startbox 1
      { x: 370, y: 204 }, // Startbox 2
      { x: 328, y: 248 }, // Startbox 3
      { x: 454, y: 80 } // Startbox 4
    ],
    segments: [
      { x: 301, y: 34 },
      { x: 464, y: 120 },
      { x: 308, y: 302 },
      { x: 148, y: 202 }
    ],
    lapMapFacts: [
      { x: 466, y: 98, width: 145 },
      { x: 233, y: 52, width: 118 },
      { x: 58, y: 212, width: 140 },
      { x: 312, y: 260, width: 145 }
    ]
  },
  sulky: {
    trackName: { x: 0, y: 426 },
    timesCircle: { x: 260, y: 247 },
    turns: [
      { x: 230, y: 112 }, // Finish line
      { x: 435, y: 142 }, // Startbox 1
      { x: 410, y: 185 }, // Startbox 2
      { x: 365, y: 225 } // Startbox 3
    ],
    segments: [
      { x: 450, y: 127 }, // 150m
      { x: 308, y: 59 }, // 400m
      { x: 246, y: 125 }, // 850m
      { x: 386, y: 198 } // 1200m
    ],
    lapMapFacts: [
      { x: 456, y: 110, width: 145 }, // Weather
      { x: 232, y: 52, width: 118 }, // Temp
      { x: 137, y: 127, width: 140 }, // Humidity
      { x: 391, y: 185, width: 145 } // Wind
    ]
  },
  oddsAlwaysOn: {
    trackName: { x: 0, y: 332 },
    timesCircle: { x: 201, y: 180 },
    turns: [
      { x: 305, y: 183 }, // Finish line
      { x: 240, y: 254 }, // Startbox 1
      { x: 123, y: 60 }, // Startbox 2
      { x: 88, y: 95 }, // Startbox 3
      { x: 196, y: 284 } // Startbox 4
    ],
    segments: [
      { x: 323, y: 135 },
      { x: 126, y: 67 },
      { x: 34, y: 210 },
      { x: 232, y: 272 },
      { x: 409, y: 262 }
    ],
    lapMapFacts: [
      { x: 321, y: 59, width: 100 }, // Weather
      { x: 20, y: 84, width: 120 }, // Temp
      { x: -38, y: 267, width: 140 }, // Humidity
      { x: 272, y: 233, width: 110 } // Wind
    ]
  }
};

export const TrackPresentationAnimTimings: IElementTimings = {
  dog6: TrackPresentationDog6AnimTimings,
  dog8: TrackPresentationDefaultAnimTimings,
  dog63: TrackPresentationDefaultAnimTimings,
  horse: {
    bottomResultBar: { startTime: 0, duration: 18 },
    trackName: [
      { startTime: 0.25, duration: 3.39 }, // trackname
      { startTime: 4.56, duration: 4.3 }, // trackname
      { startTime: 9.9, duration: 7.4 } // trackname
    ],
    lapInfo: [
      //? Lapinfo top right
      { startTime: 4.4, duration: 2.45 },
      { startTime: 7.2, duration: 1.8 },
      { startTime: 9.7, duration: 3.15 },
      { startTime: 13.1, duration: 3.75 }
    ],
    turns: [
      { startTime: 2.1, duration: 6 }, // Finish Line
      { startTime: 0.6, duration: 6 }, // Startbox 1
      { startTime: 0.9, duration: 6 }, // Startbox 2
      { startTime: 1.28, duration: 6 }, // Startbox 3
      { startTime: 1.58, duration: 6 } // Startbox 4
    ],
    segments: [
      { startTime: 5.7, duration: 10 }, // 100m
      { startTime: 6.1, duration: 10 }, // 400m
      { startTime: 6.99, duration: 10 }, // 850m
      { startTime: 7.4, duration: 10 } // 1200m
    ],
    lapMapFacts: [
      { startTime: 14.0, duration: 3.6 }, // Weather
      { startTime: 13.6, duration: 3.8 }, // Temp
      { startTime: 15.2, duration: 2.2 }, // Humidity
      { startTime: 14.7, duration: 1.7 } // Wind
    ]
  },
  sulky: {
    bottomResultBar: { startTime: 0, duration: 18 },
    trackName: [
      { startTime: 0.25, duration: 3.39 }, // trackname
      { startTime: 4.56, duration: 4.3 }, // trackname
      { startTime: 9.9, duration: 7.4 } // trackname
    ],
    lapInfo: [
      //? Lapinfo top right
      { startTime: 4.4, duration: 2.45 },
      { startTime: 7.2, duration: 1.8 },
      { startTime: 9.7, duration: 3.15 },
      { startTime: 13.1, duration: 3.75 }
    ],
    turns: [
      { startTime: 2.1, duration: 6 }, // Finish Line
      { startTime: 0.6, duration: 6 }, // Startbox 1
      { startTime: 0.9, duration: 6 }, // Startbox 2
      { startTime: 1.28, duration: 6 }, // Startbox 3
      { startTime: 1.58, duration: 6 } // Startbox 4
    ],
    segments: [
      { startTime: 5.4, duration: 10 }, // 100m
      { startTime: 6.2, duration: 10, fromRight: true }, // 400m
      { startTime: 6.7, duration: 10, fromRight: true }, // 850m
      { startTime: 7.4, duration: 10 } // 1200m
    ],
    lapMapFacts: [
      { startTime: 13.12, duration: 3.2 }, // Weather
      { startTime: 13.8, duration: 2.79, fromRight: true }, // Temp
      { startTime: 14.3, duration: 2.58, fromRight: true }, // Humidity
      { startTime: 14.9, duration: 2.3 } // Wind
    ]
  }
};
