import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import { RtcLogic } from "../Rtc/RtcLogic";
import { Logic } from "../Logic/Logic";
import { asComponent } from "./ReactHelper";
import { dummyModelKart5 } from "../LogicImplementationDummy/DummyModelKart5";

// loadFonts.fontFamily
const useStyles = makeStyles({
  outer: {
    margin: "10px"
  },
  slider: {
    height: "48px",
    "& > div": {
      margin: "0px 0px 0px -24px"
    }
  },
  button: {
    margin: "20px 5px"
  },
  sliderBar: {
    display: "flex",
    flexDirection: "row",
    "& p": {
      margin: "10px",
      flex: "0 0 auto"
    }
  }
});

interface IProps { }

export const ReactProducerTools = asComponent("ReactProducerTools", (props: IProps) => {
  const styles = useStyles();

  const devTools = Logic.getProducerTools();
  const videoTime = devTools.getObservableTime();
  const handleSpace = (e: any) => {
    if (e.key === " ") {
      Logic.toggleVideoPlay();
    }
  };

  setInterval(() => {
    console.log("disabling pause overlay");
    Logic.showPauseOverlay(false);
  }, 2000);

  return (
    <div className={styles.outer}>
      <div className={styles.sliderBar}>
        <Slider
          tabIndex={1}
          onKeyDown={handleSpace}
          className={styles.slider}
          max={Logic.getRaceEndTime()}
          value={videoTime}
          onChange={(ev: any, val: any) => {
            devTools.setVideoTime(val);
          }}
        />
        <p>{videoTime.toFixed(2)}</p>
      </div>
      <Button
        variant="contained"
        color="primary"
        className={styles.button}
        onClick={() => {
          Logic.toggleVideoPlay();
        }}
      >
        {Logic.isVideoPlaying() ? "Stop" : "Play"}
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={styles.button}
        onClick={() => {
          devTools.setVideoTime(Math.max(videoTime - 0.0333333, 0.0));
        }}
      >
        Prev
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={styles.button}
        onClick={() => {
          devTools.setVideoTime(Math.max(videoTime + 0.0333333, 0.0));
        }}
      >
        Next
      </Button>

      <Button
        variant="contained"
        color="primary"
        className={styles.button}
        onClick={() => {
          devTools.triggerFade();
        }}
      >
        Trigger Fade
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={styles.button}
        onClick={() => {
          devTools.looseContext();
        }}
      >
        Loose Context
      </Button>
      {RtcLogic.instance.isProducer() && (
        <Button
          variant="contained"
          color="primary"
          className={styles.button}
          onClick={() => {
            Logic.publish();
          }}
        >
          Publish
        </Button>
      )}
      <Button variant="contained" color="primary" className={styles.button} onClick={() => Logic.showPauseOverlay("intro", { bottomText: "Intro", pauseEndTimeText: "CANCELLED dsfasdf " })}>
        Request PauseOverlay Intro
      </Button>
      <Button variant="contained" color="primary" className={styles.button} onClick={() => Logic.showPauseOverlay("race", { bottomText: "Race", pauseEndTimeText: "CANCELLED dsfasdf " })}>
        Request PauseOverlay Race
      </Button>
      <Button variant="contained" color="primary" className={styles.button} onClick={() => Logic.showPauseOverlay("immediately", { bottomText: "PROSSIMA GARA", pauseEndTimeText: "12:00" })}>
        FadeTo PauseOverlay
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={styles.button}
        onClick={() =>
          Logic.showPauseOverlay("immediately", {
            bottomText: "Race was cancelled",
            pauseEndTimeText: "CANCELLED",
            nextRaceTime: new Date(Date.now() + 10000),
            nextRound: dummyModelKart5.roundInfo
          })
        }
      >
        FadeTo NoResultOverlay
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={styles.button}
        onClick={() =>
          Logic.showPauseOverlay("immediately", {
            bottomText: "Race was cancelled",
            pauseEndTimeText: "CANCELLED",
            nextRaceTime: new Date(Date.now() + 10000)
          })
        }
      >
        FadeTo NoResultOverlay2
      </Button>
      <Button variant="contained" color="primary" className={styles.button} onClick={() => Logic.showPauseOverlay(false)}>
        Remove PauseOverlay
      </Button>
    </div>
  );
});
