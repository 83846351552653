import { RouletteboardOddArea } from "./RoulettteboardOddArea";
import { AnimHelper } from "./../common/Anim";
import { IAnimInterval } from "./../../Logic/LogicDefinitions";
import { settings } from "../../Logic/Logic";
import { Group } from "../../Graphics/Group";
import { Logic, _s } from "../../Logic/Logic";
import { IGameInfo, IRoundInfo } from "../../Logic/LogicDefinitions";
import * as PIXI from "pixi.js";
import rouletteGridTexture from "../../assets/c4/roulette/RouletteGrid.png";
import rouletteGridBackgroundTexture from "../../assets/c4/roulette/RouletteGridBackground.png";

export class RouletteBoard extends Group {
  private rouletteBoard: PIXI.Sprite = new PIXI.Sprite();
  private rouletteBoardBackground: PIXI.Sprite = new PIXI.Sprite();
  private rouletteBoardOddArea: RouletteboardOddArea;
  private gameInfo: IGameInfo;
  private anims: IAnimInterval[] = [{ startTime: 0.0, duration: 0.0 }];

  /**
   * basic container for the rouletteboard
   * contains background, rouletteboard
   * RouletteBoardOddArea: all odds and areas
   * @param {IGameInfo} gameInfo standart gameinfo, will be passed always
   */
  public constructor(gameInfo: IGameInfo) {
    super();
    this.gameInfo = gameInfo;

    this.rouletteBoardOddArea = new RouletteboardOddArea(gameInfo);

    this.rouletteBoard.anchor.set(0.5, 0.5);
    this.rouletteBoardBackground.anchor.set(0.5, 0.5);

    this.add(this.rouletteBoardBackground);
    this.add(this.rouletteBoard);
    this.add(this.rouletteBoardOddArea);
  }

  public onLayout(): void {
    this.rouletteBoardOddArea.onLayout();

    const positionY = _s(420);
    const positionX = _s(764);
    const height = _s(400);
    const width = _s(900);

    // Adjust positions
    this.rouletteBoard.position.y = positionY;
    this.rouletteBoard.position.x = positionX;
    this.rouletteBoard.width = width;
    this.rouletteBoard.height = height;

    this.rouletteBoardBackground.position.y = positionY;
    this.rouletteBoardBackground.position.x = positionX;
    this.rouletteBoardBackground.width = width * 1.029;
    this.rouletteBoardBackground.height = height * 1.05;

    if (settings.debug) {
      this.rouletteBoard.tint = 0x000;
      this.rouletteBoard.alpha = 0.6;

      this.rouletteBoardBackground.tint = 0x000;
      this.rouletteBoardBackground.alpha = 0.6;
    }
  }

  public async init(): Promise<void> {
    await this.rouletteBoardOddArea.init();
    const rouletteBoardTexture = await Logic.loadTexture(rouletteGridTexture);
    const rouletteBoardBackgroundTexture = await Logic.loadTexture(rouletteGridBackgroundTexture);
    this.rouletteBoard.texture = rouletteBoardTexture;
    this.rouletteBoardBackground.texture = rouletteBoardBackgroundTexture;
  }

  public fill(roundInfo: IRoundInfo): void {
    this.anims = this.createAnims();
    this.rouletteBoardOddArea.fill(roundInfo);
  }

  public createAnims(): IAnimInterval[] {
    const result = [{ startTime: 141.5, duration: Logic.getIntroLength() - 142 }];
    return result;
  }

  public update(dt: number): void {
    super.update(dt);
    const currentTime = Logic.getVideoTime();

    const anim = Logic.getAnim(currentTime, this.anims, this);

    if (!anim) {
      this.visible = false;
      return;
    }
    this.visible = true;
    const baseFactor = currentTime - anim.startTime;
    AnimHelper.animateInOut(baseFactor, 0, anim.duration, 0.9, 0, _s(0.58593), (val) => (this.rouletteBoard.scale.x = val), 0, 0);
    AnimHelper.animateInOut(baseFactor, 0, anim.duration, 0.9, 0, _s(0.58737), (val) => (this.rouletteBoard.scale.y = val), 0, 0);
    AnimHelper.animateInOut(baseFactor, 0, anim.duration, 0.9, 0, _s(0.584955), (val) => (this.rouletteBoardBackground.scale.y = val), 0, 0);
    AnimHelper.animateInOut(baseFactor, 0, anim.duration, 0.9, 0, _s(0.586135), (val) => (this.rouletteBoardBackground.scale.x = val), 0, 0);
  }
}
