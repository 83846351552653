import { IColors, RouletteHelper } from "./RouletteHelper";
import { AnimHelper } from "./../common/Anim";
import { IAnimInterval } from "./../../Logic/LogicDefinitions";
import { settings } from "../../Logic/Logic";
import { Group } from "../../Graphics/Group";
import { Logic, _s } from "../../Logic/Logic";
import { IGameInfo, IRoundInfo } from "../../Logic/LogicDefinitions";
import * as PIXI from "pixi.js";

export class StatOddNumber extends Group {
  public number: number;
  private numberSprite: PIXI.Sprite = new PIXI.Sprite();
  private numberTexture: PIXI.Texture | undefined;
  private winnerNumber: PIXI.Text = Logic.createPixiText();
  private oddNumber: PIXI.Text = Logic.createPixiText();
  private oddBar: PIXI.Graphics = new PIXI.Graphics();
  private isBig: boolean;
  private showNumber: boolean;
  private gameInfo: IGameInfo;
  private anims: IAnimInterval[] = [{ startTime: 0.0, duration: 0.0 }];
  private winnerColor: string | undefined;

  /**
   *
   * @param gameInfo
   * @param number
   * @param showStatBar
   * @param showNumber
   * @param big
   */
  public constructor(gameInfo: IGameInfo, number: number, showStatBar: boolean = true, showNumber: boolean = true, big: boolean = false) {
    super();
    this.gameInfo = gameInfo;
    this.isBig = big;
    this.showNumber = showNumber;

    this.numberSprite.anchor.set(0.5, 0.5);

    this.number = number;
    this.winnerColor = RouletteHelper.getColorForNumber(this.number);

    this.oddBar.beginFill(0xaaaaaa);
    this.oddBar.drawRoundedRect(0, 0, 1, _s(13), _s(1));
    this.oddBar.endFill();

    // Add text
    this.winnerNumber.anchor.set(0.5, 0.5);
    this.oddNumber.anchor.set(0.5, 0.5);

    this.winnerNumber.style = this.isBig ? RouletteHelper.bigNumberTextStyle : RouletteHelper.smallNumberTextStyle;
    this.oddNumber.style = RouletteHelper.smallOddTextStyle;

    if (showStatBar) {
      this.add(this.oddNumber);
      this.add(this.oddBar);
    }
    this.add(this.numberSprite);
    if (this.showNumber) this.add(this.winnerNumber);
  }

  public onLayout(): void {
    const height = this.isBig ? 45 : 32;
    const width = this.isBig ? 45 : 32;
    // Add sprite shapes
    this.numberSprite.width = _s(width);
    this.numberSprite.height = _s(height);

    if (settings.debug) {
      this.numberSprite.tint = 0x000;
      this.numberSprite.alpha = 0.6;
    }
  }

  public fill(roundInfo: IRoundInfo, oddNumber?: number, updatedNumber?: number): void {
    if (updatedNumber !== undefined) {
      this.number = updatedNumber;
      this.winnerColor = RouletteHelper.getColorForNumber(this.number);
    }

    this.updateColor();
    if (this.numberTexture) this.numberTexture.updateUvs();

    if (this.oddBar !== undefined && oddNumber !== undefined) {
      const maxOdd = this.isBig ? 200 : 30;
      const maxWidth = this.isBig ? _s(120) : _s(163);
      const width = (maxWidth / maxOdd) * oddNumber;
      this.oddBar.width = width;
      this.oddBar.position.x = _s(26);
      this.oddBar.position.y = -_s(11 / 2);
      this.oddNumber.position.x = this.isBig ? _s(45) + width : _s(40) + width;
      this.oddNumber.text = oddNumber.toString();
    }
    this.winnerNumber.text = this.number.toString();
  }

  private updateColor(): void {
    if (this.winnerColor === "red" && this.numberTexture) this.numberTexture.frame.y = 0;
    else if (this.winnerColor === "black" && this.numberTexture) this.numberTexture.frame.y = 58;
    else if (this.winnerColor === "green" && this.numberTexture) this.numberTexture.frame.y = 116;
  }

  public init(texture: PIXI.Texture): void {
    this.numberTexture = texture;
    this.numberTexture.frame = new PIXI.Rectangle(0, 0, 58, 58);
    this.numberSprite.texture = this.numberTexture;
  }

  public update(dt: number): void {
    super.update(dt);
  }
}
