import { mainElementPositionSizes, oddsAlwaysOnStyles, oddsAlwaysOnTimings, oddScreenSettings } from "../../settings/OddsAlwaysOnSettings";
import * as PIXI from "pixi.js";
import { Group } from "../../Graphics/Group";
import { Logic, _s, settings, _t } from "../../Logic/Logic";
import { IColors, IDriver, IAnimInterval } from "../../Logic/LogicDefinitions";
import { AnimHelper } from "../common/Anim";
import { GameType, GameLength } from "../../common/Definitions";
import { Dog63Helper } from "../dog63/Dog63Helper";
import { DogHelper } from "./DogHelper";

export class OddsScreenDog extends Group {
  private oddsHeaderVicente: PIXI.Text;
  private oddsHeaderAccopiata: PIXI.Text;
  private oddsHeaderVicenteAnims: IAnimInterval[] = [];
  private oddsHeaderAccopiataAnims: IAnimInterval[] = [];
  private oddsTexts: PIXI.Text[] = [];
  private racers: IDriver[] = [];
  private first: PIXI.Text;
  private second: PIXI.Text;
  private gameType: GameType;
  private gameLength: GameLength;
  private racerCount: number;
  private language: string;
  private oddsAlwaysOn: boolean;
  private textContainer = new Group();

  // create texts, pixi objects and so on in constructor => if possible
  public constructor(gameType: GameType, gameLength: GameLength, language: string, oddsAlwaysOn = false) {
    super();
    this.gameType = gameType;
    this.gameLength = gameLength;
    this.language = language;
    const racerCount = Logic.getRacerCount(gameType);
    this.racerCount = racerCount;
    this.oddsAlwaysOn = oddsAlwaysOn;
    this.showDebug(settings.debug, 0.7, "OddScreenDog");

    const isDog8 = gameType === "dog8";
    const isHorse = gameType === "horse" || gameType === "sulky";
    let rowStartX = isDog8 ? 95 : 153;
    let rowStartY = isDog8 ? 58 : 68;
    let rowHeight = isDog8 ? 41.5 : isHorse ? 47.5 : 48;
    let columnWidth = isDog8 ? 83 : 95;

    if (this.oddsAlwaysOn) {
      const { rowStartX: startX, rowStartY: startY, rowHeight: rHeight, columnWidth: colWidth } = oddScreenSettings[this.gameType as keyof typeof oddScreenSettings];
      rowStartX = startX;
      rowStartY = startY;
      rowHeight = rHeight;
      columnWidth = colWidth;
    }

    {
      for (let iRow = 0; iRow < racerCount; iRow++) {
        for (let iCol = 0; iCol < racerCount; iCol++) {
          const text = Logic.createPixiText();
          text.position.x = _s(rowStartX + iCol * columnWidth);
          text.position.y = _s(rowStartY + iRow * rowHeight);
          text.anchor.set(0.5);
          this.oddsTexts.push(text);
          this.textContainer.add(text);
        }
      }
      this.add(this.textContainer);
      this.textContainer.container.pivot.set(_s(467.2), _s(230.4));
      this.textContainer.container.position.set(_s(467.2), _s(230.4));
    }

    {
      const style = new PIXI.TextStyle({
        fontFamily: "DIN-Bold",
        fontSize: _s(10),
        letterSpacing: _s(0),
        fill: DogHelper.getBlackColor(),
        align: "center"
      });

      if (this.oddsAlwaysOn) {
        style.fontSize = _s(oddsAlwaysOnStyles[this.gameType as keyof typeof oddsAlwaysOnStyles].oddsScreen.oddsHeaderVincente.fontSize);
      }

      this.oddsHeaderVicente = Logic.createPixiText(style);
      this.oddsHeaderVicente.anchor.set(0.5, 1.0);

      this.oddsHeaderAccopiata = Logic.createPixiText(style);
      this.oddsHeaderAccopiata.anchor.set(0.5, 1.0);

      if (language === "it") {
        this.add(this.oddsHeaderVicente);
        this.add(this.oddsHeaderAccopiata);
      }
    }
    {
      const style = new PIXI.TextStyle({
        fontFamily: isHorse ? "DIN-Bold" : "DIN-Light",
        fontSize: isHorse ? _s(10) : _s(8),
        letterSpacing: _s(2),
        fill: DogHelper.getBlackColor(),
        align: "center"
      });
      if (this.oddsAlwaysOn) {
        const { fontSize, letterSpacing } = oddsAlwaysOnStyles[this.gameType as keyof typeof oddsAlwaysOnStyles].oddsScreen.firstSecond;
        style.fontSize = _s(fontSize);
        style.letterSpacing = _s(letterSpacing);
      }

      this.first = Logic.createPixiText(style);
      this.first.anchor.set(0.5, 0.45);
      this.add(this.first);

      this.second = Logic.createPixiText(style);
      this.second.anchor.set(0.5, 0.4);
      this.add(this.second);
    }
  }

  public createOddsHeaderAnims(gameType: GameType, gameLength: GameLength, language: string): IAnimInterval[][] {
    if (language !== "it") return [];
    if (this.oddsAlwaysOn) {
      return oddsAlwaysOnTimings[this.gameType as keyof typeof oddsAlwaysOnTimings].itHeader;
    }
    if (gameType === "dog6") {
      switch (gameLength) {
        case 300:
          return [
            [
              { startTime: 10.3, duration: 3.7 },
              { startTime: 100.6, duration: 4.45 },
              { startTime: 155.8, duration: 5.8 }
            ],
            [
              { startTime: 14.65, duration: 2.2 },
              { startTime: 105.6, duration: 2.6 },
              { startTime: 162.3, duration: 3.4 }
            ]
          ];
        case 240:
          return [
            [
              { startTime: 10.3, duration: 3.0 },
              { startTime: 90.7, duration: 4.3 },
              { startTime: 140.8, duration: 5.7 }
            ],
            [
              { startTime: 13.7, duration: 1.7 },
              { startTime: 95.57, duration: 2.6 },
              { startTime: 147.25, duration: 3.4 }
            ]
          ];
      }
    } else if (gameType === "dog8") {
      switch (gameLength) {
        case 300:
          return [
            [
              { startTime: 10.1, duration: 4.35 },
              { startTime: 110.15, duration: 4.55 },
              { startTime: 160.4, duration: 5.9 }
            ],
            [
              { startTime: 14.85, duration: 1.9 },
              { startTime: 114.8, duration: 1.9 },
              { startTime: 166.6, duration: 2.6 }
            ]
          ];
        case 240:
          return [
            [
              { startTime: 10, duration: 3.5 },
              { startTime: 100, duration: 4.6 },
              { startTime: 145.3, duration: 5.9 }
            ],
            [
              { startTime: 13.9, duration: 1.5 },
              { startTime: 104.8, duration: 1.8 },
              { startTime: 151.5, duration: 2.5 }
            ]
          ];
      }
    }
    return [];
  }

  public createAnims(gameType: GameType, gameLength: GameLength, withBonus: boolean, language: string): OddsScreenDog["anims"] {
    if (this.oddsAlwaysOn) {
      if (language !== "it") language = "default";
      const oddsScreenTimings = oddsAlwaysOnTimings[gameType as keyof typeof oddsAlwaysOnTimings].oddsScreen;

      return oddsScreenTimings[language as keyof typeof oddsScreenTimings];
    }
    if (gameType === "horse") {
      switch (gameLength) {
        case 320:
          return [
            { startTime: 1.3, duration: 24.8, fadeInFactor: 0.75 },
            { startTime: 101.7, duration: 18.3, fadeInFactor: 0.75 },
            withBonus ? { startTime: 161.0, duration: 15.3, fadeInFactor: 0.75 } : { startTime: 156.3, duration: 19.7, fadeInFactor: 0.75 }
          ];
      }
    } else if (gameType === "sulky") {
      return [
        { startTime: 1.3, duration: 24.8, fadeInFactor: 0.75 },
        { startTime: 101.7, duration: 18.3, fadeInFactor: 0.75 },
        withBonus ? { startTime: 161.0, duration: 15.3, fadeInFactor: 0.75 } : { startTime: 156.3, duration: 19.7, fadeInFactor: 0.75 }
      ];
    } else if (gameType === "dog6") {
      switch (gameLength) {
        case 120:
          return [
            { startTime: 0.3, duration: 13.2, fadeInFactor: 0.75 },
            withBonus ? { startTime: 35.6, duration: 21.5, fadeInFactor: 0.75 } : { startTime: 30.7, duration: 26.2, fadeInFactor: 0.75 }
          ];
        case 180:
          return [
            withBonus ? { startTime: 0.8, duration: 17.5, fadeInFactor: 0.75 } : { startTime: 1.05, duration: 22.5, fadeInFactor: 0.75 },
            withBonus ? { startTime: 90.8, duration: 26.0 } : { startTime: 90.8, duration: 26.2 }
          ];
        case 240:
          return [
            language === "it" ? { startTime: 10.2, duration: 18.4 } : { startTime: 1.1, duration: 27.0 },
            { startTime: 91.0, duration: 26.5 },
            withBonus ? { startTime: 150.2, duration: 27.3, fadeOutFactor: 0.7 } : { startTime: 140.8, duration: 36.2 }
          ];
        case 300:
          return [
            withBonus
              ? { startTime: 1.1, duration: withBonus ? 27.0 : 31.7 }
              : language === "it"
                ? { startTime: 10.3, duration: withBonus ? 27.0 : 23.5, fadeOutFactor: 0.5 }
                : { startTime: 1.3, duration: withBonus ? 27.0 : 31.7 },
            withBonus ? { startTime: 90.9, duration: 27.1 } : { startTime: 101.0, duration: 26.5 },
            { startTime: 156.3, duration: 35.3 }
          ];
        default: {
          return [{ startTime: 4.5, duration: 24.0 }, { startTime: 112.7, duration: 24.5 }, withBonus ? { startTime: 161, duration: 15.5 } : { startTime: 146, duration: 15.5 }];
        }
      }
    }
    // dog8
    switch (gameLength) {
      case 120:
        return [
          { startTime: 1.1, duration: withBonus ? 12.0 : 11.8, fadeInFactor: 0.55 },
          withBonus ? { startTime: 35.5, duration: 21.5, fadeInFactor: 0.75 } : { startTime: 30.5, duration: 26.5, fadeInFactor: 0.75 }
        ];
      case 180:
        return [
          { startTime: withBonus ? 1.1 : 1.2, duration: withBonus ? 17.0 : 21.7, fadeInFactor: 0.7 },
          { startTime: 91.0, duration: 26.0 } // maybe with bonus??
        ];
      case 240:
        return [
          language === "it" ? { startTime: 10.2, duration: 18.7, fadeOutFactor: 1.3 } : { startTime: 1.2, duration: 26.5, fadeOutFactor: 1.3 },
          { startTime: 100.8, duration: 22.2 }, // maybe with bonus??
          withBonus ? { startTime: 155.5, duration: 21.3 } : { startTime: 146.2, duration: 30.0, fadeInFactor: 1.1 }
        ];
      case 300:
        return [
          language === "it" ? { startTime: withBonus ? 1.1 : 10.3, duration: withBonus ? 27.0 : 23.0 } : { startTime: withBonus ? 1.1 : 1.3, duration: withBonus ? 27.0 : 31.2 },
          withBonus ? { startTime: 100.8, duration: 22.3 } : { startTime: 110.8, duration: 22.2 }, // maybe with bonus??
          { startTime: 161.0, duration: 31.0, fadeInFactor: 1.2 }
        ];
      default: {
        return [
          { startTime: 4.8, duration: 31.0 },
          withBonus
            ? { startTime: 157.5, duration: 19.0 } // maybe with bonus??
            : { startTime: 152.7, duration: 21.0 }
        ];
      }
    }
  }

  // fill texts with infos from model
  public fill(racers: IDriver[], odds: number[], colors: IColors, withBonus: boolean) {
    this.racers = racers;
    const style = new PIXI.TextStyle({
      fontFamily: "DIN-Regular",
      fontSize: _s(22),
      fill: DogHelper.getWhiteColor(), // "white",
      align: "center"
    });
    const styleBold = new PIXI.TextStyle({
      fontFamily: "DIN-Heavy",
      fontSize: _s(22),
      fill: DogHelper.getWhiteColor(), // "white",
      align: "center"
    });

    if (this.oddsAlwaysOn) {
      const { fontSize, fontFamily } = oddsAlwaysOnStyles[this.gameType as keyof typeof oddsAlwaysOnStyles].oddsScreen.odds;
      style.fontSize = _s(fontSize);
      styleBold.fontSize = _s(fontSize);
      styleBold.fontFamily = fontFamily;
    }
    const minMax = Logic.calcOddsMinMax(odds, racers.length);

    for (let iRow = 0; iRow < this.racerCount; iRow++) {
      for (let iCol = 0; iCol < this.racerCount; iCol++) {
        const val = Logic.getOddsForDriver(odds, iRow, iCol, this.racerCount);
        const oddsColor = Logic.getOddsColor(minMax, val, iRow, iCol);
        const text = this.oddsTexts[iCol + iRow * this.racerCount];
        text.text = Logic.implementation.formatOdds(val);
        text.style = oddsColor !== "white" ? styleBold : style;
        text.tint = colors[oddsColor];
      }
    }

    this.oddsHeaderVicente.text = _t("winner");
    this.oddsHeaderAccopiata.text = _t("forcastBet");
    this.first.text = _t("first").replace("_", "");
    this.second.text = _t("second").replace("_", "");

    this.anims = this.createAnims(this.gameType, this.gameLength, withBonus, this.language);
    const headerAnims = this.createOddsHeaderAnims(this.gameType, this.gameLength, this.language);
    if (headerAnims.length > 1) {
      this.oddsHeaderVicenteAnims = headerAnims[0];
      this.oddsHeaderAccopiataAnims = headerAnims[1];
    }
  }

  // set positions and sizes when layout changes
  public onLayout() {
    const isDog8 = this.gameType === "dog8";
    const isHorse = this.gameType === "horse" || this.gameType === "sulky";

    let oddsHeaderX = isDog8 ? 113 : 167;
    let oddsHeaderY = -3;
    let firstX = isDog8 ? -6 : 36;
    let firstY = isDog8 ? 333 : isHorse ? 338 : 293;
    let secondY = isHorse ? -6 : -4;
    let secondX = isDog8 ? 682 : isHorse ? 732 : 632;

    if (this.oddsAlwaysOn) {
      const { x: xFirst, y: yFirst } = oddScreenSettings[this.gameType as keyof typeof oddScreenSettings].first;
      const { x: xSecond, y: ySecond } = oddScreenSettings[this.gameType as keyof typeof oddScreenSettings].second;

      const { x: xHeader, y: yHeader } = oddScreenSettings[this.gameType as keyof typeof oddScreenSettings].itHeader;

      oddsHeaderX = xHeader;
      oddsHeaderY = yHeader;

      firstX = xFirst;
      firstY = yFirst;

      secondX = xSecond;
      secondY = ySecond;

      this.oddsHeaderVicente.anchor.set(0, 0.5);
      this.oddsHeaderAccopiata.anchor.set(0, 0.5);
    }

    this.oddsHeaderVicente.position.x = _s(oddsHeaderX);
    this.oddsHeaderVicente.position.y = _s(oddsHeaderY);
    this.oddsHeaderAccopiata.position.x = this.oddsHeaderVicente.position.x;
    this.oddsHeaderAccopiata.position.y = this.oddsHeaderVicente.position.y;

    this.first.position.x = _s(firstX);
    this.first.rotation = -Math.PI * 0.5;
    this.first.position.y = _s(firstY);

    this.second.position.x = _s(secondX);
    this.second.position.y = _s(secondY);
  }

  // the startTime and duration of the appearance -> can be more than one
  private anims: (IAnimInterval & {
    fadeInFactor?: number;
    fadeOutFactor?: number;
    initAnimation?: boolean;
    startSm?: boolean;
    subAnimations?: (IAnimInterval & { fadeInFactor?: number; fadeOutFactor?: number; smoothness?: { in: number; out: number } })[];
  })[] = []; // = createEmptyDogAnims();

  // use update for fading, animations and so on...
  public update(dt: number) {
    super.update(dt);

    // get animation if there is one for current videotime...
    const t = Logic.getVideoTime();
    const anim = Logic.getAnim(t, this.anims, this);
    if (!anim) return;
    const fadeInFactor = anim.fadeInFactor ? anim.fadeInFactor : 1;
    const fadeOutFactor = anim.fadeOutFactor ? anim.fadeOutFactor : 1;

    // set positions, alpha values and so on based on animation time = t - anim.startTime
    // const racers = this.racers;
    const baseFactor = t - anim.startTime;

    const oddsHeaderVicenteAnim = Logic.getAnim(t, this.oddsHeaderVicenteAnims, this.oddsHeaderVicente);
    const oddsHeaderAccopiataAnim = Logic.getAnim(t, this.oddsHeaderAccopiataAnims, this.oddsHeaderAccopiata);
    if (oddsHeaderVicenteAnim)
      AnimHelper.animateInOut(t, oddsHeaderVicenteAnim.startTime, oddsHeaderVicenteAnim.startTime + oddsHeaderVicenteAnim.duration, 1, 0, 1, (alpha) => (this.oddsHeaderVicente.alpha = alpha), 0.2, 0);
    if (oddsHeaderAccopiataAnim)
      AnimHelper.animateInOut(
        t,
        oddsHeaderAccopiataAnim.startTime,
        oddsHeaderAccopiataAnim.startTime + oddsHeaderAccopiataAnim.duration,
        0.2,
        0,
        1,
        (alpha) => (this.oddsHeaderAccopiata.alpha = alpha),
        0.2,
        0
      );

    if (!this.oddsAlwaysOn) {
      anim.initAnimation = true;
    } else if (anim.startSm && t < 3) {
      const fadeInTo = oddScreenSettings[this.gameType as keyof typeof oddScreenSettings].oddsScreenScaleTo;
      this.container.scale.x = fadeInTo;
      this.container.scale.y = fadeInTo;

      this.container.position.x = _s(476.5);
      this.container.position.y = _s(110);
    }

    if (baseFactor < anim.duration - 1 && anim.initAnimation) {
      for (let iRow = 0; iRow < this.racerCount; iRow++) {
        const rowFactor = baseFactor - iRow * 0.1 * fadeInFactor;
        for (let iCol = 0; iCol < this.racerCount; iCol++) {
          const index = iCol + iRow * this.racerCount;
          this.oddsTexts[index].alpha = rowFactor - iCol * 0.1 * fadeInFactor;
        }
      }
      this.setDebugFade(AnimHelper.clamp(baseFactor));
      if (this.gameType === "dog6") {
        this.first.alpha = AnimHelper.clamp(baseFactor);
        this.second.alpha = this.first.alpha;
      } else {
        this.first.alpha = AnimHelper.clamp((baseFactor - 0.3) * 2);
        this.second.alpha = AnimHelper.clamp((baseFactor - 0.4) * 2);
      }
    } else {
      if (this.oddsAlwaysOn) return this.updateOddsAlwaysOn(t);
      this.animateOutScreen(baseFactor, anim, fadeOutFactor);
    }
  }

  private animateOutScreen(baseFactor: number, anim: IAnimInterval, fadeOutFactor: number) {
    const limitedBaseFactor = AnimHelper.limit(baseFactor, anim.duration);
    for (let iRow = 0; iRow < this.racerCount; iRow++) {
      const rowFactor = limitedBaseFactor + (this.racerCount - iRow) * 0.05 * fadeOutFactor;
      for (let iCol = 0; iCol < this.racerCount; iCol++) {
        const index = iCol + iRow * this.racerCount;
        // this.oddsTexts[index].alpha = rowFactor;
        this.oddsTexts[index].alpha = rowFactor - iCol * 0.05 * fadeOutFactor;
      }
    }
    this.setDebugFade(AnimHelper.clamp(baseFactor));
    if (this.oddsAlwaysOn) {
      this.first.alpha = AnimHelper.clamp((anim.duration - baseFactor + 1.1) * 2);
      this.second.alpha = AnimHelper.clamp((anim.duration - baseFactor + 1.1) * 2);
    } else if (this.gameType === "dog6") {
      this.first.alpha = AnimHelper.clamp((anim.duration - baseFactor - 0.5) * 2);
      this.second.alpha = AnimHelper.clamp((anim.duration - baseFactor + 0.5) * 2);
    } else {
      this.first.alpha = AnimHelper.clamp((anim.duration - baseFactor + 0.5) * 2);
      this.second.alpha = AnimHelper.clamp((anim.duration - baseFactor + 0.0) * 2);
    }
  }

  public updateOddsAlwaysOn(t: number) {
    const anim = Logic.getAnim(t, this.anims, this);

    if (!anim) return;

    const baseFactor = t - anim.startTime;

    const fadeOutFactor = anim.fadeOutFactor ? anim.fadeOutFactor : 1;

    if (!(baseFactor < anim.duration - 1) && !anim.initAnimation) {
      this.animateOutScreen(baseFactor, anim, fadeOutFactor);
    }

    if (!anim.subAnimations) return;

    anim.subAnimations.forEach((animation, index) => {
      if (t > animation.startTime && t < animation.startTime + animation.duration + 5 && anim.subAnimations) {
        const animation = anim.subAnimations[index];
        const start = animation.startTime;
        const duration = animation.startTime + animation.duration;

        const fadeInFrom = 1;
        const fadeInTo = oddScreenSettings[this.gameType as keyof typeof oddScreenSettings].oddsScreenScaleTo;
        const xFrom = _s(mainElementPositionSizes[this.gameType as keyof typeof oddScreenSettings].oddsScreen.x);
        const yFrom = _s(mainElementPositionSizes[this.gameType as keyof typeof oddScreenSettings].oddsScreen.y);
        const x = _s(oddScreenSettings[this.gameType as keyof typeof oddScreenSettings].oddsScreenPosXTo);
        const y = _s(oddScreenSettings[this.gameType as keyof typeof oddScreenSettings].oddsScreenPosYTo);

        const fadeIn = animation.fadeInFactor || 0.8;
        const fadeOut = animation.fadeOutFactor || 0.8;
        const inSmoothness = animation.smoothness?.in || 1.5;
        const outSmoothness = animation.smoothness?.out || 1.5;

        if (t < duration - fadeOut) {
          this.animateIn(t, start, duration, fadeIn, fadeInFrom, fadeInTo, inSmoothness || 1.5, (val) => {
            this.container.scale.x = val;
            this.container.scale.y = val;
          });
          this.animateIn(t, start, duration, fadeIn, xFrom, x, inSmoothness || 1.5, (val) => (this.container.position.x = val));
          this.animateIn(t, start, duration, fadeIn, yFrom, y, inSmoothness || 1.5, (val) => (this.container.position.y = val));
        } else if (t > duration) {
          this.container.scale.x = fadeInFrom;
          this.container.scale.y = fadeInFrom;
          this.container.position.x = xFrom;
          this.container.position.y = yFrom;
          return;
        } else {
          this.animateOut(t, duration, fadeInTo, fadeInFrom, fadeOut, outSmoothness || 1.5, (val) => {
            this.container.scale.x = val;
            this.container.scale.y = val;
          });
          this.animateOut(t, duration, x, xFrom, fadeOut, outSmoothness || 1.5, (val) => (this.container.position.x = val));
          this.animateOut(t, duration, y, yFrom, fadeOut, outSmoothness || 1.5, (val) => (this.container.position.y = val));
        }
      }
    });
  }

  private animateOut(currentTime: number, end: number, fadeOutFrom: number, fadeOutTo: number, fadeOut: number, smoothness: number, fadeInCallback: (x: number) => any) {
    const val = fadeOutFrom + (fadeOutTo - fadeOutFrom) * AnimHelper.easeOutCirc((currentTime - (end - fadeOut)) / fadeOut, smoothness);
    fadeInCallback(val);
  }

  private animateIn(currentTime: number, start: number, duration: number, fadeIn: number, fadeInFrom: number, fadeInTo: number, smoothness: number, fadeInCallback: (x: number) => any) {
    if (currentTime < start) {
      fadeInCallback(fadeInFrom);
      return;
    } else if (currentTime - start > fadeIn && currentTime < duration) {
      fadeInCallback(fadeInTo);
      return;
    } else if (currentTime - start < fadeIn) {
      const val = fadeInFrom + (fadeInTo - fadeInFrom) * AnimHelper.easeOutCirc((currentTime - start) / fadeIn, smoothness);
      fadeInCallback(val);
      return;
    }
    fadeInCallback(fadeInTo);
    return;
  }
}
