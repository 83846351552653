import * as PIXI from "pixi.js";
import { Group } from "../../../Graphics/Group";
import { Logic, _s, _t, settings } from "../../../Logic/Logic";
import { IDriver, IAnimInterval, IDog63RoundHistory, IDog63SuprimiEntry } from "../../../Logic/LogicDefinitions";
//import { BonusAnnotationDog } from "./BonusAnnotationDog";
import { GameType, GameLength } from "../../../common/Definitions";
import { Dog63Helper } from "../Dog63Helper";
import { Dog63Quotes3SideEntry } from "./Dog63Quotes3SideEntry";
import { TilingSprite } from "pixi.js";
import { LayoutHelper } from "../../../VideoScreen/Util/LayoutHelper";
import { OddsScreenDog } from "../../../VideoScreen/dog/OddsScreenDog";

export class Dog63Quotes3Side extends Group {
  private titleText: PIXI.Text;
  private header1: PIXI.Text;
  private header2: PIXI.Text;
  private entries: Dog63Quotes3SideEntry[][] = [];

  // private rows: RowItem[] = [];
  private anims: (IAnimInterval & { fadeInFactor?: number; fadeOutFactor?: number })[] = [];
  private gameType: GameType;
  private gameLength: GameLength;

  public constructor(gameType: GameType, gameLength: GameLength) {
    super();

    this.gameType = gameType;
    this.gameLength = gameLength;

    this.showDebug(settings.debug, undefined, "Quotes Side");

    {
      const style = new PIXI.TextStyle({
        fontFamily: "DIN-Bold",
        fontSize: _s(14),
        fill: "black",
        align: "center",
        letterSpacing: 1.0
      });
      this.titleText = Logic.createPixiText(style);
      this.titleText.anchor.set(0.5, 0);
      this.add(this.titleText);
    }
    {
      const styleLight = new PIXI.TextStyle({
        fontFamily: "DIN-Light",
        fontSize: _s(20),
        fill: Dog63Helper.getWhiteColor(),
        align: "center"
      });
      this.header1 = Logic.createPixiText(styleLight);
      this.header1.anchor.set(0.5, 0);
      this.add(this.header1);
      this.header2 = Logic.createPixiText(styleLight);
      this.header2.anchor.set(0.5, 0);
      this.add(this.header2);
    }
    for (let row = 0; row < 6; row++) {
      const rowEntries: Dog63Quotes3SideEntry[] = [];
      for (let column = 0; column < 4; column++) {
        const entry = new Dog63Quotes3SideEntry(gameType, gameLength);
        rowEntries.push(entry);
        this.add(entry);
      }
      this.entries.push(rowEntries);
    }
  }

  public fill(drivers: IDriver[], oddsSide: IDog63SuprimiEntry[][]): void {
    this.titleText.text = _t("trioInOrder");
    this.header1.text = _t("twelfLowQ");
    this.header2.text = _t("twelfHeighQ");

    let minValue: number = 1000;
    let maxValue: number = 0;
    for (let row = 0; row < 6; row++) {
      for (let column = 0; column < 4; column++) {
        const oddsEntry = oddsSide[row][column];
        if (oddsEntry.quote < minValue) minValue = oddsEntry.quote;
        else if (oddsEntry.quote > maxValue) maxValue = oddsEntry.quote;
      }
    }

    for (let row = 0; row < 6; row++) {
      for (let column = 0; column < 4; column++) {
        const oddsEntry = oddsSide[row][column];
        this.entries[row][column].fill(drivers, oddsEntry.drivers, oddsEntry.quote, oddsEntry.betCodeId, minValue, maxValue);
      }
    }
  }

  public onLayout(): void {
    this.titleText.x = _s(126);
    this.titleText.y = _s(2);
    this.header1.x = _s(96);
    this.header1.y = _s(33);
    this.header2.x = _s(286);
    this.header2.y = _s(33);

    const rowOffsetY = 65;
    const rowHeight = 47.6;
    const columnOffsetX = 1;
    const columnWidth = 95;

    for (let row = 0; row < 6; row++) {
      for (let column = 0; column < 4; column++) {
        LayoutHelper.setScaledRectangle(this.entries[row][column], columnOffsetX + column * columnWidth, rowOffsetY + row * rowHeight, columnWidth, rowHeight);
      }
    }
  }
}
